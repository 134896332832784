import React, { useState, useRef, useCallback, useEffect } from "react"
import AccountPhoneNumberForm from "./AccountPhoneNumberForm"
import ApiHelpers from "../../helpers/ApiHelpers"
import img_empty from "../../assets/BK/img_empty_mobilephone.png"
import Header from "../../components/Header"
import Loading from "../../components/Loading"
import { useTheme } from "../../hooks/useTheme"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/

export default function AccountPhoneNumber() {

  const { theme } = useTheme()

  const refAccountPhoneNumberForm = useRef(null)
  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [showError, setShowError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [errType, setErrType] = useState("warning")
  const [loadingMessage, setLoadingMessage] = useState("Loading...")



  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'account/important_contact')
    if (response.status === 200) {
      objData = response.results.data
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  const onAccountPhoneNumberFormListeners = async (data) => {
    let API_END = null
    if (data.id === '') {
      API_END = "account/important_contact/add"
    }

    if (data.id !== '') {
      API_END = "account/important_contact/update"
    }
    let response = await ApiHelpers.post("", API_END, {
      "id": data.id,
      "name": data.name,
      "number": data.mobilephone,
    })

    // setIsLoadingMessage("Mengecek akun login Anda...")
    if (response.status === 200) {
      refAccountPhoneNumberForm.current.closeModal()
      onRefresh()
    } else {
      setErrMessage(response.message)
      setErrType("error")
      setShowError(true)
    }
  }

  const onDeleteDataListeners = async (data) => {
    let response = await ApiHelpers.post("", "account/important_contact/delete", {
      "id": data.id
    })

    // setIsLoadingMessage("Mengecek akun login Anda...")
    if (response.status === 200) {
      onRefresh()
    } else {
      setErrMessage(response.message)
      setErrType("error")
      setShowError(true)
    }
  }

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      if (fetchData.length > 0) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>
              {fetchData.map((item) => {
                return (
                  <div style={{ marginBottom: 15 }}>
                    <div
                      style={{ paddingLeft: 15, paddingRight: 15 }}>
                      <div style={{ borderRadius: 10, backgroundColor: '#9a2c90', padding: '20px 15px' }}>
                        <div style={{ display: 'flex' }}>
                          <img
                            src={img_empty} style={{ width: 50, height: 50, resizeMode: 'contain' }}
                            placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                          />
                          <div style={{ marginLeft: 15 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 16, color: '#fff' }}>
                              {item.name}
                            </div>
                            <div numberOfLines={1} style={{ fontSize: 14, marginTop: 5, color: '#fff' }}>
                              {item.number}
                            </div>
                          </div>
                        </div>
                        <div style={{
                          marginTop: 15,
                          textAlign: 'right'
                        }}>
                          <span
                            style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16, fontWeight: 'bold', marginRight: 15, cursor: 'pointer' }}
                            onClick={() => { refAccountPhoneNumberForm.current.onShowModal(item) }}
                            className="ripple">
                            UBAH
                          </span>
                          <span
                            style={{ color: '#ffeaa7', fontSize: 16, fontWeight: 'bold', marginLeft: 15, cursor: 'pointer' }}
                            className="ripple"
                            onClick={() => { onDeleteDataListeners(item) }}>
                            HAPUS
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>


            <div style={{ position: 'fixed', bottom: 0 }} className="width_full">
              <div style={{ height: 70, alignItems: 'center', justifyContent: 'center', paddingRight: 20, paddingLeft: 20, flexDirection: 'row', backgroundColor: '#D400B8', display: 'flex' }}>
                <div style={{ flex: 1, height: 40 }}>
                  <div style={{
                    borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                    alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'row',
                    display: 'flex'
                  }}
                    onClick={() => { refAccountPhoneNumberForm.current.onShowModal() }}
                    className="ripple">
                    {/* <Icons name={'plus-circle'} color={"#fff"} size={16}/> */}
                    <i className="fas fa-plus-circle" style={{ color: "#FFF", width: 18 }}></i>
                    <div style={{ color: "#fff", fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
                      Tambahkan Nomor
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: "center", minHeight: "calc(100vh - 60px)", display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={img_empty} style={{ width: 120, height: 120, resizeMode: 'contain' }}
                placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
              />
              <div numberOfLines={1} style={{ fontSize: 16, color: "rgba(255,255,255,0.7)", fontWeight: "bold", marginTop: 30 }}>Kontak Tidak Tersedia</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 13, color: "rgba(255,255,255,0.7)" }}>Data nomor penting tidak ditemukan atau,</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 3, color: "rgba(255,255,255,0.7)" }}>data yang Anda cari tidak tersedia.</div>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgba(255, 255, 255, 0.5)',
                width: 200, paddingTop: 11, paddingBottom: 11, borderRadius: 7, marginTop: 30, marginLeft: 'auto', marginRight: "auto", paddingLeft: 10, paddingRight: 10
              }}
                className="ripple"
                onClick={() => { refAccountPhoneNumberForm.current.onShowModal() }}>
                {/* <Icons name={'plus-circle'} size={18} color={'#fff'}/> */}
                <i className="fas fa-plus-circle" style={{ color: "#FFF", width: 18 }}></i>
                <div style={{ fontSize: 15, color: '#fff', marginLeft: 10 }}>Tambahkan Nomor</div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }
  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      {/* <Navbar page={"Profil Saya"} /> */}
      <Header
        title={isFetched ? 'loading' : "List Nomor Penting"}
        // navigation={this.props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <AccountPhoneNumberForm ref={refAccountPhoneNumberForm} onUpdateData={onAccountPhoneNumberFormListeners} />
      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto',
        bottom: 0
      }}
        open={showError}
        autoHideDuration={3000}
        onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }}>
        <Alert onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>
    </div>
  )
  // }
}