import React, { useState, useRef, useCallback, useEffect } from "react"
import AccountSubscriptionsDetail from "./AccountSubscriptionsDetail"
import ApiHelpers from "../../helpers/ApiHelpers"
import img_empty from "../../assets/BK/img_subscribe_type.png"
import Header from "../../components/Header"
import img_subscribe from "../../assets/BK/img_subscribe.png"
import moment from 'moment'
import Loading from "../../components/Loading"
import { useHistory } from "react-router-dom"
import { useTheme } from "../../hooks/useTheme"

export default function AccountPhoneNumber() {

  const { theme } = useTheme()

  const refAccountSubscriptionsDetail = useRef(null)
  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")

  const history = useHistory()



  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'account/subscribe')
    if (response.status === 200) {
      objData = response.results.data
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      if (fetchData.length > 0) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>
              {fetchData.map((item) => {
                let isActive = false
                let today = moment().format("YYYY-MM-DD HH:mm:ss")
                let isBefore = moment(today).isBefore(item.end_datetime)
                if (isBefore) { isActive = true }
                return (
                  <div style={{ marginBottom: 15 }}>
                    <div
                      style={{ paddingLeft: 15, paddingRight: 15 }}>
                      <div style={{ borderRadius: 10, 
                        // backgroundColor: '#9a2c90', 
                        padding: '20px 15px' }} className={`background-dark-theme-${theme}`}>
                        <div style={{ display: 'flex' }}>
                          <img
                            src={img_subscribe} style={{ width: 50, height: 50, resizeMode: 'contain' }}
                            placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                          />
                          <div style={{ marginLeft: 15 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 16, color: '#fff' }}>
                              {item.package_name}
                            </div>
                            <div numberOfLines={1} style={{ fontSize: 14, marginTop: 5, color: '#fff' }}>
                              {isActive ?
                                <div numberOfLines={1} style={{ fontSize: 14, marginTop: 5, color: '#55efc4' }}>
                                  Aktif s.d. {moment(item.end_datetime).format("lll")}
                                </div>
                                :
                                <div numberOfLines={1} style={{ fontSize: 14, marginTop: 5, color: '#fab1a0' }}>
                                  Telah berakhir
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{
                          marginTop: 15,
                          textAlign: 'right'
                        }}>
                          <span
                            style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16, fontWeight: 'bold', marginRight: 15, cursor: 'pointer' }}
                            className="ripple"
                            onClick={() => { refAccountSubscriptionsDetail.current(item) }}>
                            Detail
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div style={{ position: 'fixed', bottom: 0 }} className="width_full">
              <div style={{ height: 70, alignItems: 'center', justifyContent: 'center', paddingRight: 20, paddingLeft: 20, flexDirection: 'row', 
              // backgroundColor: '#D400B8', 
              display: 'flex' }} className={`background-theme-${theme}`}>
                <div style={{ flex: 1, height: 40 }}>
                  <div style={{
                    borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                    alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'row',
                    display: 'flex'
                  }}
                    className="ripple"
                    onClick={() => { history.push("/subscriptions-package") }}>
                    <i className="fas fa-check-circle" style={{ color: "#FFF", width: 18 }}></i>
                    <div style={{ color: "#fff", fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
                      Beli Paket
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: "center", minHeight: "calc(100vh - 60px)", display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={img_empty} style={{ width: 120, height: 120, resizeMode: 'contain' }}
                placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
              />
              <div numberOfLines={1} style={{ fontSize: 16, color: "rgba(255,255,255,0.7)", fontWeight: "bold", marginTop: 30 }}>Riwayat Tidak Tersedia</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 13, color: "rgba(255,255,255,0.7)" }}>Riwayat langganan tidak ditemukan atau,</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 3, color: "rgba(255,255,255,0.7)" }}>Riwayat yang Anda cari tidak tersedia.</div>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgba(255, 255, 255, 0.5)',
                width: 200, paddingTop: 11, paddingBottom: 11, borderRadius: 7, marginTop: 30, marginLeft: 'auto', marginRight: "auto", paddingLeft: 10, paddingRight: 10
              }}
                onClick={() => { history.push("/subscriptions-package") }}
                className="ripple">
                <i className="fas fa-check-circle" style={{ color: "#FFF", width: 18 }}></i>
                <div style={{ fontSize: 15, color: '#fff', marginLeft: 10 }}>Beli Paket</div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }
  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Riwayat Langganan"}
        // navigation={this.props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <AccountSubscriptionsDetail refAccountSubscriptionsDetail={refAccountSubscriptionsDetail} />
    </div>
  )
  // }
}