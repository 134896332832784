import React, { useState, useRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar"; // https://mui.com/getting-started/usage/
import Alert from "@mui/material/Alert"; // https://mui.com/getting-started/usage/
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
// import TrackPlayer from 'react-track-player';
import Modal from "react-bootstrap/Modal";
import FlatList from "flatlist-react";

import moment from "moment";
import img_empty from "../../assets/BK/img_empty_data.png";
import arrContraction from "./arrContraction.json";

import Loading from "../../components/Loading";
import ApiHelpers from "../../helpers/ApiHelpers";
import MyHelpers from "../../helpers/MyHelpers";
import PopupList from "./PopupList";
import ContractionTimer from "./ContractionTimer";
import ContractionInfoAdd from "./ContractionInfoAdd";
import ContractionItem from "./ContractionItem";
import AudioMedia from "../../components/audio/AudioMedia";
import { useTheme } from "./../../hooks/useTheme";
import NoSleep from "nosleep.js";

import ModalMusicPlaylist from "./../_modals/ModalMusicPlaylist/ModalMusicPlaylist";
import ContractionMyNote from "./ContractionMyNote";
import ContractionMyNoteInfo from "./ContractionMyNoteInfo";

const { width } = 100;
const START_BTN_BORDER = 85;
const START_BTN = 70;
const START_BTN_ICON = 45;
const EMPTY_IMG_WIDTH = "40%";
const EMPTY_IMG_HEIGHT = "40%";
const DEF_MUSIC_THEME = [
  {
    id: "1",
    url: "https://media.bidankita.com/private/2021/09/file/rain-sound-1-1631700036.mp3",
    title: "Musik Relaksasi Hujan",
    artist: "Musik Relaksasi",
    artwork:
      "https://i.pinimg.com/736x/ac/27/b8/ac27b8c845b2c4f87d6ca577074951ab--rainy-season-wallpaper-backgrounds.jpg",
    background: "https://c.tenor.com/JlUcZGgvVHwAAAAC/rain-raining.gif",
  },
  {
    id: "1",
    url: "https://media.bidankita.com/private/2021/09/file/rain-sound-1-1631700036.mp3",
    title: "Musik Relaksasi Hujan",
    artist: "Musik Relaksasi",
    artwork:
      "https://i.pinimg.com/736x/ac/27/b8/ac27b8c845b2c4f87d6ca577074951ab--rainy-season-wallpaper-backgrounds.jpg",
    background: "https://c.tenor.com/JlUcZGgvVHwAAAAC/rain-raining.gif",
  },
  {
    id: "1",
    url: "https://media.bidankita.com/private/2021/09/file/rain-sound-1-1631700036.mp3",
    title: "Musik Relaksasi Hujan",
    artist: "Musik Relaksasi",
    artwork:
      "https://i.pinimg.com/736x/ac/27/b8/ac27b8c845b2c4f87d6ca577074951ab--rainy-season-wallpaper-backgrounds.jpg",
    background: "https://c.tenor.com/JlUcZGgvVHwAAAAC/rain-raining.gif",
  },
  {
    id: "1",
    url: "https://media.bidankita.com/private/2021/09/file/rain-sound-1-1631700036.mp3",
    title: "Musik Relaksasi Hujan",
    artist: "Musik Relaksasi",
    artwork:
      "https://i.pinimg.com/736x/ac/27/b8/ac27b8c845b2c4f87d6ca577074951ab--rainy-season-wallpaper-backgrounds.jpg",
    background: "https://c.tenor.com/JlUcZGgvVHwAAAAC/rain-raining.gif",
  },
];
const DEF_VOICE_THEME = {
  id: "1",
  url: "https://media.bidankita.com/private/2021/09/file/greeting+podcast-1631700594.mp3",
  title: "Panduan & Sugesti Kontraksi",
  artist: "Bidan Yesie Aprillia",
  artwork:
    "https://gpu.id/data-gpu/images/uploads/author/dedede4b7e4bc862eebff9b4aa6a3b3e.jpg",
};

const CONTRACTION_MESSAGE_FAKE =
  "Selamat Anda sudah mengalami kontraksi, namun ini adalah kontraksi palsu/braxton hiks. Silahkan: minum air putih hangat, atau atur posisi yang nyaman lalu relaksasi menggunakan panduan hypnobirthing dari bidan kita (elearning).";
const CONTRACTION_MESSAGE_REAL =
  "Selamat Anda sudah mengalami kontraksi asli, tetaplah tenang, senyum, pastikan Anda sudah makan, minum, mandi dan menyiapkan hospital bag Anda. Kemudian silahkan menuju ke provider untuk periksa. Semangat ya.";

const Contraction = () => {
  const [isStart, setIsStart] = useState(false);
  const [startDatetime, setStartDatetime] = useState("");
  const [timer, setTimer] = useState(null);

  const [hours_counter, set_hours_counter] = useState("00");
  const [minutes_counter, set_minutes_counter] = useState("00");
  const [seconds_counter, set_seconds_counter] = useState("00");

  const [arrData, setArrData] = useState([]);

  const [tot_observer_time_second, set_tot_observer_time_second] = useState(0);
  const [tot_observer_time_label, set_tot_observer_time_label] = useState("-");

  const [tot_duration, set_tot_duration] = useState("00:00");
  const [avg_duration, set_avg_duration] = useState("00:00");
  const [tot_interval, set_tot_interval] = useState("00:00");
  const [avg_interval, set_avg_interval] = useState("00:00");

  const [tot_duration_label, set_tot_duration_label] = useState("-");
  const [avg_duration_label, set_avg_duration_label] = useState("-");
  const [tot_interval_label, set_tot_interval_label] = useState("-");
  const [avg_interval_label, set_avg_interval_label] = useState("-");

  const history = useHistory();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalInfo, setIsShowModalInfo] = useState(false);
  const [dataInfo, setDataInfo] = useState('');
  const [isShowModalSave, setIsShowModalSave] = useState(false);

  const refAudioMusic = useRef(null);
  const refAudioVoice = useRef(null);

  const refModalPlaylist = useRef(null);

  const [switchMusic, setSwitchMusic] = useState(false);
  const [switchVoice, setSwitchVoice] = useState(false);

  const [selectedPlaylist, setSelectedPlaylist] = useState({});

  const [voiceAuto, setVoiceAuto] = useState(false);
  const [selectedVoices, setSelectedVoices] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const { theme, backgroundImageTheme, changeTheme, changeBackground } =
    useTheme();

  useEffect(() => {
    getVoices();

    return () => {
      noSleep.disable(); // disable stay awake
    };
  }, []);

  useEffect(() => {
    if (selectedPlaylist.name) {
      refAudioMusic.current.play(
        selectedPlaylist.music[selectedPlaylist.indexPlay].url
      );
    }
  }, [selectedPlaylist.name]);

  const noSleep = new NoSleep();

  const getVoices = async () => {
    setIsLoading(true);
    setLoadingMessage("Memuat panduan...");

    let response = await ApiHelpers.get("", "general/sound", {
      type: "backsound",
      limit: 1000,
    });

    if (response.status === 200) {
      setIsLoading(false);
      setSelectedVoices({ music: { ...response.results.data }, indexPlay: 0 });
    } else {
      setIsLoading(false);
      setErrMessage(response.message);
      setShowError(true);
    }
  };

  const [
    modus_contraction_intensitas_label,
    set_modus_contraction_intensitas_label,
  ] = useState("-");
  const [
    modus_contraction_intensitas_feel,
    set_modus_contraction_intensitas_feel,
  ] = useState("-");
  const [
    modus_contraction_intensitas_note,
    set_modus_contraction_intensitas_note,
  ] = useState("-");

  const [tot_duration_second, set_tot_duration_second] = useState(0);
  const [avg_duration_second, set_avg_duration_second] = useState(0);
  const [tot_interval_second, set_tot_interval_second] = useState(0);
  const [avg_interval_second, set_avg_interval_second] = useState(0);

  const [minIntervalSeconds, setMinIntervalSeconds] = useState(0);
  const [maxIntervalSeconds, setMaxIntervalSeconds] = useState(0);

  const [isPlayMusics, setIsPlayMusics] = useState(false);
  const [isPlayVoices, setIsPlayVoices] = useState(false);

  const [errMessage, setErrMessage] = useState('')
  const [errType, setErrType] = useState('warning')
  const [showError, setShowError] = useState(false)

  // const [selectedMusics, setSelectedMusics] = useState(null);
  // const [selectedVoices, setSelectedVoices] = useState(null);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const API_END_POINT = "actions/options";
  const [listMusic, setListMusic] = useState([]);
  const fetchData = async () => {
    let fetchData = [];
    let arrMusic = [];
    let selectedPlaylistTitle = "";
    let response = await ApiHelpers.get("", API_END_POINT, { type: "moods" });
    // console.log(JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      fetchData = response.results.data;
      // response.results.data.map((item, key) => {
      //     if(this.state.parentIndex === key) {
      //         arrMusic = item.music
      //         selectedPlaylistTitle = item.name
      //     }
      // })
    }
    setListMusic(fetchData);
  };

  // ====================================================================================
  // ========== SOUND LISTENER SECTIONS =================================================
  // ====================================================================================
  // const onPlayBacksound = async (params = null) => {
  //   setShowPopupList(true);
  //   if (params === null) {
  //     if (selectedMusics !== null) {
  //       let objData = selectedMusics;
  //       setIsPlayMusics(true);
  //       // await TrackPlayer.setupPlayer();
  //       // await TrackPlayer.add(objData);
  //       // await TrackPlayer.play();
  //     } else {
  //       setSnackbarMessage("Musik tidak tersedia atau tidak ditemukan.");
  //       setShowSnackbar(true);
  //     }
  //   } else {
  //     setIsPlayMusics(true);
  //     // await TrackPlayer.setupPlayer();
  //     // await TrackPlayer.add(params);
  //     // await TrackPlayer.play();
  //   }
  // };
  const onPauseBacksound = async () => {
    setIsPlayMusics(false);
    // await TrackPlayer.pause()
  };
  const onStopBacksound = async () => {
    setIsPlayMusics(false);
    // await TrackPlayer.stop()
    // await TrackPlayer.reset()
  };
  const onPlayVoicesound = async (params = null) => {
    if (isStart) {
      // setShowPopupList(true);
    } else {
      setSnackbarMessage("Tekan tombol play untuk memutar panduan.");
      setShowSnackbar(true);
      setIsPlayVoices(false);
    }

    // setShowPopupList(true);
    // if (params === null) {
    // if (selectedVoices !== null) {
    // let objData = selectedVoices;
    // setIsPlayVoices(true);
    // this.ref_PlayVoices = new Sound(objData.url, '',
    //   (error, sound) => {
    //     if (error) {
    //       alert('error' + error.message);
    //       return;
    //     }
    //     this.ref_PlayVoices.play(() => {
    //       this.ref_PlayVoices.release();
    //     });
    //   });
    // await TrackPlayer.setVolume(0.7)
    //   } else {
    //     setSnackbarMessage("Panduan tidak tersedia atau tidak ditemukan.");
    //     setShowSnackbar(true);
    //   }
    // } else {
    //   setIsPlayVoices(true);
    // this.ref_PlayVoices = new Sound(params.url, '',
    //   (error, sound) => {
    //     if (error) {
    //       alert('error' + error.message);
    //       return;
    //     }
    //     this.ref_PlayVoices.play(() => {
    //       this.ref_PlayVoices.release();
    //     });
    //   });
    // await TrackPlayer.setVolume(0.7)
    // }
  };
  const onStopVoicesound = async () => {
    setIsPlayVoices(false);
    // this.ref_PlayVoices.stop(() => {
    //   console.log('voicesSound', 'Stop')
    // })
    // await TrackPlayer.setVolume(1.0)
  };
  const onSaveListeners = async (objData) => {
    // console.log('CONTRACTION_SUMMARY', JSON.stringify(objData))
    // this.ref_Loading.onShowModal(false)
    if (arrData.length > 0) {
        // let moods = { "data_array": objData.moods }
        setIsShowModalSave(false)
        setIsLoading(true)
        setLoadingMessage('Menyimpan data...')
        let response = await ApiHelpers.post("", "actions/add", {
            item_array: arrData
        })
        if (response.status === 200) {
          setTimeout(() => {
            setIsLoading(false)
            onResetAllData()
            history.push({
              pathname: '/contraction-history',
              params: {
                fieldLabel: 'Hari Ini',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: ''
              }
            })
          }, 1500)
        } else {
          setTimeout(() => {
            setModalErrorMessage(response.message)
            setShowModalError(true)
          }, 10)
        }
    } else {
      setIsShowModalSave(false)
      // setModalErrorMessage("Tidak ada data yang akan disimpan.")
      // setShowModalError(true)
      setSnackbarMessage("Tidak ada data yang akan disimpan.");
      setShowSnackbar(true);
        // Snackbar.show({
        //     backgroundColor: colors.snackbar.errorBackgoround,
        //     text: "Tidak ada data yang akan disimpan.",
        //     duration: Snackbar.LENGTH_LONG,
        //     action: {
        //         text: 'BAIK',
        //         textColor: colors.snackbar.errorColor,
        //         onPress: () => { /* Do something. */ }
        //     },
        // })
    }
  }

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 15,
        }}
      >
        <AudioMedia
          ref={refAudioMusic}
          volume={0.1}
          onPlaySuccess={() => setSwitchMusic(true)}
          onPlayError={(err) => {
            setSwitchMusic(false);
            setErrMessage("Gagal memutar musik");
            setShowError(true);
          }}
          onStop={() => setSwitchMusic(false)}
          onEnded={() => {
            setSelectedPlaylist((prevState) => {
              let indexToPlay = prevState.indexPlay + 1;

              if (!prevState.music[indexToPlay]) {
                indexToPlay = 0;
              }

              refAudioMusic.current.play(prevState.music[indexToPlay].url);

              return { ...prevState, indexPlay: indexToPlay };
            });
          }}
        />

        <AudioMedia
          ref={refAudioVoice}
          volume={1}
          onPlaySuccess={() => setSwitchVoice(true)}
          onPlayError={(err) => {
            setSwitchVoice(false);
            setErrMessage("Gagal memutar panduan");
            setShowError(true);
          }}
          onStop={() => setSwitchVoice(false)}
          onEnded={() => {
            setSelectedVoices((prevState) => {
              let indexToPlay = prevState.indexPlay + 1;

              if (!prevState.music[indexToPlay]) {
                indexToPlay = 0;
              }

              refAudioVoice.current.play(prevState.music[indexToPlay].url);

              return { ...prevState, indexPlay: indexToPlay };
            });
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div onClick={() => history.push(`/dashboard`)}>
            <i
              className="fas fa-times-circle mg-right10 ripple"
              style={{ color: "yellow", cursor: "pointer" }}
            />
          </div>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <div style={{ color: "#FFFFFF", fontWeight: "bold", fontSize: 14 }}>
              Contraction Timers
            </div>
            <div style={{ color: "#FFFFFF", fontSize: 10 }}>
              {moment().calendar()}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{
            borderRadius: 20,
            border: "2px solid #FFF",
            background: 'rgb(0 0 0 / 11%)'
          }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 12,
                paddingRight: 12,
                // opacity:0.8,
                paddingTop: 5,
                paddingBottom: 5,
              }}
              onClick={() => {
                if (isStart) {
                  setSnackbarMessage("Tekan stop terlebih dahulu, lalu simpan.");
                  setShowSnackbar(true);
                } else {
                  if (arrData.length > 0) {
                    if (tot_observer_time_second >= 3600) {
                      generateSummary('back_save')
                    } else {
                      setIsShowModalSave(true)
                    }
                  } else {
                    setSnackbarMessage(
                      "Tidak ada data yang akan disimpan."
                    );
                    setShowSnackbar(true);
                  }
                  // this.ref_ContractionInfoSummary.onShowModal(true)
                }
              }}>
              <i className={"fas fa-check mg-right10 txt-white"} />
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#FFF",
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                // onClick={() => setIsShowModalSave(true)}
                className="ripple"
              >
                Simpan
              </div>
            </div>
          </div>
          <div onClick={() => {
              if (arrData.length > 0) {
                setIsShowModal(true)
              } else {
                setSnackbarMessage(
                  "Tidak ada data yang akan dihapus."
                );
                setShowSnackbar(true);
              }
            }}>
            <i
              className="fa fa-trash icon mg-left10 ripple"
              style={{ color: "white", cursor: "pointer" }}
            />
          </div>
          <Modal animationType="fade" show={isShowModal} transparent centered>
            <div className={`background-theme-${theme}`} style={{ borderRadius: "15px", }}>
              <Modal.Header
                style={{
                  width: "100%",
                  height: 50,
                  flexDirection: "row",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "15px 15px 0px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: 20,
                }}
              >
                {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
                <div
                  style={{
                    flex: 1,
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Reset Data?
                </div>
              </Modal.Header>
              <Modal.Body>
                <div style={{ textAlign: "left", padding: "15px 0px" }}>
                  <div
                    style={{ color: "#fff", fontSize: 14, textAlign: "center" }}
                  >
                    Apakah Anda yakin ingin reset data kontraksi?
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer
                style={{
                  height: 70,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  flexDirection: "row",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "0px 0px 15px 15px",
                }}
              >
                <div
                  style={{
                    borderRadius: 40,
                    width: 100,
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsShowModal(false);
                  }}
                >
                  <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>
                    Tidak
                  </div>
                </div>
                <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                  <div
                    style={{
                      borderRadius: 40,
                      width: "100%",
                      height: 40,
                      backgroundColor: "rgba(0,0,0,0.4)",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => onResetAllData()}
                  >
                    <i
                      className="fas fa-check icon ripple"
                      style={{ color: "#fff", fontSize: 14 }}
                    ></i>
                    <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>
                      Ya, Reset
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </div>
    );
  };

  const renderAudioVoices = () => {
    return (
      <div
        style={{
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          display: "flex",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              color: "#fff",
              fontSize: 14,
              marginRight: 10,
              fontWeight: "bold",
            }}
          >
            Musik
          </span>
          <Switch
            checked={switchMusic}
            size="small"
            color="default"
            onChange={(e, val) => {
              if (val) {
                if (selectedPlaylist.name) {
                  refAudioMusic.current.play(
                    selectedPlaylist.music[selectedPlaylist.indexPlay].url
                  );
                } else {
                  refModalPlaylist.current.showModal(true);
                }
              } else {
                refAudioMusic.current.stop();
              }
            }}
          />
          <i
            className="fa fa-list-alt ripple"
            style={{
              color: "#fff",
              marginLeft: 20,
              fontSize: 18,
              cursor: "pointer",
            }}
            onClick={() => refModalPlaylist.current.showModal(true)}
          ></i>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <div
            style={{
              borderLeftWidth: 1,
              borderColor: "#fff",
              height: "100%",
              borderLeftStyle: "solid",
            }}
          ></div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              color: "#fff",
              fontSize: 14,
              marginRight: 10,
              fontWeight: "bold",
            }}
          >
            Panduan
          </span>
          <Switch
            checked={switchVoice}
            size="small"
            color="default"
            onChange={(e, val) => {
              if (isStart) {
                if (val) {
                  if (selectedVoices.music) {
                    refAudioVoice.current.play(
                      selectedVoices.music[selectedVoices.indexPlay].url
                    );
                  }
                } else {
                  refAudioVoice.current.stop();
                  // setVoiceAuto(!voiceAuto)
                }
              } else {
                setSnackbarMessage("Tekan tombol play untuk memutar panduan.");
                setShowSnackbar(true);
                setIsPlayVoices(false);
              }
            }}
          />
          <span
            style={{
              padding: "2px 6px",
              fontSize: 11,
              background: voiceAuto
                ? "rgba(255,255,255,0.8)"
                : "rgba(0,0,0,0.3)",
              color: voiceAuto ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.8)",
              borderRadius: 20,
              width: 40,
              textAlign: "center",
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: 20,
            }}
            className={`ripple`}
            onClick={() => {
              setVoiceAuto(!voiceAuto);

              if (isStart) {
                if (!voiceAuto && selectedVoices.music) {
                  refAudioVoice.current.play(
                    selectedVoices.music[selectedVoices.indexPlay].url
                  );
                } else {
                  refAudioVoice.current.stop();
                }
              }
            }}
          >
            {voiceAuto ? "Auto" : "Off"}
          </span>
        </div>
      </div>
    );
  };

  const onStopTimerListeners = (durTime = "") => {
    setIsStart(false);
    setIsPlayVoices(false);
    setSwitchVoice(false)
    refAudioVoice.current.stop()
    let newStartDatetime = startDatetime;
    let endDatetime = moment().format("YYYY-MM-DD HH:mm:ss");

    let durationTimer = `${minutes_counter}:${seconds_counter}`;
    if (durTime !== "") {
      durationTimer = durTime;
    }
    let durInSecond = MyHelpers.timeToSecond(durationTimer);
    let durationLabel = MyHelpers.secondsToHms(durInSecond);
    // console.log(`${durInSecond}s => ${durationLabel}`)

    let newArrData = arrData;
    if (newArrData.length <= 0) {
      newArrData.push({
        start_datetime: newStartDatetime,
        end_datetime: endDatetime,
        duration: durationTimer,
        duration_label: durationLabel,
        interval: newArrData.length <= 0 ? "00:00" : "",
        interval_label: newArrData.length <= 0 ? "0d" : "",
        data_jsonobject: {
          feel: "",
          feel_note: {
            title:
              "rasa kontraksi menjalar sampai ke pinggang atau tulang ekor",
            value: "",
          },
          my_note: "",
          my_note_object: {
            medicine_consumed: "",
            moods: [],
            contraction_treatment: [],
            contraction_treatment_other: "",
            fetal_movement: { value: "", icon: "" },
            signs_and_symptoms: [],
            life_style: [],
            ruptured_membranes: [],
            physical_condition: [],
          },
        },
      });
    } else {
      let lsIndex = newArrData.length - 1;
      let lsEndDatetime = newArrData[lsIndex].end_datetime;
      let freq = moment
        .utc(
          moment(newStartDatetime, "YYYY-MM-DD HH:mm:ss").diff(
            moment(lsEndDatetime, "YYYY-MM-DD HH:mm:ss")
          )
        )
        .format("mm:ss");

      let feqInSecond = MyHelpers.timeToSecond(freq);
      let freqLabel = MyHelpers.secondsToHms(feqInSecond);
      // console.log(`${feqInSecond}s => ${freqLabel}`)

      newArrData.push({
        start_datetime: newStartDatetime,
        end_datetime: endDatetime,
        duration: durationTimer,
        duration_label: durationLabel,
        interval: freq,
        interval_label: freqLabel,
        data_jsonobject: {
          feel: "",
          feel_note: {
            title:
              "rasa kontraksi menjalar sampai ke pinggang atau tulang ekor",
            value: "",
          },
          my_note: "",
          my_note_object: {
            medicine_consumed: "",
            moods: [],
            contraction_treatment: [],
            contraction_treatment_other: "",
            fetal_movement: { value: "", icon: "" },
            signs_and_symptoms: [],
            life_style: [],
            ruptured_membranes: [],
            physical_condition: [],
          },
        },
      });
    }
    // console.log(JSON.stringify(newArrData))

    clearInterval(timer);
    setIsStart(false);
    setArrData(newArrData);
    set_hours_counter("00");
    set_minutes_counter("00");
    set_seconds_counter("00");

    setTimeout(() => {
      let dataIndex = newArrData.length > 0 ? newArrData.length - 1 : 0;
      let dataInfo = newArrData[dataIndex];

      // console.log(durationTimer)
      if (durationTimer === "02:00") {
        onCalculateTotal(true);
      } else {
        contractionInfoRef.current.onSetData(dataIndex, dataInfo);
        setIsShowModalContractionInfo(true);
      }
      // console.log(dataInfo)
    }, 50);
    // onCalculateTotal(true);
  };

  const onEndTimer = (sec, min, hour) => {
    set_seconds_counter(sec);
    set_minutes_counter(min);
    set_hours_counter(hour);
  };
  const onDeleteContractionData = (index) => {
    let newArrData = arrData;
    newArrData.splice(index, 1);
    if (newArrData.length > 0) {
      setArrData(newArrData);
      onCalculateTotal();
    } else {
      onResetAllData();
    }
  };

  const onResetAllData = () => {
    setIsStart(false);
    setStartDatetime("");

    setTimer(null);
    set_hours_counter("00");
    set_minutes_counter("00");
    set_seconds_counter("00");

    setArrData([]);
    set_tot_observer_time_second(0);
    set_tot_observer_time_label("-");

    set_tot_duration("00:00");
    set_avg_duration("00:00");
    set_tot_interval("00:00");
    set_avg_interval("00:00");

    set_tot_duration_label("-");
    set_avg_duration_label("-");
    set_tot_interval_label("-");
    set_avg_interval_label("-");
    set_modus_contraction_intensitas_label("-");
    set_modus_contraction_intensitas_feel("-");
    set_modus_contraction_intensitas_note("-");

    set_tot_duration_second(0);
    set_avg_duration_second(0);
    set_tot_interval_second(0);
    set_avg_interval_second(0);

    setMinIntervalSeconds(0);
    setMaxIntervalSeconds(0);

    setIsShowModal(false);
  };
  const renderContents = () => {
    if (isLoading === true) {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }

    if (isStart) {
      return (
        <div
          style={{
            height: "calc(100vh - 235px)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ fontSize: 14, color: "rgba(255,255,255,255)", fontWeight: 900, }}>
                TIMER
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  backgroundColor: "#8A3782",
                  borderRadius: 8,
                  marginRight: 7.5,
                  width: 70,
                  height: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    color: isStart ? "#fff" : "rgba(255,255,255,0.3)",
                  }}
                >
                  {minutes_counter}
                </div>
              </div>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "#fff",
                  paddingBottom: 5,
                }}
              >
                :
              </div>
              <div
                style={{
                  backgroundColor: "#8A3782",
                  borderRadius: 8,
                  marginLeft: 7.5,
                  width: 70,
                  height: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    color: isStart ? "#fff" : "rgba(255,255,255,0.3)",
                  }}
                >
                  {seconds_counter}
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: 12,
                color: "rgba(255,255,255,255)",
                fontWeight: 900,
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              Tekan tombol
              <div
                style={{
                  fontWeight: 900,
                  color: "#fab1a0",
                }}
              >
                &nbsp;stop&nbsp;
              </div>
              saat kontraksi selesai dan tekan tombol&nbsp;
              <div style={{ fontWeight: 900, color: "#55efc4" }}>
                play&nbsp;
              </div>
              saat Anda merasakan kontraksi kembali.
            </div>
          </div>
        </div>
      );
    } else {
      if (arrData.length > 0) {
        return (
          <div style={{ padding: 20 }}>
            <FlatList
              list={arrData}
              renderWhenEmpty={() => <div>List is empty!</div>}
              renderItem={(item, index) => {
                return (
                  <ContractionItem
                    item={item}
                    index={index}
                    onClickEye={() => {
                      contractionNoteInfoRef.current.onShowModal(
                        true,
                        item.data_jsonobject.my_note_object,
                        index + 1
                      );
                    }}
                    onClickCatatan={() => {
                      contractionNoteRef.current.onShowModal(true, index + 1);
                    }}
                    onClickIntensitas={() => {
                      contractionInfoRef.current.onSetData(index, item);
                      setIsShowModalContractionInfo(true);
                    }}
                    onClickHapus={() => onDeleteContractionData(index)}
                  />
                );
              }}
              numColumns={1}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingRight: 15, paddingLeft: 15 }}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              height: "calc(100vh - 235px)",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={img_empty}
                  height={EMPTY_IMG_HEIGHT}
                  width={EMPTY_IMG_WIDTH}
                  // placeholderStyle={{backgroundColor:'rgba(0,0,0,0.0)'}}
                />
              </div>

              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    color: "rgba(255,255,255,0.7)",
                    fontWeight: "bold",
                    marginTop: 10,
                    textAlign: "center",
                  }}
                >
                  Belum Ada Data Kontraksi
                </div>
                <div
                  style={{
                    fontSize: 12,
                    color: "rgba(255,255,255,0.7)",
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  Tekan tombol
                  <div style={{ fontWeight: "bold", color: "#fab1a0" }}>
                    &nbsp;stop&nbsp;
                  </div>
                  saat kontraksi selesai dan tekan tombol&nbsp;
                  <div style={{ fontWeight: "bold", color: "#55efc4" }}>
                    play&nbsp;
                  </div>
                  saat Anda merasakan kontraksi kembali.
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  // const initializeVoiceData = async () => {
  //   return new Promise(async (resolve) => {
  //     let arrVoices = [];
  //     let selectedVoices = null;
  //     let response = await ApiHelpers.get(API_MEDIA_VOICES_END_POINT, {
  //       type: "backsound",
  //       limit: 1000,
  //     });
  //     if (response.status === 200 && response.results.data.length > 0) {
  //       arrVoices = response.results.data;
  //       selectedVoices = arrVoices[0];
  //       // this.setState({ selectedVoices, arrVoices })
  //       setSelectedVoices(selectedVoices);
  //       // console.log(JSON.stringify(response))
  //     }
  //     return resolve({ arrVoices, selectedVoices });
  //   });
  // };

  useEffect(() => {
    if (isStart) {
      let interval = setInterval(() => {
        var num = (Number(seconds_counter) + 1).toString(),
          count = minutes_counter,
          hour = hours_counter;

        if (Number(seconds_counter) == 59) {
          count = (Number(minutes_counter) + 1).toString();
          set_minutes_counter(count.length == 1 ? "0" + count : count);
          if (Number(count) == 2) {
            hour = "00";
            count = "00";
            onStopTimerListeners("02:00");
            // setIsShowModalInfo(true);
          }
          num = "00";
        }
        set_seconds_counter(num.length == 1 ? "0" + num : num);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [seconds_counter, minutes_counter, isStart]);

  const onStartTimerListeners = async () => {
    setIsStart(true);
    setIsPlayVoices(true);

    setStartDatetime(moment().format("YYYY-MM-DD HH:mm:ss"));
  };

  const renderStartStopButton = () => {
    if (isStart) {
      return (
        <div
          style={{
            alignItems: "center",
            paddingTop: 15,
            paddingBottom: 15,
            width,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: START_BTN_BORDER,
              height: START_BTN_BORDER,
              backgroundColor: "white",
              borderRadius: START_BTN_BORDER / 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{}}
              onClick={() => {
                onStopTimerListeners();
                setIsStart(false);
              }}
            >
              <div
                // colors={['#CB70C5', '#9C3D93']}
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #fab1a0, #e17055)",
                  borderRadius: START_BTN / 2,
                  width: START_BTN,
                  height: START_BTN,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="ripple"
              >
                <div
                  style={{ color: "#E8A5E2", fontWeight: "bold", fontSize: 16 }}
                >
                  STOP
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            alignItems: "center",
            justifyContent: "center",
            width,
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#fff",
              marginBottom: 7.5,
              fontSize: 12,
              flexWrap: "wrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Pengamatan Selama :&nbsp;
            <div style={{ fontWeight: "bold", fontSize: 12 }}>
              {tot_observer_time_label}
            </div>
          </div>
          <div
            style={{
              width,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 100,
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontSize: 9,
                  fontWeight: "bold",
                  color: "rgba(255,255,255,1.0)",
                  textAlign: "right",
                }}
              >
                TOT DUR KONTRAKSI
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#55efc4",
                  marginTop: 2,
                  textAlign: "right",
                }}
              >
                {tot_duration_label}
              </div>
              <div
                style={{
                  fontSize: 9,
                  fontWeight: "bold",
                  color: "rgba(255,255,255,1.0)",
                  marginTop: 7.5,
                  textAlign: "right",
                }}
              >
                AVG KONTRAKSI
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#ffeaa7",
                  marginTop: 2,
                  textAlign: "right",
                }}
              >
                {arrData.length > 1 ? avg_duration_label : "-"}
              </div>
            </div>
            <div
              style={{
                marginLeft: 15,
                marginRight: 15,
                width: START_BTN_BORDER,
                height: START_BTN_BORDER,
                backgroundColor: "white",
                borderRadius: START_BTN_BORDER / 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{}}
                onClick={() => {
                  onStartTimerListeners();
                }}
              >
                <div
                  // colors={['#CB70C5', '#9C3D93']}
                  style={{
                    backgroundImage:
                      "linear-gradient(rgb(253, 121, 168), rgb(232, 67, 147))",
                    borderRadius: START_BTN / 2,
                    width: START_BTN,
                    height: START_BTN,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa fa-play-circle ripple"
                    style={{
                      // width: START_BTN_ICON,
                      // height: START_BTN_ICON,
                      color: "white",
                      fontSize: START_BTN_ICON,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: 100 }}>
              <div
                style={{
                  fontSize: 9,
                  fontWeight: "bold",
                  color: "rgba(255,255,255,1.0)",
                }}
              >
                AVG INTENSITAS
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#fab1a0",
                  marginTop: 2,
                }}
              >
                {arrData.length > 1 ? modus_contraction_intensitas_label : "-"}
              </div>
              <div
                style={{
                  fontSize: 9,
                  fontWeight: "bold",
                  color: "rgba(255,255,255,1.0)",
                  marginTop: 7.5,
                }}
              >
                AVG INTERVAL
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#a29bfe",
                  marginTop: 2,
                }}
              >
                {avg_interval_label}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const onCalculateTotal = (forceAlert = false) => {
    let newArrData = arrData;

    let totalDurSeconds = 0;
    let totalIntSeconds = 0;
    let minIntervalSeconds = 9000000;
    let maxIntervalSeconds = 0;
    newArrData.forEach(function (element, index) {
      let timePartsDur = element.duration.split(":"); //Separate  mm ss
      let secsDur = Number(timePartsDur[0]) * 60 + Number(timePartsDur[1]);
      totalDurSeconds += secsDur;

      let timePartsInt = element.interval.split(":"); //Separate  mm ss
      let secsInt = Number(timePartsInt[0]) * 60 + Number(timePartsInt[1]);
      totalIntSeconds += secsInt;

      if (index > 0) {
        if (minIntervalSeconds > secsInt) {
          minIntervalSeconds = secsInt;
        }
        if (maxIntervalSeconds < secsInt) {
          maxIntervalSeconds = secsInt;
        }
      }
    });

    // ===== SET TOTAL & AVG DURATIONS =====================
    let tot_dur = totalDurSeconds;
    let tot_duration = MyHelpers.convertHMS(tot_dur);
    let tot_duration_label = MyHelpers.secondsToHms(tot_dur);

    let avg_dur = Math.ceil(totalDurSeconds / newArrData.length);
    let avg_duration = MyHelpers.convertHMS(avg_dur);
    let avg_duration_label = MyHelpers.secondsToHms(avg_dur);

    // ===== SET TOTAL & AVG INTERVALS =====================
    let tot_int = totalIntSeconds;
    let tot_interval = "00:00";
    let tot_interval_label = "-";
    if (tot_int > 0) {
      tot_int = totalIntSeconds;
      tot_interval = MyHelpers.convertHMS(tot_int);
      tot_interval_label = MyHelpers.secondsToHms(tot_int);
    }
    let avg_int =
      tot_int > 0 ? Math.ceil(totalIntSeconds / (newArrData.length - 1)) : 0;
    let avg_interval = "00:00";
    let avg_interval_label = "-";
    if (newArrData.length > 2 && avg_int > 0) {
      avg_int = Math.ceil(totalIntSeconds / (newArrData.length - 1));
      avg_interval = MyHelpers.convertHMS(avg_int);
      avg_interval_label = MyHelpers.secondsToHms(avg_int);
    }

    let tot_observer_time_second = totalDurSeconds + totalIntSeconds;
    let tot_observer_time_label = MyHelpers.secondsToHms(
      tot_observer_time_second
    );
    // console.log(`totalDurSeconds = ${totalDurSeconds}s`)
    // console.log(`totalIntSeconds = ${totalIntSeconds}s`)
    // console.log(`--------------------`)

    set_tot_observer_time_second(tot_observer_time_second);
    set_tot_observer_time_label(tot_observer_time_label);
    set_tot_duration(tot_duration);
    set_avg_duration(avg_duration);
    set_tot_interval(tot_interval);
    set_avg_interval(avg_interval);
    set_tot_duration_label(tot_duration_label);
    set_avg_duration_label(avg_duration_label);
    set_tot_interval_label(tot_interval_label);
    set_avg_interval_label(avg_interval_label);
    set_tot_duration_second(tot_dur);
    set_avg_duration_second(avg_dur);
    set_tot_interval_second(tot_int);
    set_avg_interval_second(avg_int);

    setMinIntervalSeconds(minIntervalSeconds);
    setMaxIntervalSeconds(maxIntervalSeconds);

    generateSummary(forceAlert);
  };

  const generateSummary = (forceAlert = false) => {
    let newArrData = arrData;
    // console.log(JSON.stringify(newArrData))

    let tot_observer_time = tot_observer_time_label;
    let tot_contraction_count = MyHelpers.formatNumber(newArrData.length, "");
    let tot_contraction_time = tot_duration_label;
    let avg_contraction_time = avg_duration_label;
    let avg_interval_time = avg_interval_label;

    let newMinIntervalSeconds = minIntervalSeconds;
    let newMaxIntervalSeconds = maxIntervalSeconds;

    // ===== SET AVG INTENSITAS FEEL & NOTE =====================
    let arrContractionFeel = [];
    let arrContractionNote = [];
    newArrData.map((item) => {
      arrContractionFeel.push(item.data_jsonobject.feel);
    });
    newArrData.map((item) => {
      arrContractionNote.push(item.data_jsonobject.feel_note.value);
    });
    // console.log('arrContractionFeel', arrContractionFeel)

    let modus_contraction_intensitas_feel =
      MyHelpers.getStatisticModus(arrContractionFeel);
    // console.log('modus_contraction_intensitas_feel', modus_contraction_intensitas_feel)

    let modus_contraction_intensitas_note =
      MyHelpers.getStatisticModus(arrContractionNote);

    let modus_contraction_intensitas_label = `${modus_contraction_intensitas_feel}`;

    set_modus_contraction_intensitas_feel(modus_contraction_intensitas_feel);
    set_modus_contraction_intensitas_note(modus_contraction_intensitas_note);
    set_modus_contraction_intensitas_label(
      modus_contraction_intensitas_label != ""
        ? modus_contraction_intensitas_label
        : "-"
    );

    let min = 60; // 1m
    let max = 360; // 6m
    let message = CONTRACTION_MESSAGE_FAKE;
    let isOption = false;
    let color = "#81ecec";
    let interval_note = "";

    // ===== SET DATA FOR MODAL INFORMATION =====================
    if (forceAlert) {
      if (avg_duration_second >= 45 && avg_duration_second <= 90) {
        if (avg_interval_second >= min && avg_interval_second <= max) {
          if (newMinIntervalSeconds >= min && newMaxIntervalSeconds <= max) {
            // console.log('minIntervalSeconds', minIntervalSeconds)
            // console.log('maxIntervalSeconds', maxIntervalSeconds)
            // if (modus_contraction_intensitas_note !== "Tidak") {
            message = CONTRACTION_MESSAGE_REAL;
            isOption = true;
            color = "#ffeaa7";
            interval_note = "yes";
            // }
          }
        }
      }

      let data = {
        tot_observer_time: tot_observer_time,
        tot_contraction_count: tot_contraction_count,
        tot_contraction_time: tot_contraction_time,
        avg_contraction_time: avg_contraction_time,
        avg_interval_time: avg_interval_time,
        modus_contraction_intensitas_feel: modus_contraction_intensitas_feel,
        modus_contraction_intensitas_note:
          "rasa kontraksi menjalar sampai ke pinggang atau tulang ekor",
        modus_contraction_intensitas_value: modus_contraction_intensitas_note,
        show_message: message,
        show_message_color: color,
        show_option: isOption,
        interval_note: modus_contraction_intensitas_feel,
      };
      setDataInfo(data)
      setTimeout(() => {
        setIsShowModalInfo(true)
        // setSwitchMusic(false)
        setSwitchVoice(false)
        refAudioVoice.current.stop()
      }, 10);
    } else {
      if (tot_observer_time_second >= 3600) {
        if (avg_duration_second >= 45 && avg_duration_second <= 90) {
          if (avg_interval_second >= min && avg_interval_second <= max) {
            if (newMinIntervalSeconds >= min && newMaxIntervalSeconds <= max) {
              // console.log('minIntervalSeconds', minIntervalSeconds)
              // console.log('maxIntervalSeconds', maxIntervalSeconds)
              // if (modus_contraction_intensitas_note !== "Tidak") {
              message = CONTRACTION_MESSAGE_REAL;
              isOption = true;
              color = "#ffeaa7";
              interval_note = "yes";
              // }
            }
          }
        }

        let data = {
          tot_observer_time: tot_observer_time,
          tot_contraction_count: tot_contraction_count,
          tot_contraction_time: tot_contraction_time,
          avg_contraction_time: avg_contraction_time,
          avg_interval_time: avg_interval_time,
          modus_contraction_intensitas_feel: modus_contraction_intensitas_feel,
          modus_contraction_intensitas_note:
            "rasa kontraksi menjalar sampai ke pinggang atau tulang ekor",
          modus_contraction_intensitas_value: modus_contraction_intensitas_note,
          show_message: message,
          show_message_color: color,
          show_option: isOption,
          interval_note: modus_contraction_intensitas_feel,
        };
        setDataInfo(data)
        setTimeout(() => {
          setIsShowModalInfo(true)
          // setSwitchMusic(false)
          setSwitchVoice(false)
          refAudioVoice.current.stop()
        }, 10);
      }
    }

    // if(params === "back_save") {
    //     setTimeout(() => { setIsShowModalInfo(true) }, 300)
    // }
    // console.log("message", message)
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [showPopupList, setShowPopupList] = useState(false);

  const renderPopupList = () => {
    return (
      <PopupList
        isShowModal={showPopupList}
        title={"Pilih Musik"}
        list={listMusic}
        onClickItem={() => {}}
        closeModal={() => setShowPopupList(false)}
      />
    );
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        style={{
          position: "absolute",
          width: "100%",
          left: "auto",
          right: "auto",
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={"warning"}
          sx={{
            width: "80%",
            marginLeft: "10%",
            marginRight: "10%",
            marginBottom: "6vh",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  };

  const [isShowModalContractionInfo, setIsShowModalContractionInfo] =
    useState(false);
  const contractionInfoRef = useRef();

  const renderContractionInfoPopup = () => {
    return (
      <ContractionInfoAdd
        ref={contractionInfoRef}
        isShowModal={isShowModalContractionInfo}
        closeModal={() => setIsShowModalContractionInfo(false)}
        onSkip={onSkipContractionInfo}
        onSubmit={onSubmitContractionInfo}
        colors={{
          background: "",
          backgroundDark: "",
          backgroundLight: "",
          primaryColor: "white",
          secondaryColor: "",
          selectedBackground: "",
          selectedColor: "",
        }}
      />
    );
  };

  const onSkipContractionInfo = (index, data) => {
    onCalculateTotal();
    setIsShowModalContractionInfo(false);
  };

  const onSubmitContractionInfo = (index, params) => {
    onAddContractionInfo(index, params);
    setIsShowModalContractionInfo(false);
  };

  const onAddContractionInfo = (index, params) => {
    let newArrData = arrData;
    newArrData[index].data_jsonobject = params;
    setArrData(newArrData);
    onCalculateTotal();
  };

  const contractionNoteRef = useRef();

  const renderContractionNotePopup = () => {
    return (
      <ContractionMyNote
        ref={contractionNoteRef}
        onSubmit={onSubmitNote}
        colors={
          {
            // background: theme.colors.background,
            // backgroundDark: theme.colors.backgroundDark,
            // backgroundLight: theme.colors.backgroundLight,
            // primaryColor: theme.colors.primaryColor,
            // secondaryColor: theme.colors.primaryColorLight,
            // selectedBackground: theme.colors.primaryColor,
            // selectedColor: theme.colors.background,
          }
        }
      />
    );
  };

  const contractionNoteInfoRef = useRef();

  const renderContractionNoteInfoPopup = () => {
    return (
      <ContractionMyNoteInfo
        ref={contractionNoteInfoRef}
        colors={
          {
            // background: theme.colors.background,
            // backgroundDark: theme.colors.backgroundDark,
            // backgroundLight: theme.colors.backgroundLight,
            // primaryColor: theme.colors.primaryColor,
            // secondaryColor: theme.colors.primaryColorLight,
            // selectedBackground: theme.colors.primaryColor,
            // selectedColor: theme.colors.background,
          }
        }
      />
    );
  };

  const onSubmitNote = (index, objData) => {
    let newArrData = arrData;
    newArrData[index].data_jsonobject.my_note_object = objData;
    setArrData(newArrData);
  };

  return (
    <div
      style={{
        flex: 1,
        // backgroundColor: '#D400B8',
        position: "relative",
        "--background-themes": `url(${backgroundImageTheme})`,
        height: "100vh",
        height: "calc(var(--vh, 1vh) * 100)",
        display: "flex",
      }}
      className={`bg-themes background-theme-${theme}`}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxWidth: "100%",
        }}
      >
        {renderHeader()}
        {renderAudioVoices()}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderContents()}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 0,
            left: 0,
          }}
        >
          {renderStartStopButton()}
        </div>
        {renderSnackbar()}
        {renderContractionInfoPopup()}
        {renderPopupList()}
        {renderContractionNotePopup()}
        {renderContractionNoteInfoPopup()}

        <Modal animationType="fade" show={isShowModalSave} transparent centered>
          <div className={`background-theme-${theme}`} style={{ borderRadius: "15px", }}>
            <Modal.Header
              style={{
                width: "100%",
                height: 50,
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "15px 15px 0px 0px",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Simpan Data?
              </div>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "left", padding: "15px 0px" }}>
                <div
                  style={{ color: "#fff", fontSize: 14, textAlign: "center" }}
                >
                  Apakah Anda yakin ingin simpan data kontraksi?
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              style={{
                height: 70,
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "0px 0px 15px 15px",
              }}
            >
              <div
                style={{
                  borderRadius: 40,
                  width: 100,
                  height: 40,
                  backgroundColor: "rgba(0,0,0,0.1)",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsShowModalSave(false);
                }}
              >
                <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>
                  Tidak
                </div>
              </div>
              <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                <div
                  style={{
                    borderRadius: 40,
                    width: "100%",
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => onSaveListeners()}
                >
                  <i
                    className="fas fa-check icon ripple"
                    style={{ color: "#fff", fontSize: 14 }}
                  ></i>
                  <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>
                    Ya, Simpan
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </Modal>

        <Modal animationType="fade" show={showModalError} transparent centered>
          <div className={`background-theme-${theme}`} style={{ borderRadius: "15px", }}>\
            <Modal.Body>
              <div style={{ textAlign: "left", padding: "15px 0px" }}>
                <div
                  style={{ color: "#fff", fontSize: 14, textAlign: "center" }}
                >
                  { modalErrorMessage }
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              style={{
                height: 70,
                alignItems: "center",
                justifyContent: "end",
                paddingHorizontal: 20,
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "0px 0px 15px 15px",
              }}
            >
              <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                <div
                  style={{
                    borderRadius: 40,
                    width: "100%",
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowModalError(false)}
                >
                  <i
                    className="fas fa-check icon ripple"
                    style={{ color: "#fff", fontSize: 14 }}
                  ></i>
                  <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>
                    Tutup
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </Modal>

        <Modal animationType="fade" show={isShowModalInfo} transparent centered>
          <div className={`background-theme-${theme}`} style={{ borderRadius: "15px", }}>
            <Modal.Header
              style={{
                width: "100%",
                height: 50,
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "15px 15px 0px 0px",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
              }}
            >
              {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
              <div
                style={{
                  flex: 1,
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Hai Bunda
              </div>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "left", padding: "15px 0px" }}>
                <div style={{ color: "#fff", fontSize: 14, textAlign: "center" }}>
                  { dataInfo.show_message }
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              style={{
                height: 70,
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "0px 0px 15px 15px",
              }}
              onClick={() => {
                setIsShowModalInfo(false)
                setIsShowModalSave(true)
              }}
            >
              <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                <div
                  style={{
                    borderRadius: 40,
                    width: "100%",
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  
                >
                  <i
                    className="fas fa-check icon ripple"
                    style={{ color: "#fff", fontSize: 14 }}
                  ></i>
                  <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>
                    Simpan Kontraksi Ini
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: 40,
                  width: 100,
                  height: 40,
                  backgroundColor: "rgba(0,0,0,0.1)",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location = "https://elearning.bidankita.com/elearning";
                }}
              >
                <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>
                  Elearning
                </div>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
        <ModalMusicPlaylist
          ref={refModalPlaylist}
          onSelectedPlaylist={(value) => {
            setSelectedPlaylist(value);
            localStorage.setItem("ake_background_theme", value.background);
            changeBackground(value.background);
          }}
          selected={selectedPlaylist}
        />

      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto',
        bottom: 0
      }}
        open={showError}
        autoHideDuration={3000}
        onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }}>
        <Alert onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>
      </div>
    </div>
  );
};

export default Contraction;
