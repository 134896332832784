import React, { Component } from "react"
// import logo_bk from "../assets/BK/img_lady_pregnant.png"
import logo_bk from "../assets/BK/logo_BK.png"
import Header from "../components/Header"

export default class Privacy extends Component {
    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return(
          <div>
            <Header
                title={"Privacy Policy"}
                // navigation={this.props.navigation}
                backgroundColor={"#D400B8"}
                paddingHorizontal={20}
                fontSize={16}
                fontWeight={"bold"}
                textAlign={"center"}
                color={"#fff"}
            />
            <div style={{ minHeight:'100vh', padding: 15, paddingTop: 70, backgroundColor:"#D400B8" }}>
                <div style={{ color: "#FFF" }}>
                  Aplikasi Kontraksi Enak merupakan Situs atau Aplikasi milik Kami (CV Bidan Kita).
                  Kontraksi Enak dibuat untuk membantu ibu hamil dapat merasakan kenyamanan saat
                  melahirkan, sehingga mendapatkan pengalaman positif selama hamil dan melahirkan.
                  Kami bertanggung jawab dalam melindungi Data Pribadi Pengguna. Kami menghargai
                  setiap informasi pribadi yang diberikan dan berkomitmen untuk melindungi informasi
                  pribadi tersebut. <br></br><br></br>
                  Kebijakan Privasi ini menjelaskan bagaimana Kami mengumpulkan data, mengolah,
                  menggunakan dan mengungkapkan informasi Pengguna. Harap diperhatikan bahwa
                  Kami tidak bertanggung jawab atas situs web lain yang dikelola oleh perusahaan lain
                  dari grup CV. Bidan Kita ataupun tautan ke konten lain di internet yang dimiliki atau
                  dioperasikan oleh pihak ketiga. Di mana situs tersebut tidak berada di bawah kendali
                  Kami. Kami tidak bertanggung jawab atas kesalahan, kelalaian, penundaan,
                  pencemaran nama baik, fitnah, kebohongan, pornografi, konten cabul, ketidakakuratan
                  atau materi lain yang terkandung dalam konten, atau konsekuensi dari mengakses situs
                  web terkait.

                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Penerimaan dan Persetujuan Pengguna:
                  </div>
                  <div style={{ marginTop: 10 }}>
                    Apabila Pengguna mengakses atau menggunakan layanan atau produk Kami, maka
                    Pengguna dianggap telah menyetujui persyaratan yang terikat pada setiap ketentuan
                    yang tercantum dalam Kebijakan Privasi ini. Apabila Pengguna tidak setuju dengan
                    ketentuan apa pun dalam Kebijakan Privasi ini, maka jangan gunakan layanan atau
                    produk Kami. <br></br> <br></br>
                    Kami memiliki hak untuk merevisi dan/atau memperbarui Kebijakan Privasi Kami dari
                    waktu ke waktu dan Pengguna dianggap setuju untuk terikat dengan Kebijakan Privasi
                    yang direvisi dan/atau diperbarui tersebut. Apabila Pengguna tidak setuju dengan
                    ketentuan yang direvisi dan/atau diperbarui dalam Kebijakan Privasi, silakan berhenti
                    mengakses layanan atau produk Kami.<br></br> <br></br>
                    Penerimaan dan persetujuan terhadap Kebijakan Privasi ini juga akan berkaitan dengan
                    ketentuan yang tercantum berdasarkan Ketentuan Layanan dan akan dianggap dan
                    tidak dapat dipisahkan dan satu perjanjian yang mengatur hak dan kewajiban Kami
                    kepada Pengguna.
                  </div>


                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Privasi
                  </div>
                  <div style={{ marginTop: 10 }}>
                    Menghargai setiap informasi yang Pengguna berikan pada saat mengakses layanan
                    Kami. Informasi pribadi yang dikumpulkan akan disimpan dalam situs Kami. Informasi
                    pribadi tersebut dillindungi secara maksimal dari kehilangan, penyalahgunaan dan
                    pengungkapkan dari pihak yang tidak berwenang menggunakan metode-metode dan
                    teknologi terbaik.Setiap Pengguna layanan, sepakat untuk membebaskan Kami dari tanggungjawab atas
                    kerugian/ tuntutan/ hukuman dalam bentuk apapun yang disebabkan oleh akses tidak
                    sah, peretas, penyalahgunaan atau perubahan informasi oleh pihak lain yang
                    dikirimkan ke situs Kami.<br></br> <br></br>
                    Dalam situasi tertentu, Kami akan mengambil langkah-langkah penting dan wajar untuk
                    melakukan penyelidikan. Senantiasa menginformasikan kepada Pengguna apabila
                    terjadi penyalahgunaan data yang berkaitan dengan informasi pribadi Pengguna
                    tersebut.
                  </div>

                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Kerahasiaan Akun
                  </div>
                  <div style={{ marginTop: 10 }}>
                    Setiap Pengguna, wajib menjaga kerahasiaan akun dan kata sandi dari setiap aplikasi
                    yang diajukan. Setiap kewajiban yang disetujui, dimasukkan ke dalam dan semua
                    kegiatan lain yang dilakukan berdasarkan akun tersebut. Pengguna setuju untuk segera
                    memberi tahu Kami, tentang Penggunaan atau pengungkapan data yang tidak sah atas
                    akun atau kata sandi miliknya.<br></br> <br></br>
                    Kami tidak akan bertanggung jawab atas kerugian apa pun yang mungkin dialami oleh
                    Pengguna akibat penggunaan pihak ketiga atas akun atau kata sandi miliknya, baik
                    dengan atau tanpa sepengetahuan Pengguna dan tanpa kesalahan atau kelalaian dari
                    pihak Kami.
                  </div>

                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Pengumpulan Informasi Pribadi
                  </div>
                  <div>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                      <li style={{ fontFamily: 'Roboto', fontSize:20 }}>Pengumpulan Data</li>
                      <div>
                        Pengumpulan informasi pribadi terjadi Ketika Pengguna melakukan
                        pendaftaran akun dan pembelian aplikasi Kontraksi Enak. Pengumpulan
                        informasi tersebut merupakan bagian dari proses verifikasi identitas pelanggan.
                        Kami dapat mengumpulkan dan mengelola, namun tidak terbatas pada :
                      </div>
                      <ul style={{ listStyleType:'disc' }}>
                        <li>Informasi Pengguna (Nama, Alamat Email, No HP, atau informasi lainnya)</li>
                        <li>
                          Informasi apapun yang di unggah oleh Pengguna (foto, rekaman suara dan lain sebagainya)
                        </li>
                        <li>Informasi yang melekat saat Anda menggunakan situs atau aplikasi Kami</li>
                        <li>Informasi apapun yang tersedia dalam perangkat Pengguna yang di install, di mana Pengguna telah memberikan akses kepada Kami</li>
                        <li>Informasi yang dikumpulkan oleh Cookies dan log informasi umum</li>
                      </ul>

                      <li style={{ fontFamily: 'Roboto', fontSize:20 }}>Penggunaan Data</li>
                      <div>
                        Data Pengguna yang terkumpul dan diolah akan digunakan sebagai media
                        informasi atas promosi, perubahan, penambahan, dukungan iklan dan
                        kelengkapan administratif. Data Pengguna akan digunakan untuk:
                      </div>
                      <ul style={{ listStyleType:'disc' }}>
                        <li>
                          Mengirimkan informasi dan berkomunikasi dengan Pengguna, terkait
                          dengan promosi, pembaruan layanan, perubahan layanan, penawaran-
                          penawaran khusus baik dari CV. Bidan Kita maupun mitra
                        </li>
                        <li>
                          Meningkatkan produk dan layanan pada situs atau aplikasi Kami
                        </li>
                        <li>
                          Membangun komunikasi dengan Pengguna
                        </li>
                        <li>
                          Menggunakan data sebagai acuan identifikasi, verifikasi dan/atau
                          otentikasi data Pengguna
                        </li>
                        <li>
                          Menanggapi pertanyaan, permintaan, keluhan, komentar dan
                          kebutuhan Pengguna
                        </li>
                        <li>
                          Melakukan penagihan, memproses pembayaran dan Biaya Layanan
                        </li>
                        <li>
                          Mengelola partisipasi Pengguna dalam program yang Kami
                          selenggarakan
                        </li>
                        <li>
                          Menyelidiki dugaan kecurangan, pelecehan, ancaman fisik, pelanggaran
                          terhadap hukum, syarat dan ketentuan Penggunaan layanan, atau hak
                          pihak ketiga
                        </li>
                        <li>
                          Untuk tujuan internal CV. Bidan Kita, seperti proses audit, analisis data,
                          analisa pasar, dan sebagainya
                        </li>
                        <li>
                          Dan tujuan lainnya yang akan diungkapkan kepada Pengguna
                          sehubungan dengan Layanan (secara bersama-sama selanjutnya
                          disebut sebagai “Tujuan”).
                        </li>
                      </ul>
                    </ol>
                  </div>

                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Pengungkapan Data
                  </div>
                  <div>
                    Setiap Pengguna memahami dan menyetujui bahwa Kami mungkin memberikan data
                    dan/atau mengungkapkan informasi Pengguna dengan cara-cara yang terbatas, untuk
                    tujuan seperti :
                  </div>
                  <div>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                      <li>
                        Melaksanakan Tujuan sebagaimana disebutkan pada butir di atas, CV. Bidan
                        KIta dapat memberikan dan/atau mengungkapkan Informasi Pengguna kepada
                        anak perusahaan, afiliasi, perusahan terkait atau mitra usaha, ahli/penasehat,
                        konsultan, auditor eksternal serta pihak ketiga lainnya
                      </li>
                      <li>
                        Menjalankan perintah peraturan perundang-undangan, pengadilan lembaga
                        penegak hukum, badan pemerintahan atau instansi terkait lainnya dengan
                        terlebih dahulu pihak terkait menunjukan surat perintah pengungkapan data
                        pada Kami.
                      </li>
                      <li>
                        Kami dapat memberikan informasi himpunan atau anonim yang tidak langsung mengidentifikasikan Pengguna terkait
                      </li>
                      <li>
                        Kami tidak akan menyewakan, menjual, atau dengan cara lain informasi pribadi Pengguna kepada pihak lain tanpa persetujuan Pengguna terkait.
                      </li>
                    </ol>
                  </div>


                  <div style={{ fontFamily: 'Merriweather', fontSize: 23, marginTop: 20, fontWeight: 900 }}>
                    Hukum yang berlaku
                  </div>
                  <div style={{ marginTop: 10 }}>
                    Setiap referensi terhadap hukum dan peraturan yang berlaku berdasarkan Kebijakan
                    Privasi ini harus ditafsirkan sesuai dengan ketentuan yang tercantum di bawah hukum
                    dan peraturan Republik Indonesia.
                  </div>
                </div>

            </div>
          </div>
        )
    }
}