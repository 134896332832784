import React from "react"
import ApiHelpers from "../../helpers/ApiHelpers";
import { useHistory } from "react-router-dom"
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AutoLogin(){

  const history = useHistory()
  let query = useQuery();

  const onSetIsLogin = async (isLogin, data) => {
    return new Promise((resolve) => {
      let result = false
      localStorage.setItem('user_session', JSON.stringify(data))
      let auth = JSON.parse(localStorage.getItem('user_session'))
      if (auth !== null && auth.id !== undefined && auth.id !== "") {
        result = isLogin
      }
      return resolve(result)
    })
  }
  
  React.useEffect( () => {
    let email = query.get('email')
    let hash = query.get('hash')
    let redirect = query.get('redirect')
    redirect = redirect ? redirect.split(',') : []
    let last = query.get('last')
    
    async function getData(){
      let data = await ApiHelpers.post('', 'auth/login/auto', {
        email,
        hash,
        from: 'ake'
      })
      if (data.status === 200) {
        await onSetIsLogin(true, data.results.data)
        if(redirect.length > 0){
          let link = redirect[0]
          redirect.shift()
          let nextLink = redirect.toString()
          window.location.replace(`${link}?email=${email}&hash=${hash}&redirect=${nextLink}&last=${last}`)
        }else{
          window.location.replace(last)
        }
      }else{
        if (redirect.length > 0) {
          let link = redirect[0]
          redirect.shift()
          let nextLink = redirect.toString()
          window.location.replace(`${link}?email=${email}&hash=${hash}&redirect=${nextLink}&last=${last}`)
        } else {
          window.location.replace(last)
        }
      }

      console.log(data)

    }

    getData()
    
  }, [query])

  return (
    <>
    </>
  )
}