import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Snackbar from "@mui/material/Snackbar"; //https://mui.com/getting-started/usage/
import Alert from "@mui/material/Alert"; //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal";
import ApiHelpers from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
import { useTheme } from "./../../hooks/useTheme";

import icon_contraction_lembut from "../../assets/icon_contraction_lembut.png";
import icon_contraction_sedang from "../../assets/icon_contraction_sedang.png";
import icon_contraction_kuat from "../../assets/icon_contraction_kuat.png";
import icon_contraction_intens from "../../assets/icon_contraction_intens.png";

const width = "100%";
const FEEL_SIZE = (width * 0.9 - 30 - 45) / 5;
const FEEL_ICON_SIZE = FEEL_SIZE * 0.7;
const ARR_FEEL = [
  { value: "Lembut", icon: icon_contraction_lembut, is_selected: false },
  { value: "Sedang", icon: icon_contraction_sedang, is_selected: false },
  { value: "Kuat", icon: icon_contraction_kuat, is_selected: false },
  { value: "Intens", icon: icon_contraction_intens, is_selected: false },
];
const ARR_NOTE = [
  { value: "Ya", is_selected: false },
  { value: "Tidak", is_selected: false },
  { value: "Kadang", is_selected: false },
];

const ContractionInfoAdd = forwardRef(
  (
    {
      isShowModal,
      colors,
      closeModal = () => {},
      onSkip = () => {},
      onSubmit = () => {},
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      onSetData(index, data) {
        onSetData(index, data);
      },
    }));
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [data, setData] = useState(null);
    const [index, setIndex] = useState(0);
    const [arrFeel, setArrFeel] = useState([]);
    const [arrNote, setArrNote] = useState([]);
    const { theme, backgroundImageTheme, changeTheme, changeBackground } =
    useTheme();

    const onSetData = (index, data) => {
      setData(data);
      setIndex(index);
      onInitializeArrOption(data);
    };

    const onInitializeArrOption = (data) => {
      if (data != null) {
        let arrFeel = ARR_FEEL;
        let arrNote = ARR_NOTE;
        arrFeel.map((item) => {
          let feel =
            data.data_jsonobject.feel !== undefined &&
            data.data_jsonobject.feel !== ""
              ? data.data_jsonobject.feel
              : "";
          item.is_selected = false;
          if (feel === item.value) {
            item.is_selected = true;
          }
        });
        arrNote.map((item) => {
          item.is_selected = false;
          let feel_note =
            data.data_jsonobject.feel_note !== undefined &&
            data.data_jsonobject.feel_note.value !== undefined &&
            data.data_jsonobject.feel_note.value !== ""
              ? data.data_jsonobject.feel_note.value
              : "";
          if (feel_note === item.value) {
            item.is_selected = true;
          }
        });
        setArrFeel(arrFeel);
        setArrNote(arrNote);
      }
    };

    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectedItems = (params, value, index) => {
      setSelectedItem(value);
      let newData = data;
      if (params === "feel") {
        let newArrFeel = arrFeel;
        newData.data_jsonobject.feel = value;
        newArrFeel.map((item, key) => {
          item.is_selected = false;
          if (key === index) {
            item.is_selected = true;
          }
        });
        setArrFeel(newArrFeel);
        setData(newData);
      } else {
        let newArrNote = arrNote;
        newData.data_jsonobject.feel_note.value = value;
        newArrNote.map((item, key) => {
          item.is_selected = false;
          if (key === index) {
            item.is_selected = true;
          }
        });
        setArrNote(newArrNote);
        setData(newData);
      }
    };
    const onSubmitListeners = () => {
      let newData = data;
      let valid = false;
      let errMessage =
        "Bunda belum memilih intensitas kontraksi dan yang bunda rasakan kontraksi.";
      if (
        newData.data_jsonobject.feel === "" &&
        newData.data_jsonobject.feel_note.value === ""
      ) {
        errMessage =
          "Bunda belum memilih intensitas kontraksi dan yang bunda rasakan kontraksi.";
      } else {
        if (newData.data_jsonobject.feel == "") {
          valid = false;
          errMessage = "Bunda belum memilih intensitas kontraksi.";
        } else {
          if (newData.data_jsonobject.feel_note.value == "") {
            valid = false;
            errMessage = "Bunda belum memilih rasa kontraksi.";
          } else {
            valid = true;
            errMessage = "";
          }
        }
      }

      if (valid) {
        onSubmit(index, newData.data_jsonobject);
      } else {
        setSnackbarMessage(errMessage);
        setShowSnackbar(true);
      }
    };
    const onSkipListeners = () => {
      let newData = data;
      onSkip(index, newData.data_jsonobject);
    };

    const renderContent = () => {
      let objData = data;
      if (data !== null) {
        return (
          <div style={{ flex: 1 }}>
            {/* <Animated.Scrolldiv showsVerticalScrollIndicator={false}> */}
            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 30 }}>
              <div
                style={{
                  fontSize: 14,
                  color: colors.primaryColor,
                  textAlign: "center",
                }}
              >
                Bagaimana kontraksi itu terasa?
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              {renderFeel()}
            </div>
            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 20 }}>
              <div
                style={{
                  fontSize: 14,
                  color: colors.primaryColor,
                  textAlign: "center",
                }}
              >
                Apakah {objData.data_jsonobject.feel_note.title}
              </div>
            </div>
            <div>{renderNote()}</div>
            {/* </Animated.Scrolldiv> */}
          </div>
        );
      }
    };

    const renderFeel = () => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {arrFeel.map((item, key) => {
            console.log({
              item,
              selectedItem,
            });
            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: key !== 0 ? 15 : 0,
                  cursor: 'pointer'
                }}
                onClick={() => onSelectedItems("feel", item.value, key)}
              >
                <div
                  style={{
                    borderRadius: FEEL_SIZE / 2,
                    width: FEEL_SIZE,
                    height: FEEL_SIZE,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={item.icon}
                    style={{
                      width: FEEL_ICON_SIZE,
                      height: FEEL_ICON_SIZE,
                      resizeMode: "cover",
                      opacity: item.is_selected ? 1 : 0.2,
                    }}
                  />
                </div>
                <div
                  numberOfLines={1}
                  style={{
                    fontSize: 11,
                    marginTop: 5,
                    color: item.is_selected
                      ? colors.primaryColor
                      : "rgba(255,255,255,0.4)",
                    fontWeight: "bold",
                  }}
                >
                  {item.value}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    width: 5,
                    height: 5,
                    borderRadius: 5 / 2,
                    backgroundColor: item.is_selected
                      ? colors.primaryColor
                      : colors.background,
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    };

    const renderNote = () => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {arrNote.map((item, key) => {
            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: key !== 0 ? 15 : 0,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  onSelectedItems("feel_note", item.value, key);
                }}
              >
                <div
                  numberOfLines={1}
                  style={{
                    fontSize: 16,
                    marginTop: 5,
                    color: item.is_selected
                      ? "#FFFFFF"
                      : "rgba(255,255,255,0.4)",
                    fontWeight: "bold",
                  }}
                >
                  {item.value}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    width: 5,
                    height: 5,
                    borderRadius: 5 / 2,
                    backgroundColor: item.is_selected
                      ? colors.primaryColor
                      : colors.background,
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    };

    const renderFooter = () => {
      return (
        <div
          style={{
            paddingBottom: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.2)",
              paddingLeft: 25,
              paddingRight: 25,
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 20,
              cursor: 'pointer'
            }}
            onClick={onSubmitListeners}
          >
            <i className="fa fa-check" style={{ color: "white" }} />
            <div
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: colors.primaryColor,
                marginLeft: 5,
              }}
            >
              Tambahkan Info
            </div>
          </div>
          <div onClick={onSkipListeners}>
            <div
              style={{
                fontSize: 14,
                color: colors.primaryColor,
                marginLeft: 20,
                cursor: 'pointer'
              }}
            >
              Lewati
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        onClick={() => {
          // closeModal()
        }}
      >
        <Modal animationType="fade" show={isShowModal} transparent centered>
          <div>
            <div
              className={`background-theme-${theme}`}
              style={{
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.2)",
                  paddingRight: 20,
                  paddingLeft: 20,
                  paddingTop: 20,
                  paddingBottom: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {"Yang Anda Rasakan"}
                </div>
                <i
                  className="fas fa-times-circle mg-right10"
                  style={{ color: "white", cursor: 'pointer' }}
                  onClick={() => closeModal()}
                />
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {renderContent()}
              </div>
              <div>{renderFooter()}</div>
            </div>
            <Snackbar
              style={{
                position: "absolute",
                width: "100%",
                left: "auto",
                right: "auto",
              }}
              open={showSnackbar}
              autoHideDuration={6000}
              onClose={() => setShowSnackbar(false)}
            >
              <Alert
                onClose={() => setShowSnackbar(false)}
                severity={"warning"}
                sx={{
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginBottom: "6vh",
                }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </Modal>
      </div>
    );
  }
);

export default ContractionInfoAdd;
