import React, { Component, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar"; //https://mui.com/getting-started/usage/
import Alert from "@mui/material/Alert"; //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal";
import ApiHelpers from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
// import useSound from "use-sound";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const PopupList = ({
  isShowModal,
  closeModal = () => {},
  title,
  list = [],
  onClickItem = () => {},
}) => {
  const [selectedItem, setSelectedItem] = useState({ name: "", music: [] });
  const [selectedSound, setSelectedSound] = useState({ name: "", music: [] });

  // const [play] = useSound(selectedSound, { volume: 0.5 });
  const [playing, toggle] = useAudio(selectedSound);
  const onSelectItem = (item) => {
    setSelectedItem(item);
    setSelectedSound(
      item.music[Math.floor(Math.random() * item.music.length)].url
    );

    toggle();
    // play();
  };

  const renderListItem = (item, index) => {
    return (
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => {
          onSelectItem(item);
        }}
      >
        <div>
          <div>
            <i className="fa fa-list-music" style={{ color: "white" }} />
            <div>
              <div>{item.name}</div>
              <div>{`${item.music.length} media`}</div>
            </div>
          </div>
        </div>
        {/* <input
          type={"radio"}
          name={`${item.title}`}
          value={""}
          checked={selectedItem == item}
          onChange={() => {}}
        /> */}
        {renderPlayButton(item)}
      </div>
    );
  };

  const renderPlayButton = (item) => {
    if (selectedItem.name == item.name) {
      return <i class="fas fa-play-circle-o" style={{ color: "white" }}></i>;
    } else {
      return <i className="fas fa-play-circle" style={{ color: "white" }} />;
    }
  };
  return (
    <div
      onClick={() => {
        // closeModal()
      }}
    >
      <Modal animationType="fade" show={isShowModal} transparent centered>
        <div onClick={() => console.log("click content")}>
          <div
            style={{
              backgroundColor: "#9F4096",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.05)",
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 20,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {title}
              </div>
              <i
                className="fas fa-times-circle mg-right10"
                style={{ color: "white" }}
                onClick={() => closeModal()}
              />
            </div>
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <FlatList
                list={list}
                renderItem={renderListItem}
                renderWhenEmpty={() => <div>List is empty!</div>}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PopupList;
