import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react"
import Modal from "react-bootstrap/Modal"

import ApiHelpers from "../../../helpers/ApiHelpers"

import './Style.css'
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from './../../../hooks/useTheme';

const ModalMusicPlaylist = ((props, ref) => {
  const { theme, changeTheme } = useTheme()

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [arrPlaylist, setArrPlaylist] = useState([]);

  useImperativeHandle(
    ref,
    () => ({
      showModal
    }),
  )

  useEffect(() => {
    if (show) {
      getPlaylist()
    }
  }, [show])


  const showModal = (value) => {
    setShow(value)
  }

  const getPlaylist = async () => {
    setLoading(true)

    let response = await ApiHelpers.get("", 'general/music_by_category', { limit: 1000 })

    if (response.status === 200) {
      setArrPlaylist(response.results.data)
    } else {
      alert('Gagal memuat musik')
    }

    setLoading(false)
  }

  const renderPlaylistItem = () => {
    return (
      <>
        {
          loading
            ?
            [...Array(6)].map((item, key) => {
              return (
                <div className="playlist-item-skeleton" key={key}>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(255,255,255, 1)', width: '100%' }}>
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                    <div style={{ width: 'calc(100% - 60px)', paddingLeft: 10, paddingRight: 10 }}>
                      <Skeleton animation="wave" height={22} style={{ width: '90%' }} />
                      <Skeleton animation="wave" height={18} style={{ width: '40%' }} />
                    </div>
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                  </div>
                </div>
              )
            })
            :
            arrPlaylist.map((item, key) => {
              return (
                <div className="playlist-item ripple" key={key} onClick={() => {
                  props.onSelectedPlaylist && props.onSelectedPlaylist({ ...item, background: item.music[0].background, indexPlay: 0 })
                  setShow(false)
                }}>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'rgba(255,255,255, 1)', width: '100%' }}>
                    <i class="fa fa-music" style={{ color: '#fff', fontSize: 20, marginRight: 20 }}></i>
                    <div style={{ width: 'calc(100% - 80px)' }}>
                      <div style={{ fontSize: 14 }} className="text-truncate">{item.name}</div>
                      <div style={{ fontSize: 12 }}>{item.music.length} media</div>
                    </div>
                    <i class="fa fa-play-circle" style={{ color: props.selected && props.selected.name === item.name ? '#ffeb3b' : '#fff', fontSize: 20, marginLeft: 20 }}></i>
                  </div>
                </div>
              )
            })
        }
      </>
    )
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={true}
        className="modal-music-playlist"
        centered
      >
        <div className={`background-theme-${theme}`}>
          <Modal.Header className={`background-light-theme-${theme}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
              <div style={{ fontWeight: 'bold' }}>Pilih Playlist</div>
              <i class="fa fa-times-circle ripple" style={{ color: '#fff', fontSize: 20, cursor: 'pointer' }} onClick={() => setShow(false)}></i>
            </div>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'auto', maxHeight: 480 }}>
            {renderPlaylistItem()}
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
})

export default forwardRef(ModalMusicPlaylist);