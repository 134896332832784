import moment from "moment";
import React, { useState, useRef, useEffect } from "react";

// COTRACTION ICON REFFERENCES
import icon_contraction_lembut from "../../assets/icon_contraction_lembut.png";
import icon_contraction_sedang from "../../assets/icon_contraction_sedang.png";
import icon_contraction_kuat from "../../assets/icon_contraction_kuat.png";
import icon_contraction_intens from "../../assets/icon_contraction_intens.png";

const ContractionItem = ({
  index,
  item,
  onClickEye = () => {},
  onClickCatatan = () => {},
  onClickIntensitas = () => {},
  onClickHapus = () => {},
}) => {
  const renderFeels = (objData) => {
    if (
      objData.data_jsonobject.feel !== undefined &&
      objData.data_jsonobject.feel != ""
    ) {
      let img_source = icon_contraction_lembut;
      if (objData.data_jsonobject.feel === "Sedang") {
        img_source = icon_contraction_sedang;
      }
      if (objData.data_jsonobject.feel === "Kuat") {
        img_source = icon_contraction_kuat;
      }
      if (objData.data_jsonobject.feel === "Intens") {
        img_source = icon_contraction_intens;
      }

      return (
        <img
          src={img_source}
          style={{ width: 16, height: 16, resizeMode: "cover", marginLeft: 10 }}
          // placeholderStyle={{backgroundColor:'rgba(0,0,0,0.0)'}}
        />
      );
    } else {
      return <div style={{ width: 16, height: 16, marginLeft: 10 }} />;
    }
  };

  return (
    <div
      style={{
        marginBottom: 10,
      }}
    >
      <div
        key={index}
        style={{
          backgroundColor: "rgba(0,0,0,0.2)",

          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: 0.33 }}>
          <div
            style={{
              fontSize: 9,
              color: "rgba(255,255,255,0.7)",
              fontWeight: "bold",
            }}
          >
            MULAI
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#fff",
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {item.start_datetime !== ""
              ? moment(item.start_datetime).format("HH:mm:ss")
              : "-"}
          </div>
        </div>
        <div style={{ flex: 0.33 }}>
          <div
            style={{
              fontSize: 9,
              color: "rgba(255,255,255,0.7)",
              fontWeight: "bold",
            }}
          >
            BERAKHIR
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#dfe6e9",
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {item.end_datetime !== ""
              ? moment(item.end_datetime).format("HH:mm:ss")
              : "-"}
          </div>
        </div>
        <div
          style={{
            flex: 0.33,
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              fontSize: 9,
              color: "rgba(255,255,255,1.0)",
              fontWeight: "bold",
            }}
          >
            DURASI
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#fdcb6e",
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {item.duration_label}
          </div>
        </div>
        <div
          style={{
            flex: 0.33,
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              fontSize: 9,
              color: "rgba(255,255,255,1.0)",
              fontWeight: "bold",
            }}
          >
            INTERVAL
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#74b9ff",
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {item.interval !== "00:00" ? item.interval_label : "-"}
          </div>
        </div>
        {renderFeels(item)}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "yellow",
            height: 24,
            paddingLeft: 5,
            paddingRight: 5,
            marginRight: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            cursor: 'pointer'
          }}
          className="ripple"
          onClick={onClickEye}
        >
          <i className="fa fa-eye" style={{ fontSize: 14 }} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "yellow",
            paddingLeft: 5,
            height: 24,
            paddingRight: 5,
            marginRight: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            fontSize: 14,
            cursor: 'pointer'
          }}
          className="ripple"
          onClick={onClickCatatan}
        >
          <i
            className="fa fa-pencil"
            style={{ marginRight: 5, fontSize: 14 }}
          />
          <div>{`Catatan`}</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "yellow",
            paddingLeft: 5,
            height: 24,
            paddingRight: 5,
            marginRight: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            fontSize: 14,
            cursor: 'pointer'
          }}
          className="ripple"
          onClick={onClickIntensitas}
        >
          <i className="fa fa-plus" style={{ marginRight: 5, fontSize: 14 }} />
          <div>{`Intensitas`}</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "yellow",
            paddingLeft: 5,
            height: 24,
            paddingRight: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            fontSize: 14,
            cursor: 'pointer'
          }}
          className="ripple"
          onClick={onClickHapus}
        >
          <i className="fa fa-trash" style={{ marginRight: 5, fontSize: 14 }} />
          <div>{`Hapus`}</div>
        </div>
      </div>
    </div>
  );
};

export default ContractionItem;
