import React, { useState, useRef, useCallback, useEffect } from "react"
import ApiHelpers from "../../helpers/ApiHelpers"
import Modal from "react-bootstrap/Modal"
import Header from "../../components/Header"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import Loading from "../../components/Loading"
import img_subscribe_type from "../../assets/BK/img_subscribe_type.png"
import Tabs from '@mui/material/Tabs' //https://mui.com/getting-started/usage/
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab'
import { useHistory, useParams } from "react-router-dom"
import { useTheme } from './../../hooks/useTheme';

export default function SubscriptionsHistory() {

  const { theme } = useTheme()

  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [value, setValue] = useState('semua');
  const history = useHistory()


  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    setValue('semua')
    // getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'transaction', {
      "sort": "-id",
      "limit": 10,
      "status": value === 'semua' ? '' : value
    })
    if (response.status === 200) {
      objData = response.results.data
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDetail = (data) => {
    // localStorage.setItem('subscriptions_id', JSON.stringify(data))
    history.push(`/subscriptions-history-detail/${data}`)
    // history.push(`/subscriptions-history-detail`)
  }

  const handleCaraBayar = (data) => {
    // localStorage.setItem('subscriptions_id', JSON.stringify(data))
    history.push(`/subscriptions-payment/${data}`)
    // history.push(`/subscriptions-payment`)
  }


  useEffect(() => {
    onRefresh()
  }, [])

  useEffect(() => {
    if (value) {
      seIsFetched(true)
      getData()
    }
  }, [value])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      if (fetchData.length > 0) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>

              {fetchData.map((item) => {
                let bgColor = "#ffeaa7"
                let ftColor = "#000"
                if (item.status === "selesai") {
                  bgColor = "#55efc4"
                  ftColor = "#000"
                }
                if (item.status === "dibatalkan") {
                  bgColor = "#ff7675"
                  ftColor = "#FFF"
                }
                return (
                  <div style={{ marginBottom: 15 }}>
                    <div
                      style={{ paddingLeft: 15, paddingRight: 15 }}>
                      <div style={{ borderRadius: 10, 
                        // backgroundColor: '#9a2c90', 
                        padding: '20px 15px' }} className={`background-dark-theme-${theme}`}>
                        <div style={{
                          backgroundColor: bgColor, paddingLeft: 5, paddingRight: 5, paddingTop: 2.5, paddingBottom: 2.5, borderRadius: 10, width: 70,
                          alignItems: 'center', justifyContent: 'center', marginBottom: 5, textAlign: 'center'
                        }}>
                          <div style={{ color: ftColor, textTransform: "uppercase", fontSize: 8, fontWeight: 'bold' }}>{item.status}</div>
                        </div>
                        <div style={{ fontWeight: 'bold', fontSize: 16, color: '#fff' }}>
                          {item.trans_number}
                        </div>
                        <div style={{ fontSize: 14, marginTop: 5, color: 'rgba(255,255,255,0.7)' }}>
                          {item.package_name}
                        </div>
                        <div style={{
                          fontSize: 14,
                          marginRight: 10,
                          color: "#ffeaa7",
                          marginTop: 5,
                          fontWeight: 'bold'
                        }}>
                          {MyHelpers.formatNumber(item.grand_total, 'idr')}
                        </div>
                        <div style={{
                          marginTop: 15,
                          textAlign: 'right'
                        }}>
                          <span
                            onClick={() => { handleDetail(item.id) }}
                            style={{ color: 'rgba(255,255,255,0.5)', fontSize: 15, fontWeight: 'bold', cursor: 'pointer', padding: 10 }}
                            className="ripple">
                            DETAIL
                          </span>
                          {
                            item.status === "belum bayar" &&
                            <span
                              onClick={() => { handleCaraBayar(item.id) }}
                              style={{ color: '#FFF', fontSize: 15, fontWeight: 'bold', cursor: 'pointer', padding: 10, marginLeft: 10 }}
                              className="ripple">
                              BAYAR
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: "center", minHeight: "calc(100vh - 60px)", display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={img_subscribe_type} style={{ width: 120, height: 120, resizeMode: 'contain' }}
                placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
              />
              <div style={{ fontSize: 16, color: '#FFF', fontWeight: "bold", marginTop: 30 }}>Riwayat Tidak Tersedia</div>
              <div style={{ fontSize: 12, marginTop: 13, color: '#FFF' }}>Riwayat transaksi tidak ditemukan atau,</div>
              <div style={{ fontSize: 12, marginTop: 3, color: '#FFF' }}>riwayat yang Anda cari tidak tersedia.</div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }

  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Riwayat Transaksi"}
        // navigation={props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        <div style={{
          paddingTop: 15,
          paddingBottom: 15
        }}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="secondary tabs example">
              <Tab label="Semua" style={{ color: value === 'semua' ? '#FFF' : 'rgba(255,255,255,0.59)', textTransform: 'none', fontWeight: 900 }} value="semua" />
              <Tab label="Belum Bayar" style={{ color: value === 'belum bayar' ? '#FFF' : 'rgba(255,255,255,0.59)', textTransform: 'none', fontWeight: 900 }} value="Semua" value="belum bayar" />
              <Tab label="Selesai" style={{ color: value === 'selesai' ? '#FFF' : 'rgba(255,255,255,0.59)', textTransform: 'none', fontWeight: 900 }} value="Semua" value="selesai" />
              <Tab label="Dibatalkan" style={{ color: value === 'dibatalkan' ? '#FFF' : 'rgba(255,255,255,0.59)', textTransform: 'none', fontWeight: 900 }} value="Semua" value="dibatalkan" />
            </Tabs>
          </Box>
        </div>
        {renderContents()}
      </div>
    </div>
  )
  // }
}