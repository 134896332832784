const { atom } = require("recoil")

const authenticated = atom ({
    key: "authenticated",
    default: {
        check: false,
        user: {
            "id": 0,
            "name": "Kode Kreativ",
            "username": "kodekreativ",
            "email": "kodekreativ@gmail.com",
            "image_url": "https://billatowing.com/wp-content/uploads/2016/06/team-1.jpg",
            "role":"development"
        }
        // user: userAuth()
    }
})

const userAuth = () => {
    let response = localStorage.getItem("user_session")
    if(response !== 'null' && response !== '' && response !== null){
        return JSON.parse(response)
    }else{
        return {}
    }
}

export { authenticated, userAuth }