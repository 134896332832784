import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"

import { useTheme } from "../../hooks/useTheme";

import './Style.css'

import moment from "moment"
import "moment/locale/id";
import { getBottomNavigationUtilityClass } from "@mui/material";

export default function DatePicker(props) {
  const { theme, changeTheme } = useTheme()

  const [mode, setMode] = useState('date');

  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null)

  const [arrDate, setArrDate] = useState([]);
  const [arrMonth, setArrMonth] = useState([
    { value: "01", title: "Januari" },
    { value: "02", title: "Februari" },
    { value: "03", title: "Maret" },
    { value: "04", title: "April" },
    { value: "05", title: "Mei" },
    { value: "06", title: "Juni" },
    { value: "07", title: "Juli" },
    { value: "08", title: "Agustus" },
    { value: "09", title: "September" },
    { value: "10", title: "Oktober" },
    { value: "11", title: "November" },
    { value: "12", title: "Desember" },
  ]);
  const [arrYear, setArrYear] = useState([]);

  useEffect(() => {
    if (props.show) {
      let selectDay = props.date !== '' ? moment(props.date).format('DD') : moment().format('DD')
      let selectMonth = props.date !== '' ? moment(props.date).format('MM') : moment().format('MM')
      let selectYear = props.date !== '' ? moment(props.date).format('YYYY') : moment().format('YYYY')

      setDate(selectDay)
      setMonth(selectMonth)
      setYear(selectYear)

      setSelectedDate(`${selectYear}-${selectMonth}-${selectDay}`)

      setMode('date')
    }

  }, [props.show]);

  useEffect(() => {
    generateDate()
  }, [date, month, year])

  useEffect(() => {
    if (mode === 'date') {
      generateDate()
    }

    if (mode === 'year') {
      generateYear()
    }
  }, [mode])

  const handleSelectDate = (item) => {
    setDate(item)
    setSelectedDate(`${year}-${month}-${String(item).padStart(2, '0')}`)

    props.setDate(`${year}-${month}-${String(item).padStart(2, '0')}`)
    props.setShow(false)
  }

  const generateDate = () => {
    let dataDate = ['Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab', 'Min']

    let dateMonth = moment(`${year}-${month}-${date}`).startOf('month').format('YYYY-MM-DD')

    let dataMonth = moment(dateMonth).format('MM')
    let dataYear = moment(dateMonth).format('YYYY')

    let firstDay = Number(moment(dateMonth).format('d'))
    let endDate = new Date(dataYear, dataMonth, 0).getDate()

    let startDate = 1

    for (let i = 1; i <= 42; i++) {
      if (i >= firstDay) {
        if (startDate <= endDate) {
          dataDate.push(`${startDate}`)
          startDate++
        } else {
          dataDate.push('')
        }
      } else {
        dataDate.push('')
      }
    }

    setArrDate(dataDate);
  }

  const generateYear = () => {
    let selectedYear = year
    let arrYears = []
    arrYears.push(selectedYear)
    for (let index = 1; index < 12; index++) {
      let year = Number(selectedYear)
      year = year - index
      arrYears.push(year.toString())
    }
    arrYears.sort()

    setArrYear(arrYears)
  }

  const isTextDate = (item) => {
    if (!Number.isInteger(parseInt(item))) {
      return false
    }

    return true
  }

  const renderDate = () => {

    let thisDay = "-"
    let activeDay = "-"

    if (`${year}-${month}` === moment().format("YYYY-MM")) {
      thisDay = moment().format("D")
    }

    if (`${year}-${month}` === moment(selectedDate).format("YYYY-MM")) {
      activeDay = moment(selectedDate).format("D")
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
            <div className="ripple" onClick={() => {
              let prevMonth = moment(`${year}-${month}-01`).subtract(1, 'M').format('YYYY-MM-DD')
              setMonth(moment(prevMonth).format("MM"))
              setYear(moment(prevMonth).format("YYYY"))
            }}>
              <i className="fas fa-chevron-circle-left" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
            <div style={{ padding: '5px 10px', border: '1px solid rgba(255,255,255, 1)', borderRadius: 5, fontWeight: 'bold', cursor: 'pointer' }} className="ripple" onClick={() => setMode('month')}>
              {moment(`${year}-${month}`).format("MMMM")} {moment(`${year}-${month}`).format("YYYY")}
            </div>
            <div className="ripple" onClick={() => {
              let nextMonth = moment(`${year}-${month}-01`).add(1, 'M').format('YYYY-MM-DD')
              setMonth(moment(nextMonth).format("MM"))
              setYear(moment(nextMonth).format("YYYY"))
            }}>
              <i className="fas fa-chevron-circle-right" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
          </div>
        </div>
        <div style={{ color: '#fff' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            {
              arrDate.map((item, key) => {
                return (
                  <div style={{
                    width: '14.2%',
                    height: 35,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }} key={key}>
                    <div style={{
                      fontSize: thisDay === item ? 14 : 12,
                      height: 30,
                      width: 30,
                      borderRadius: '50%',
                      cursor: isTextDate(item) ? 'pointer' : '',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: activeDay === item ? '#fff' : '',
                      color: activeDay === item ? '#333' : '',
                      position: 'relative'
                    }}
                      className={isTextDate(item) ? 'ripple' : ''}
                      onClick={() => {
                        if (isTextDate) {
                          handleSelectDate(item)
                        }
                      }}
                    >
                      {item}
                      {
                        (isTextDate(item) && thisDay === item)
                        &&
                        <div style={{
                          position: 'absolute',
                          bottom: 2,
                          width: 5,
                          height: 5,
                          borderRadius: 5 / 2,
                          backgroundColor: thisDay === item && activeDay !== item ? '#fff' : 'rgba(0,0,0,0.4)',
                        }} />
                      }

                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }

  const renderMonth = () => {

    let thisMonth = "-"
    let activeMonth = "-"

    if (year === moment().format("YYYY")) {
      thisMonth = moment().format("MM")
    }

    if (year === moment(selectedDate).format("YYYY")) {
      activeMonth = moment(selectedDate).format("MM")
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
            <div className="ripple" onClick={() => {
              let num = Number(year) - 1
              setYear(num.toString())
            }}>
              <i className="fas fa-chevron-circle-left" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
            <div style={{ padding: '5px 10px', border: '1px solid rgba(255,255,255, 1)', borderRadius: 5, fontWeight: 'bold', cursor: 'pointer' }} className="ripple" onClick={() => {
              setMode('year');
            }}>
              {moment(`${year}-${month}`).format("YYYY")}
            </div>
            <div className="ripple" onClick={() => {
              let num = Number(year) + 1
              setYear(num.toString())
            }}>
              <i className="fas fa-chevron-circle-right" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
          </div>
        </div>
        <div style={{ color: '#fff' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            {
              arrMonth.map((item, key) => {
                return (
                  <div style={{
                    width: '33.3%',
                    height: 35,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10
                  }} key={key}>
                    <div style={{
                      height: 30,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: activeMonth == item.value ? '#fff' : '',
                      color: activeMonth == item.value ? '#333' : '',
                      cursor: 'pointer',
                      position: 'relative'
                    }}
                      className="ripple" onClick={() => {
                        setMonth(item.value)
                        setMode('date')
                      }}
                    >
                      {item.title}
                      {
                        (thisMonth === item.value)
                        &&
                        <div style={{
                          position: 'absolute',
                          bottom: 2,
                          width: 5,
                          height: 5,
                          borderRadius: 5 / 2,
                          backgroundColor: thisMonth === item.value && activeMonth !== item.value ? '#fff' : 'rgba(0,0,0,0.4)',
                        }} />
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }

  const renderYear = () => {
    let thisYear = moment().format("YYYY")
    let activeYear = moment(selectedDate).format("YYYY")

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
            <div className="ripple" onClick={() => {
              let arrYears = []
              let first = (arrYear[0] - 1).toString()
              arrYears.push(first)
              for (let index = 1; index < 12; index++) {
                let year = Number(first)
                year = year - index
                arrYears.push(year.toString())
              }
              arrYears.sort()

              setArrYear(arrYears)
            }}>
              <i className="fas fa-chevron-circle-left" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
            <div style={{ padding: '5px 10px', fontWeight: 'bold' }}>
              Pilih Tahun
            </div>
            <div className="ripple" onClick={() => {
              let arrYears = []
              let last = moment(arrYear[11], "YYYY").add(12, 'years')
              last = moment(last).format("YYYY")
              arrYears.push(last)
              for (let index = 1; index < 12; index++) {
                let year = Number(last)
                year = year - index
                arrYears.push(year.toString())
              }
              arrYears.sort()

              setArrYear(arrYears)
            }}>
              <i className="fas fa-chevron-circle-right" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }}></i>
            </div>
          </div>
        </div>
        <div style={{ color: '#fff' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            {
              arrYear.map((item, key) => {
                return (
                  <div style={{
                    width: '33.3%',
                    height: 35,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10
                  }} key={key}>
                    <div style={{
                      height: 30,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: activeYear === item ? '#fff' : '',
                      color: activeYear === item ? '#333' : '',
                      cursor: 'pointer',
                      position: 'relative'
                    }}
                      className="ripple" onClick={() => {
                        setYear(item)
                        setMode('month')
                      }}
                    >
                      {item}
                      {
                        (thisYear === item)
                        &&
                        <div style={{
                          position: 'absolute',
                          bottom: 2,
                          width: 5,
                          height: 5,
                          borderRadius: 5 / 2,
                          backgroundColor: thisYear === item && activeYear !== item ? '#fff' : 'rgba(0,0,0,0.4)',
                        }} />
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Modal show={props.show} onHide={() => props.setShow(false)} animation={false} className="modal-datepicker">
        <div className={`background-theme-${theme}`}>
          <Modal.Header>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
              <span style={{ fontSize: 16 }}>Silahkan Pilih {mode == 'date' && 'Tanggal'} {mode == 'month' && 'Bulan'} {mode == 'year' && 'Tahun'}</span>
              {
                props.header &&
                <span style={{ fontSize: 14 }}>{props.header}</span>
              }
            </div>
          </Modal.Header>
          <Modal.Body>
            {
              mode == 'date'
              &&
              renderDate()
            }
            {
              mode == 'month'
              &&
              renderMonth()
            }
            {
              mode == 'year'
              &&
              renderYear()
            }
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'rgba(255,255,255, 0.5)' }}>
              <span style={{ fontSize: 14 }}>Pilih {mode == 'date' && 'tanggal'} {mode == 'month' && 'bulan'} {mode == 'year' && 'tahun'} untuk melanjutkan</span>
              <span style={{ fontSize: 12 }}>Atau tekan pada area kosong diatas untuk menutup</span>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}
