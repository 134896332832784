import status_detailing from '../assets/images/status_detailing.png'
import status_dibatalkan from '../assets/images/status_dibatalkan.png'
import status_packing from '../assets/images/status_packing.png'
import status_sorting from '../assets/images/status_sorting.png'
import status_spotting from '../assets/images/status_spotting.png'
import status_dicuci from '../assets/images/status_dicuci.png'
import status_dijemur from '../assets/images/status_dijemur.png'
import status_disetrika from '../assets/images/status_disetrika.png'
import status_menunggu from '../assets/images/status_menunggu.png'
import status_selesai from '../assets/images/status_selesai.png'
import status_diterima from '../assets/images/status_diterima.png'

const firebaseWebClientId = '784625064810-k69ptug01m5b4q0rcbffdtds4514v1gp.apps.googleusercontent.com'

const MyHelpers = {
  googleClientId: () => {
    return firebaseWebClientId
  },
  getFormattedDatetime: (datetime, dateDivider = ' ') => {
    let arrSplit = datetime.split(' ');
    let date = arrSplit[0];
    let time = arrSplit[1];
    let arrSplitDate = date.split('-');

    let month = '';
    switch (arrSplitDate[1]) {
      case '01': month = 'Januari'; break;
      case '02': month = 'Februari'; break;
      case '03': month = 'Maret'; break;
      case '04': month = 'April'; break;
      case '05': month = 'Mei'; break;
      case '06': month = 'Juni'; break;
      case '07': month = 'Juli'; break;
      case '08': month = 'Agustus'; break;
      case '09': month = 'September'; break;
      case '10': month = 'Oktober'; break;
      case '11': month = 'Nopember'; break;
      case '12': month = 'Desember'; break;
      default: break;
    }

    let tempDate = new Date(date);
    let days = '';
    let daysDivider = ', ';
    switch (tempDate.getDay()) {
      case 0: days = 'Minggu'; break;
      case 1: days = 'Senin'; break;
      case 2: days = 'Selasa'; break;
      case 3: days = 'Rabu'; break;
      case 4: days = 'Kamis'; break;
      case 5: days = 'Jumat'; break;
      case 6: days = 'Sabtu'; break;
      default: break;
    }

    let date1 = new Date(date);
    let date2 = new Date();
    let diffTime = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let newDate = `${days}${daysDivider}${arrSplitDate[2]}${dateDivider}${month}${dateDivider}${arrSplitDate[0]} ${time}`

    if (diffDays === 1) {
      return 'Hari ini, ' + time + ' WIB';
    } else {
      if (diffDays === 2) {
        return 'Kemarin, ' + time + ' WIB';
      } else {
        if (diffDays === 3) {
          return '2 hari yang lalu, ' + time + ' WIB';
        } else {
          return newDate;
        }
      }
    }
  },
  getFormattedDatetime2: (datetime, dateDivider = ' ') => {
    let arrSplit = datetime.split(' ');
    let date = arrSplit[0];
    let time = arrSplit[1];
    let arrSplitDate = date.split('-');

    let month = '';
    switch (arrSplitDate[1]) {
      case '01': month = 'Januari'; break;
      case '02': month = 'Februari'; break;
      case '03': month = 'Maret'; break;
      case '04': month = 'April'; break;
      case '05': month = 'Mei'; break;
      case '06': month = 'Juni'; break;
      case '07': month = 'Juli'; break;
      case '08': month = 'Agustus'; break;
      case '09': month = 'September'; break;
      case '10': month = 'Oktober'; break;
      case '11': month = 'Nopember'; break;
      case '12': month = 'Desember'; break;
      default: break;
    }

    let tempDate = new Date(date);
    let days = '';
    let daysDivider = ', ';
    switch (tempDate.getDay()) {
      case 0: days = 'Minggu'; break;
      case 1: days = 'Senin'; break;
      case 2: days = 'Selasa'; break;
      case 3: days = 'Rabu'; break;
      case 4: days = 'Kamis'; break;
      case 5: days = 'Jumat'; break;
      case 6: days = 'Sabtu'; break;
      default: break;
    }

    let date1 = new Date(date);
    let date2 = new Date();
    let diffTime = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let newDate = `${days}${daysDivider}${arrSplitDate[2]}${dateDivider}${month}${dateDivider}${arrSplitDate[0]}`

    if (diffDays === 1) {
      return 'Hari ini, ' + time + ' WIB';
    } else {
      if (diffDays === 2) {
        return 'Kemarin, ' + time + ' WIB';
      } else {
        if (diffDays === 3) {
          return '2 hari yang lalu, ' + time + ' WIB';
        } else {
          return newDate;
        }
      }
    }
  },
  getDateName: (date) => {
    // let arrSplitDate = date.split('-')
    // let month = '';
    // switch (arrSplitDate[1]) {
    //   case '01': month = 'Jan'; break;
    //   case '02': month = 'Feb'; break;
    //   case '03': month = 'Mar'; break;
    //   case '04': month = 'Apr'; break;
    //   case '05': month = 'Mei'; break;
    //   case '06': month = 'Jun'; break;
    //   case '07': month = 'Jul'; break;
    //   case '08': month = 'Agu'; break;
    //   case '09': month = 'Sep'; break;
    //   case '10': month = 'Okt'; break;
    //   case '11': month = 'Nop'; break;
    //   case '12': month = 'Des'; break;
    // }

    let tempDate = new Date(date);
    let days = '';
    switch (tempDate.getDay()) {
      case 0: days = 'Minggu'; break;
      case 1: days = 'Senin'; break;
      case 2: days = 'Selasa'; break;
      case 3: days = 'Rabu'; break;
      case 4: days = 'Kamis'; break;
      case 5: days = 'Jumat'; break;
      case 6: days = 'Sabtu'; break;
      default: break;
    }

    return days
  },
  getToday: () => {
    let date = new Date();
    let mYear = date.getFullYear()

    let mMonth = date.getMonth() + 1
    if (mMonth < 10) { mMonth = '0' + mMonth }

    let mDate = date.getDate()
    if (mDate < 10) { mDate = '0' + mDate }

    let thisDay = mYear + "-" + mMonth + "-" + mDate
    return thisDay
  },
  getMonthYearName: (prMonth = null, prYear = null) => {
    let date = new Date();
    let mYear
    let mMonth
    if (prMonth == null && prYear == null) {
      mYear = date.getFullYear()
      mMonth = date.getMonth() + 1
    } else {
      mYear = prYear
      mMonth = prMonth
    }

    let month = ""
    switch (mMonth) {
      case 1: month = 'Januari'; break;
      case 2: month = 'Februari'; break;
      case 3: month = 'Maret'; break;
      case 4: month = 'April'; break;
      case 5: month = 'Mei'; break;
      case 6: month = 'Juni'; break;
      case 7: month = 'July'; break;
      case 8: month = 'Agustus'; break;
      case 9: month = 'September'; break;
      case 10: month = 'Oktober'; break;
      case 11: month = 'Nopember'; break;
      case 12: month = 'Desember'; break;
      default: break;
    }

    let thisMonthYear = month + " " + mYear
    return thisMonthYear
  },
  formatDate: (pYear, pMonth, pDate) => {
    if (pDate < 10) {
      pDate = '0' + pDate;
    }

    if (pMonth < 10) {
      pMonth = '0' + pMonth;
    }

    let finalDate = pYear + '-' + pMonth + '-' + pDate
    return finalDate
  },
  formatShortDate: (date) => {
    let arrSplitDate = date.split('-')
    let month = '';
    switch (arrSplitDate[1]) {
      case '01': month = 'Jan'; break;
      case '02': month = 'Feb'; break;
      case '03': month = 'Mar'; break;
      case '04': month = 'Apr'; break;
      case '05': month = 'Mei'; break;
      case '06': month = 'Jun'; break;
      case '07': month = 'Jul'; break;
      case '08': month = 'Agu'; break;
      case '09': month = 'Sep'; break;
      case '10': month = 'Okt'; break;
      case '11': month = 'Nop'; break;
      case '12': month = 'Des'; break;
      default: break;
    }

    return arrSplitDate[2] + " " + month + " " + arrSplitDate[0]
  },
  getTime: (datetime) => {
    let arrSplitDate = datetime.split('-')
    let strSplitDate = arrSplitDate[2].toString()
    return strSplitDate.substring(3, 8)
  },
  getTimeFromNewDate: (params) => {
    let datetime = params.toString()
    let arrSplitDate = datetime.split(' ')
    return arrSplitDate[4]
  },
  getDate: (datetime) => {
    let arrSplitDate = datetime.split(' ')
    return arrSplitDate[0]
  },
  getInitial: (name) => {
    let splitName = name.split(" ")
    let initial = name.charAt(0);
    // let second = "";
    if (splitName[1] !== undefined) {
      initial += splitName[1].charAt(0)
    }

    // let result = first+second
    return initial.toUpperCase()
  },
  formatNumber: (amount, format = null) => {
    let result;
    if (amount !== null) {
      amount = Math.ceil(amount)
      result = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      result = amount;
    }
    let prefix;
    if (format === 'idr') {
      prefix = 'Rp';
    } else {
      prefix = '';
    }

    return prefix + result;
  },
  capitalize: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  strRegex: (string) => {
    const regex = /(<([^>]+)>)/ig
    var str = string
    var strReplace = str.replace(regex, '')
    var strReplace2 = strReplace.replace(/\u00a0/g, ' ')
    var strReplace3 = strReplace2.replace(/&amp;/g, ' & ')
    var strReplace4 = strReplace3.replace(/&nbsp;/g, ' ')
    return strReplace4
  },
  delBr: (string) => {
    var str = string
    var strReplace = str.replace(/<p><br[\/]?><[\/]?p>/g, '')
    return strReplace
  },
  getStateColor: (type) => {
    let opacity = 0.2
    let color = {
      background: `rgba(127, 140, 141, ${opacity})`,
      color: 'rgba(127, 140, 141, 1)'
    }

    if (type === "Belum Bayar") {
      color = {
        background: `rgba(243, 156, 18, ${opacity})`,
        color: 'rgba(243, 156, 18, 1)'
      }
    }
    if (type === "belum bayar") {
      color = {
        background: `rgba(243, 156, 18, ${opacity})`,
        color: 'rgba(243, 156, 18, 1)'
      }
    }
    if (type === "Dikemas") {
      color = {
        background: `rgba(52, 73, 94, ${opacity})`,
        color: 'rgba(52, 73, 94, 1)'
      }
    }
    if (type === "dikemas") {
      color = {
        background: `rgba(52, 73, 94, ${opacity})`,
        color: 'rgba(52, 73, 94, 1)'
      }
    }
    if (type === "Dikirim") {
      color = {
        background: `rgba(52, 152, 219, ${opacity})`,
        color: 'rgba(52, 152, 219, 1)'
      }
    }
    if (type === "dikirim") {
      color = {
        background: `rgba(52, 152, 219, ${opacity})`,
        color: 'rgba(52, 152, 219, 1)'
      }
    }
    if (type === "Selesai") {
      color = {
        background: `rgba(39, 174, 96, ${opacity})`,
        color: 'rgba(39, 174, 96, 1)'
      }
    }
    if (type === "selesai") {
      color = {
        background: `rgba(39, 174, 96, ${opacity})`,
        color: 'rgba(39, 174, 96, 1)'
      }
    }
    if (type === "Dibatalkan") {
      color = {
        background: `rgba(231, 76, 60, ${opacity})`,
        color: 'rgba(231, 76, 60, 1)'
      }
    }
    if (type === "dibatalkan") {
      color = {
        background: `rgba(231, 76, 60, ${opacity})`,
        color: 'rgba(231, 76, 60, 1)'
      }
    }
    if (type === "Pengembalian") {
      color = {
        background: `rgba(155, 89, 182, ${opacity})`,
        color: 'rgba(155, 89, 182, 1)'
      }
    }
    if (type === "pengembalian") {
      color = {
        background: `rgba(155, 89, 182, ${opacity})`,
        color: 'rgba(155, 89, 182, 1)'
      }
    }

    return color
  },
  isValidEmail: (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isGoogleEmail: (email) => {
    let split1 = email.split("@")
    let hostName = split1[1]
    if (hostName.toLowerCase() === 'gmail.com') {
      return true
    } else {
      return false
    }
  },
  getStatusColor: (status, title) => {
    let color = 'rgba(157, 157, 157, 1.0)'
    let background = 'rgba(157, 157, 157, 0.20)'
    if (status === 'Proses Pengerjaan') {
      if (title !== '') {
        if (title === 'Sorting') {
          color = 'rgba(52, 73, 94, 0.90)'
          background = 'rgba(52, 73, 94, 0.20)'
        }
        if (title === 'Spotting') {
          color = 'rgba(243, 156, 18, 0.90)'
          background = 'rgba(243, 156, 18, 0.20)'
        }
        if (title === 'Detailing') {
          color = 'rgba(52, 31, 151, 0.90)'
          background = 'rgba(52, 31, 151, 0.20)'
        }
        if (title === 'Cuci') {
          color = 'rgba(155, 89, 182, 0.90)'
          background = 'rgba(155, 89, 182, 0.20)'
        }
        if (title === 'Dikeringkan') {
          color = 'rgba(243, 104, 224, 0.90)'
          background = 'rgba(243, 104, 224, 0.20)'
        }
        if (title === 'Setrika') {
          color = 'rgba(10, 189, 227, 0.90)'
          background = 'rgba(10, 189, 227, 0.20)'
        }
        if (title === 'Packing') {
          color = 'rgba(46, 134, 222, 0.90)'
          background = 'rgba(46, 134, 222, 0.20)'
        }
      } else {
        color = 'rgba(157, 157, 157, 1.0)'
        background = 'rgba(157, 157, 157, 0.20)'
      }
    } else {
      if (status === 'Selesai') {
        color = 'rgba(39, 174, 96, 0.90)'
        background = 'rgba(39, 174, 96, 0.20)'
      }
      if (status === 'Diterima') {
        color = 'rgba(22, 160, 133, 0.90)'
        background = 'rgba(22, 160, 133, 0.20)'
      }
      if (status === 'Canceled') {
        color = 'rgba(231, 76, 60, 0.90)'
        background = 'rgba(231, 76, 60, 0.20)'
      }
    }
    return { "color": color, "background": background }
  },
  getStatusIcon: (objData) => {
    let icon = status_menunggu
    if (objData.status === 'Proses Pengerjaan') {
      if (objData.progress_status !== '') {
        if (objData.progress_status === 'Sorting') {
          icon = status_sorting
        }
        if (objData.progress_status === 'Spotting') {
          icon = status_spotting
        }
        if (objData.progress_status === 'Detailing') {
          icon = status_detailing
        }
        if (objData.progress_status === 'Cuci') {
          icon = status_dicuci
        }
        if (objData.progress_status === 'Dikeringkan') {
          icon = status_dijemur
        }
        if (objData.progress_status === 'Setrika') {
          icon = status_disetrika
        }
        if (objData.progress_status === 'Packing') {
          icon = status_packing
        }
      } else {
        icon = status_menunggu
      }
    } else {
      if (objData.status === 'Selesai') {
        icon = status_selesai
      }
      if (objData.status === 'Diterima') {
        icon = status_diterima
      }
      if (objData.status === 'Canceled') {
        icon = status_dibatalkan
      }
    }

    return icon
  },
  convertHMS: (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  },
  secondsToHms: (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "d" : "d") : "";
    return hDisplay + mDisplay + sDisplay;
  },
  timeToSecond: (time) => {
    let result = 0;
    let timeParts = time.split(":"); //Separate  mm ss
    let secs = Number(timeParts[0]) * 60 + Number(timeParts[1]);
    result = secs;
    return result;
  },
  getDateCountInMonth: (month, year) => {
    // Result will return number of day in a selected month and year
    return new Date(year, month, 0).getDate();
  },
  getDateRangeInMonth: (month, year) => {
    // Result will return start & end date in a selected month and year
    let dateCount = new Date(year, month, 0).getDate();
    let startDate = "";
    let endDate = "";

    for (let index = 1; index <= dateCount; index++) {
      let date = index > 9 ? index : `0${index}`;
      startDate = `${year}-${month}-01`;
      endDate = `${year}-${month}-${date.toString()}`;
    }
    return { startDate, endDate };
  },
  getStatisticModus: (array) => {
    if (array.length === 0) return "";
    var modeMap = {};
    var maxEl = array[0],
      maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] === undefined) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  },
  getDaysInMonth: (month, year) => { return new Date(year, month, 0).getDate() },
  getEndDateInMonth: (month, year) => {
    let dayInMonths = new Date(year, month, 0).getDate()
    let endDate = ""
    for (let index = 1; index <= dayInMonths; index++) {
      let date = index > 9 ? index : `0${index}`
      endDate = `${year}-${month}-${date.toString()}`
    }
    return endDate
  },
  splitName: (str, limit = 20) => {
    let arrName = str.split(' ')
    let result = [];
    let name = arrName[0]
    if (str.length > limit) {
      if (arrName.length > 1) {
        for (let i = 1; i < arrName.length; i++) {
          let name1 = name + ' ' + arrName[i]
          if (name1.length <= limit) {
            if (i === (arrName.length - 1)) {
              result.push(name1)
            } else {
              name = name1
            }
          } else {
            result.push(name)
            if (i === (arrName.length - 1)) {
              result.push(arrName[i])
            } else {
              name = arrName[i]
            }
          }
        }
      }
    } else {
      result.push(str)
    }
    return result
  },
  initialName: (value) => {
    if (!value) return ""
    let splitName = value.split(" ")
    let initial = value.charAt(0)
    if (splitName[1] !== undefined) {
      initial += splitName[1].charAt(0)
    }

    return initial.toUpperCase()
  },
  greating: () => {
    var today = new Date()
    var curHr = today.getHours()
    if (curHr < 12) {
      return 'Selamat pagi'
    } else if (curHr < 16) {
      return 'Selamat siang'
    } else if (curHr < 18) {
      return 'Selamat sore'
    } else {
      return 'Selamat malam'
    }
  },
}

export default MyHelpers;