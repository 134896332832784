import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import ApiHelpers from "../../helpers/ApiHelpers";
import gif_loading_default from "../../assets/animations/gif_loading_default.gif";
import { useTheme } from "./../../hooks/useTheme";
const width = 300;
const height = 300;
const WIDTH_ICON_CONTAINER = (width - 100) / 4;
const WIDTH_ICON = WIDTH_ICON_CONTAINER + 20;
const WIDTH_ICON_IMAGE = WIDTH_ICON_CONTAINER * 0.8;
const ICON_DEF =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png";
const URL_GET_OPTION = "actions/options";

const ContractionMyNoteInfo = forwardRef(({ colors }, ref) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [number, setNumber] = useState("");
  const [dataObject, setDataObject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { theme, backgroundImageTheme, changeTheme, changeBackground } =
    useTheme();

  useImperativeHandle(ref, () => ({
    onShowModal(state, dataObject, number) {
      onShowModal(state, dataObject, number);
    },
  }));

  const onShowModal = (state, dataObject, number) => {
    if (!state) {
      setIsLoading(false);
      setNumber("");
      setDataObject(null);
    } else {
      setNumber(number);
      setDataObject(dataObject);
    }

    setTimeout(() => {
      setIsShowModal(state);
    }, 1);
  };

  const renderContent = () => {
    let objData = dataObject;
    if (objData !== null) {
      // console.log(JSON.stringify(objData))
      return (
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            height: 'calc(100vh - 210px)',
            overflowY: 'auto',
            paddingBottom: 10,
          }}
        >
          <div>
            {/* === OBAT YANG DIKONSUMSI */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div
                style={{
                  ...styles.inputLabel,
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
                OBAT YANG DIKONSUMSI
              </div>
              <div
                style={{
                  fontSize: 15,
                  color:
                    objData.medicine_consumed !== ""
                      ? '#fff'
                      : "rgba(255,255,255,0.3)",
                  fontWeight:
                    objData.medicine_consumed !== "" ? "bold" : "normal",
                  marginTop: 10,
                }}
              >
                {objData.medicine_consumed !== ""
                  ? objData.medicine_consumed
                  : "Tidak ada / belum ditambahkan."}
              </div>
            </div>

            {/* === MOODS */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: 15 }}>
                MOODS
              </div>
              {objData.moods.length > 0 ? (
                <div style={{
                  display: 'flex'
                }}>
                  {objData.moods.map((item, index) => {
                    return renderIcons(item.icon, item.value, index);
                  })}
                </div>
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>

            {/* === TREATMENT */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: 15 }}>
                Treatment selama kontraksi
              </div>
              {objData.contraction_treatment.length > 0 ? (
                <div style={{
                  display: 'flex'
                }}>
                  {objData.contraction_treatment.map((item, index) => {
                    return renderIcons(item.icon, item.value, index);
                  })}
                </div>
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
              {objData.contraction_treatment_other !== "" && (
                <>
                  <div
                    style={{
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: "normal",
                      marginTop: 10,
                      marginBottom: 5,
                    }}
                  >
                    Treatment Lainnya
                  </div>
                  <div
                    style={{
                      fontSize: 15,
                      color: '#fff',
                      fontWeight: "bold",
                      marginTop: 2,
                    }}
                  >
                    {objData.contraction_treatment_other}
                  </div>
                </>
              )}
            </div>

            {/* === GERAKAN JANIN */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>
                GERAKAN JANIN
              </div>
              {objData.fetal_movement.value !== undefined &&
              objData.fetal_movement.value !== "" ? (
                <div horizontal={true} style={{ marginTop: 15 }}>
                  {renderIcons(
                    objData.fetal_movement.icon,
                    objData.fetal_movement.value,
                    0
                  )}
                </div>
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>

            {/* === TANDA ATAU GEJALA */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>
                TANDA ATAU GEJALA
              </div>
              {objData.signs_and_symptoms.length > 0 ? (
                renderArrayOfIcons(
                  objData.signs_and_symptoms,
                  "signs_and_symptoms"
                )
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>

            {/* === LIFESTYLE */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>
                LIFESTYLE
              </div>
              {objData.life_style.length > 0 ? (
                renderArrayOfIcons(objData.life_style, "life_style")
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>

            {/* === STATUS KETUBAN */}
            <div style={{ ...styles.inputContainer, marginTop: 20 }}>
              <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>
                STATUS KETUBAN
              </div>
              {objData.ruptured_membranes.length > 0 ? (
                renderArrayOfIcons(
                  objData.ruptured_membranes,
                  "ruptured_membranes"
                )
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>

            {/* === PEMERIKSAAN FISIK */}
            <div
              style={{
                ...styles.inputContainer,
                marginTop: 20,
                borderBottomWidth: 0,
                paddingBottom: 20,
              }}
            >
              <div style={{ ...styles.inputLabel, fontWeight: "bold" }}>
                PEMERIKSAAN FISIK
              </div>
              {objData.physical_condition.length > 0 ? (
                renderArrayOfIcons(
                  objData.physical_condition,
                  "physical_condition"
                )
              ) : (
                <div
                  style={{
                    fontSize: 15,
                    color: "rgba(255,255,255,0.3)",
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  Tidak ada / belum ditambahkan.
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          paddingBottom: 5,
          paddingTop: 5,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgb(255 255 255 / 27%)",
          borderRadius: '0px 0px 12px 12px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setIsShowModal(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 25,
            paddingRight: 25,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 20,
          }}
        >
          <i
            className="fas fa-times-circle mg-right10"
            style={{ color: "white" }}
            onClick={() => setIsShowModal(false)}
          />
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "white",
              marginLeft: 5,
            }}
          >
            Tutup
          </div>
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <img
        src={gif_loading_default}
        className="ComponentLoading-img"
        style={{ width: "24px", height: "24px" }}
      />
    );
  };

  const renderArrayOfIcons = (arrData, params) => {
    if (arrData.length > 0) {
      return arrData.map((item, key) => {
        return (
          <div key={key} style={{ marginTop: 20 }}>
            <div style={{ ...styles.inputLabel, textTransform: "capitalize", marginBottom: 10 }}>
              {item.value}
            </div>
            {item.type === "input" && renderdiv(item, params, key)}
            {item.type === "array" && (
              <div>{renderIconsArray(item.item_array)}</div>
            )}
          </div>
        );
      });
    }
  };
  const renderdiv = (item, params, index) => {
    let sufiks = "";
    if (params === "life_style") {
      if (item.value === "Lama Tidur") {
        sufiks = "menit/jam";
      }
      if (item.value === "Tensi") {
        sufiks = "mmHg";
      }
      if (item.value === "Nadi") {
        sufiks = "x/mnt";
      }
      if (item.value === "Suhu Tubuh") {
        sufiks = "C";
      }
    }
    if (params === "physical_condition") {
      if (item.value === "Pembukaan") {
        sufiks = "cm";
      }
    }
    return (
      <div key={index}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: -5,
          }}
        >
          {item.icon !== "" ? (
            <img
              src={item.icon}
              width="25"
              height="25"
              placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
            />
          ) : (
            <div
              style={{
                width: 18,
                height: 18,
                borderRadius: 18 / 2,
                backgroundColor: colors.backgroundLight,
                marginTop: 5,
              }}
            />
          )}
          <div
            style={{
              flex: 1,
              paddingLeft: 15,
              fontSize: 15,
              color:
                item.input !== ""
                  ? '#fff'
                  : "rgba(255,255,255,0.3)",
              fontWeight: item.input !== "" ? "bold" : "normal",
              marginTop: 10,
            }}
          >
            {item.input !== "" ? item.input : "-"}
          </div>
          {sufiks !== "" && (
            <>
              {sufiks === "C" ? (
                <div
                  style={{
                    fontWeight: "bold",
                    color: '#fff',
                    fontSize: 12,
                  }}
                >
                  &#176; {sufiks}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    color: '#fff',
                    fontSize: 12,
                  }}
                >
                  {sufiks}
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ ...styles.divider, width: width - 50 }} />
      </div>
    );
  };
  const renderIconsArray = (arrData) => {
    if (arrData.length > 0) {
      let ifFoundSelected = arrData.some(
        (el) => el.is_selected !== undefined && el.is_selected
      );
      if (ifFoundSelected) {
        return arrData.map((item, key) => {
          let is_selected = false;
          if (item.is_selected !== undefined) {
            is_selected = item.is_selected;
          }

          if (is_selected) {
            return renderIcons(item.icon, item.value, key, true);
          }
        });
      } else {
        return (
          <div
            style={{
              fontSize: 15,
              color: "rgba(255,255,255,0.3)",
              fontWeight: "normal",
            }}
          >
            Tidak ada / belum ditambahkan.
          </div>
        );
      }
    }
  };
  const renderIcons = (icons, title, index, isFromArray = false) => {
    if (isFromArray) {
      return (
        <div
          key={index}
          style={{
            width: WIDTH_ICON,
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: '#FFF',
              borderRadius: WIDTH_ICON_CONTAINER / 2,
              width: WIDTH_ICON_CONTAINER,
              height: WIDTH_ICON_CONTAINER,
              alignItems: "center",
              display: 'flex',
              justifyContent: "center",
              margin: 'auto'
            }}
          >
            <img
              src={icons}
              width={WIDTH_ICON_IMAGE}
              height={WIDTH_ICON_IMAGE}
              placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
            />
          </div>
          <div
            numberOfLines={3}
            style={{
              marginTop: 5,
              fontSize: 12,
              color: '#fff',
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {title}
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={index}
          style={{
            marginLeft: index > 0 ? 15 : 0,
            width: WIDTH_ICON,
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: '#FFF',
              borderRadius: WIDTH_ICON_CONTAINER / 2,
              width: WIDTH_ICON_CONTAINER,
              height: WIDTH_ICON_CONTAINER,
              alignItems: "center",
              display: 'flex',
              justifyContent: "center",
              margin: 'auto'
            }}
          >
            <img
              src={icons}
              width={WIDTH_ICON_IMAGE}
              height={WIDTH_ICON_IMAGE}
              placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
            />
          </div>
          <div
            numberOfLines={3}
            style={{
              marginTop: 5,
              fontSize: 12,
              color: '#fff',
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {title}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      onClick={() => {
        // closeModal()
      }}
    >
      <Modal animationType="fade" show={isShowModal} transparent centered>
        <div>
          <div
            className={`background-theme-${theme}`}
            style={{
              borderRadius: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.2)",
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 20,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {`Detail Catatan Kontraksi #${number}`}
              </div>
              <i
                className="fas fa-times-circle mg-right10"
                style={{ color: "white" }}
                onClick={() => setIsShowModal(false)}
              />
            </div>
            <div>{renderContent()}</div>
            <div>{renderFooter()}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const styles = {
  container: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.7)",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: width * 0.95,
    height: height * 0.9,
    borderRadius: 20,
    overflow: "hidden",
  },
  inputContainer: {
    paddingHorizontal: 15,
    borderBottomColor: "rgba(0,0,0,0.03)",
    borderBottomWidth: 10,
    paddingBottom: 15,
  },
  inputLabel: {
    color: "rgba(255,255,255,1.0)",
    fontSize: 14,
    textTransform: "uppercase",
  },
  inputdiv: {
    flex: 1,
    fontSize: 16,
    color: "#fff",
    marginTop: 0,
    marginLeft: -5,
  },
  inputNote: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
  },
  inputInfo: {
    flex: 1,
    fontSize: 16,
    color: "#fff",
    marginTop: 10,
  },
  inputRequired: { fontSize: 11, color: "#fab1a0" },
  inputOptional: { fontSize: 11, color: "#55efc4" },
  inputLengthInfo: { fontSize: 11, color: "#B5B5B5" },
  divider: {
    width: width - 30,
    height: 1,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    marginTop: 0,
  },
};

export default ContractionMyNoteInfo;
