import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { userAuth } from "../store"

function Guest(props) {
    const history = useHistory()
    const auth = userAuth()

    useEffect(() => {
        if(auth !== null && auth.id !== undefined && auth.id !== "") {
            history.push('/dashboard')
        } else {
            // history.push('/login')
        }
    }, [])

    return props.render
}

export default Guest