import React, { useState, useRef, useCallback, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import Header from "../../components/Header"
import { userAuth } from "../../store";
import LogoutPopup from "./LogoutPopup"
import UpdateAccount from "./UpdateAccount"
import ApiHelpers from "../../helpers/ApiHelpers"
import moment from 'moment'
import MyHelpers from "../../helpers/MyHelpers"
import Loading from "../../components/Loading"
import { useTheme } from './../../hooks/useTheme';
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/

export default function Account() {

  const { theme, backgroundImageTheme, changeTheme, changeBackground } = useTheme()

  const refLogoutPopup = useRef(null);
  const refUpdateAccount = useRef(null)
  const ref_inputImage = useRef(null)
  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [subscribeInfo, setSubscribeInfo] = useState(null)
  const [auth, setAuth] = useState(userAuth());
  const history = useHistory()
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [isLoading, setisLoading] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [errType, setErrType] = useState('warning')
  const [showError, setShowError] = useState(false)


  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
    // setAuth(dataAuth)
  }
  const getData = async () => {
    let session = auth
    let objData = null
    let response = await ApiHelpers.get("", 'account/profile')
    if (response.status === 200) {
      objData = response.results.data
      // await Storage.setObject("user_session", response.results.data)
      localStorage.setItem('user_session', JSON.stringify(objData))
    } else {
      objData = session
    }
    setFetchData(objData)
    getSubscribe()
  }
  const getSubscribe = async () => {
    setSubscribeInfo(null)
    let subscribeInfo = null
    let response = await ApiHelpers.get("", 'account/subscribe/status', {})
    if (response.status === 200 && response.results.data.subscribe_bool !== undefined) {
      subscribeInfo = response.results.data
    }
    setSubscribeInfo(subscribeInfo)
    seIsFetched(false)
    setIsRefreshing(false)
  }
  const subscribeInfomation = (objData) => {
    let status = "-"
    let type = ""
    if (objData.subscribe_bool) {
      status = "Aktif"

      if (objData.type === "Month") {
        type = `(${objData.value} bln)`
      }
      if (objData.type === "Year") {
        type = `(${objData.value} thn)`
      }
      if (objData.type === "Year") {
        type = `(${objData.value} hari)`
      }
    } else {
      if (objData.end_datetime !== "") {
        status = "Berakhir"
      }
    }

    return `${status} ${type}`
  }

  const UpdateData = (data) => {
    setAuth(data)
    onRefresh()
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    ref_inputImage.current.click();
  };

  const onImageChangeHandler = async (event) => {
    if (event.target.files && event.target.files[0]){
        let formData = new FormData()
        formData.append('image', event.target.files[0])

        setisLoading(true)
        setLoadingMessage('Upload gambar...')
        let response = await ApiHelpers.uploadImage(formData)
        console.log(response)
        if(response.status == 200){
          setLoadingMessage('Menyimpan perubahan data...')

            let responseApi = await ApiHelpers.post("", "account/profile/update_image", {
              "image_path": response.data.filepath,
              "image": response.data.filename,
            })
    
            // setIsLoadingMessage("Mengecek akun login Anda...")
            if (responseApi.status === 200) {
              const newData = {
                ...auth, 
                image_url: response.data.fileuri,
                "image_path": response.data.filepath,
                "image": response.data.filename,
              }
              console.log('data', newData)
              return new Promise((resolve) => {
                  let result = false
                  localStorage.setItem('user_session', JSON.stringify(newData))
                  let auth = JSON.parse(localStorage.getItem('user_session'))
                  console.log(auth)
                  setisLoading(false)
                  setAuth(newData)
                  onRefresh()
                  // return resolve(result)
              })
            } else {
                setErrMessage(response.message)
                setErrType("error")
                setShowError(true)
            }
        }
    }
}

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isLoading === true) {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
    
    if (isFetched == false && auth != null) {
      return (
        <div style={{ textAlign: 'center' }} >
          <div>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 180, borderRadius: '50%', display: 'flex', height: 180, resizeMode: 'cover', backgroundColor: "rgba(255,255,255,0.7)", alignItems: 'center', justifyContent: 'center' }}>
            <input type='file' id='image' onChange={(event) => onImageChangeHandler(event)} ref={ref_inputImage} accept="image/jpg, image/png, image/jpeg" style={{ display: 'none' }} />
              <div style={{
                width: 160, borderRadius: '50%', display: 'flex',
                height: 160, resizeMode: 'cover', backgroundColor: "rgba(0,0,0,0.5)", alignItems: 'center', justifyContent: 'center', position: 'relative'
              }}
              onClick={() => { onButtonClick() }}>
                {auth.image_url !== "" ?
                  <img
                    className="image"
                    src={auth.image_url}
                    style={{
                      width: 160, borderRadius: '50%', display: 'flex',
                      height: 160, resizeMode: 'cover', objectFit: 'cover'
                    }}
                  />
                  :
                  <div style={{ fontWeight: 'bold', color: "rgba(255,255,255,0.5)", fontSize: 160 * 0.5 }}>{MyHelpers.getInitial(auth.name)}</div>
                }
                <div style={{
                  width: 160, borderRadius: '50%', display: 'flex',
                  height: 160, resizeMode: 'cover', backgroundColor: "rgba(0,0,0,0.5)", alignItems: 'center', justifyContent: 'center', top: 0, left: 0, position: 'absolute'
                }}>
                  <i className="fas fa-camera" style={{ color: "#fff", fontSize: 41 }}></i>
                </div>
              </div>
            </div>
          </div>
          <div style={{ color: "#fff", fontSize: 20, marginTop: 20, fontWeight: "bold" }}>
            {auth.name}
          </div>
          <div style={{ color: "#fff", fontSize: 14, marginTop: 5 }}>
            {auth.email}
          </div>

          <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', marginTop: 35, marginBottom: 30 }}>
            <div style={{ flex: 0.5, alignItems: 'center' }}>
              <div style={{ color: "#fff", fontSize: 9, marginBottom: 2 }}>MEMBERSHIP</div>
              <div style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>{auth.is_verified === "verified" ? "Premium" : "Reguler"}</div>
            </div>
            <div style={{ flex: 0.5, alignItems: 'center' }}>
              <div style={{ color: "#fff", fontSize: 9, marginBottom: 2 }}>LANGGANAN</div>
              <div style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>{subscribeInfomation(subscribeInfo)}</div>
            </div>
          </div>

          <div style={{
              flexDirection: "row", display: 'flex', alignItems: 'center',
              paddingLeft: 20, paddingRight: 20,
              borderBottom: ".5px solid rgba(0,0,0,0.1)",
              paddingTop: 20, paddingBottom: 20
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fas fa-badge-check" style={{ color: '#fff', width: 18 }}></i>
              <div style={{ marginLeft: 15, textAlign: 'left' }}>
                <div style={{ color: "#fff", fontSize: 16 }}>Subscriptions</div>
                {subscribeInfo.subscribe_bool &&
                  <div style={{ color: "#fdcb6e", fontSize: 12 }}>s.d. {moment(subscribeInfo.end_datetime).format("lll")}</div>
                }
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', justifyContent: 'end' }}>
              <div numberOfLines={1} 
                onClick={() => { history.push('/subscriptions-package') }}
                style={{
                  color: "#fff", fontSize: 14,
                  textDecorationLine: "underline",
                  cursor: 'pointer'
                }}
                className="ripple">
                Paket
              </div>
              <div style={{ marginLeft: 15 }} onClick={() => { history.push('/account-subscriptions') }}>
                <div numberOfLines={1} style={{
                    color: "#fff", fontSize: 14,
                    textDecorationLine: "underline",
                    cursor: 'pointer'
                  }}
                  className="ripple">
                  Riwayat
                </div>
              </div>
            </div>
          </div>

          <div style={{
              flexDirection: "row", display: 'flex', alignItems: 'center',
              paddingLeft: 20, paddingRight: 20,
              borderBottom: ".5px solid rgba(0,0,0,0.1)",
              paddingTop: 20, paddingBottom: 20,
              cursor: 'pointer'
            }}
            className="ripple"
            onClick={() => { history.push('/subscriptions-history') }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="far fa-ballot-check" style={{ color: '#fff', width: 18 }}></i>
              <div style={{ marginLeft: 15 }}>
                <div style={{ color: "#fff", fontSize: 16 }}>Riwayat Transaksi</div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <i className="fal fa-chevron-right" style={{ color: '#fff', width: 18 }}></i>
            </div>
          </div>

          <div style={{
              flexDirection: "row", display: 'flex', alignItems: 'center',
              paddingLeft: 20, paddingRight: 20,
              borderBottom: ".5px solid rgba(0,0,0,0.1)",
              paddingTop: 20, paddingBottom: 20,
              cursor: 'pointer'
            }}
            className="ripple"
            onClick={() => { refUpdateAccount.current(auth) }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fas fa-user-edit" style={{ color: '#fff', width: 18 }}></i>
              <div style={{ marginLeft: 15 }}>
                <div style={{ color: "#fff", fontSize: 16 }}>Update profile</div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <i className="fal fa-chevron-right" style={{ color: '#fff', width: 18 }}></i>
            </div>
          </div>

          <div style={{
              flexDirection: "row", display: 'flex', alignItems: 'center',
              paddingLeft: 20, paddingRight: 20,
              borderBottom: ".5px solid rgba(0,0,0,0.1)",
              paddingTop: 20, paddingBottom: 20,
              cursor: 'pointer'
            }}
            className="ripple"
            onClick={() => { history.push('/account-number-phone') }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fas fa-mobile-android-alt" style={{ color: '#fff', width: 18 }}></i>
              <div style={{ marginLeft: 15 }}>
                <div style={{ color: "#fff", fontSize: 16 }}>List Nomor Penting</div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <i className="fal fa-chevron-right" style={{ color: '#fff', width: 18 }}></i>
            </div>
          </div>

          <div style={{
              flexDirection: "row", display: 'flex', alignItems: 'center',
              paddingLeft: 20, paddingRight: 20,
              borderBottom: ".5px solid rgba(0,0,0,0.1)",
              paddingTop: 20, paddingBottom: 20,
              cursor: 'pointer'
            }}
            className="ripple"
            onClick={() => { refLogoutPopup.current() }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fal fa-power-off" style={{ color: '#fff', width: 18 }}></i>
              <div style={{ marginLeft: 15 }}>
                <div style={{ color: "#fff", fontSize: 16 }}>Sign out</div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <i className="fal fa-chevron-right" style={{ color: '#fff', width: 18 }}></i>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }
  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Akun Saya"}
        // navigation={this.props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
        showThemeConfig={true}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <LogoutPopup refLogoutPopup={refLogoutPopup} />
      <UpdateAccount refUpdateAccount={refUpdateAccount} onUpdateData={UpdateData} />
      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto',
        bottom: 0
      }}
        open={showError}
        autoHideDuration={3000}
        onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }}>
        <Alert onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>
    </div>
  )
  // }
}