import React, { useState, useRef, useCallback, useEffect } from "react"
import SubscriptionsInfo from "./SubscriptionsInfo"
import ApiHelpers from "../../helpers/ApiHelpers"
import img_subscribe_type from "../../assets/BK/img_subscribe_type.png"
import Header from "../../components/Header"
import MyHelpers from "../../helpers/MyHelpers"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"
import Loading from "../../components/Loading"
import { useTheme } from './../../hooks/useTheme';

export default function SubscriptionsPackage() {

  const { theme, backgroundImageTheme, changeTheme, changeBackground } = useTheme()

  const refSubscriptionsInfo = useRef(null)
  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [isShowModal, setIsShowModal] = useState(false)
  const [dataSelected, setDataSelected] = useState(null)
  const history = useHistory()



  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'packages')
    if (response.status === 200) {
      objData = response.results.data
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  const handleOpenModal = (data) => {
    setDataSelected(data)
    setIsShowModal(true)
  }

  const onBuyPackageListeners = () => {
    seIsFetched(true)
    setLoadingMessage('Loading...')
    setTimeout(() => {
      // console.log(dataSelected)
      localStorage.setItem('subscriptions_package', JSON.stringify(dataSelected))
      history.push('/checkout')
      // this.props.navigation.navigate('SubscriptionsCheckout', {data:params})
      // this.setState({selectedData:null})
      seIsFetched(false)
      setIsShowModal(false)
    }, 300);
  }

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      if (fetchData.length > 0) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>
              {fetchData.map((item) => {
                return (
                  <div style={{ marginBottom: 15 }}>
                    <div
                      style={{ paddingLeft: 15, paddingRight: 15 }}>
                      <div style={{
                        borderRadius: 10,
                        // backgroundColor: '#9a2c90', 
                        padding: '20px 15px'
                      }} className={`background-dark-theme-${theme}`}>
                        <div style={{ display: 'flex' }}>
                          <img
                            src={img_subscribe_type} style={{ width: 50, height: 50, resizeMode: 'contain' }}
                            placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                          />
                          <div style={{ marginLeft: 15 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 16, color: '#fff' }}>
                              {item.name}
                            </div>
                            <div numberOfLines={1} style={{ fontSize: 14, marginTop: 5, color: 'rgba(255,255,255,0.7)' }}>
                              {item.desc}
                            </div>
                            <div style={{ marginTop: 10, flexDirection: 'row', alignItems: 'center', display: "flex" }}>
                              <div numberOfLines={1} style={{
                                fontSize: !item.discount_bool ? 15 : 14,
                                marginRight: !item.discount_bool ? 0 : 10,
                                color: !item.discount_bool ? "#55efc4" : "#ff7675",
                                textDecorationLine: !item.discount_bool ? "none" : "line-through",
                                fontWeight: 'bold'
                              }}>
                                {MyHelpers.formatNumber(item.price, 'idr')}
                              </div>
                              {item.discount_bool &&
                                <div numberOfLines={1} style={{ fontSize: 15, color: '#55efc4', fontWeight: 'bold', display: "flex" }}>
                                  {MyHelpers.formatNumber(Number(item.price - item.discount_value), 'idr')} <div style={{ fontSize: 12, marginLeft: 4 }}>(-{MyHelpers.formatNumber(item.discount_percent, '')}%)</div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{
                          marginTop: 15,
                          textAlign: 'right'
                        }}>
                          <span
                            onClick={() => { refSubscriptionsInfo.current(item) }}
                            style={{ color: 'rgba(255,255,255,0.5)', fontSize: 16, fontWeight: 'bold', marginRight: 5, cursor: 'pointer', padding: 10 }}
                            className="ripple">
                            INFO
                          </span>
                          <span
                            onClick={() => { handleOpenModal(item) }}
                            style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold', marginLeft: 5, cursor: 'pointer', padding: 10 }}
                            className="ripple">
                            BELI
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: "center", minHeight: "calc(100vh - 60px)", display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={img_subscribe_type} style={{ width: 120, height: 120, resizeMode: 'contain' }}
                placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
              />
              <div numberOfLines={1} style={{ fontSize: 16, color: "#fff", fontWeight: "bold", marginTop: 30 }}>Paket Tidak Tersedia</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 13, color: "#fff" }}>Paket langganan tidak ditemukan atau,</div>
              <div numberOfLines={1} style={{ fontSize: 12, marginTop: 3, color: "#fff" }}>paket yang Anda cari tidak tersedia.</div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }
  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Pilih Paket Langganan"}
        // navigation={this.props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        <Modal.Header style={{
          width: '100%',
          height: 50,
          flexDirection: 'row',
          // backgroundColor: "#950082",
          borderRadius: '15px 15px 0px 0px',
          alignItems: 'center', justifyContent: 'center',
          paddingHorizontal: 20,
        }} className={`background-light-theme-${theme}`}>
          {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
          <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Beli Paket Langganan?</div>
        </Modal.Header>
        <Modal.Body style={{
          // backgroundColor: "#D400B8"
        }} className={`background-theme-${theme}`}>
          <div style={{ textAlign: "left", padding: "15px 0px" }}>
            <div style={{ color: "white", fontSize: 14, textAlign: 'center' }}>
              Apakah Anda yakin akan membeli paket
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{
          height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row', 
          // backgroundColor: "#D400B8",
          borderRadius: '0px 0px 15px 15px',
        }} className={`background-light-theme-${theme}`}>
          <div style={{
            borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
            alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
          }}
            onClick={() => { setIsShowModal(false) }}>
            <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
          </div>
          <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
            <div style={{
              borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
              alignItems: 'center', justifyContent: 'center',
              flexDirection: 'row',
              display: 'flex',
              cursor: 'pointer'
            }}
              onClick={onBuyPackageListeners}>
              <i className="fas fa-check icon ripple" style={{ color: '#fff', fontSize: 14 }}></i>
              <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Ya, Beli Paket</div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <SubscriptionsInfo refSubscriptionsInfo={refSubscriptionsInfo} />
    </div>
  )
  // }
}