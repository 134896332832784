import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { RecoilRoot } from 'recoil'

import { ThemeProvider } from './context/ThemeContext'

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<Suspense fallback={<div>Loading...</div>}>
				<ThemeProvider>
					<App />
				</ThemeProvider>
			</Suspense>
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById('root')
)

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
	// We execute the same script as before
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
});


//check default theme in localStorage
window.addEventListener('load', () => {
	let sessionTheme = localStorage.getItem('ake_theme')
	let sessionBackgroundTheme = localStorage.getItem('ake_background_theme')
	if (!sessionTheme) {
		localStorage.setItem('ake_theme', 'default')
	}
	if (!sessionBackgroundTheme) {
		localStorage.setItem('ake_background_theme', 'null')
	}
})

// console.log = console.warn = console.error = () => {}
console.warn = console.error = () => { }