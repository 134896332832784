import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react"
import Modal from "react-bootstrap/Modal"

import './Style.css'
import Skeleton from '@mui/material/Skeleton';

const ARR_THEME = [
  {
    name: "default",
    label: "Default",
    color: "#D400B8"
  },
  {
    name: "dark",
    label: "Dark",
    color: "#333333"
  },
  {
    name: "amethyst",
    label: "Amethyst",
    color: "#9b59b6"
  },
  {
    name: "green_sea",
    label: "Green Sea",
    color: "#10ac84"
  },
  {
    name: "belize_hole",
    label: "Belize Hole",
    color: "#3498db"
  },
  {
    name: "amour",
    label: "Amour",
    color: "#ee5253"
  },
]

const ModalTheme = ((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      showModal
    }),
  )

  useEffect(() => {
    if (show) {
    }
  }, [show])


  const showModal = (value) => {
    setShow(value)
  }

  const renderThemeItem = () => {
    return (
      <>
        {
          ARR_THEME.map((item, key) => {
            return (
              <div className="theme-item ripple" style={{ background: item.color }} key={key} onClick={() => {
                props.onSelectedTheme && props.onSelectedTheme(item)
                setShow(false)
              }}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#fff', width: '100%' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ fontSize: 16 }} className="text-truncate">{item.label}</div>
                  </div>
                  <div style={{ width: '40%', textAlign: 'right' }}>
                    {
                      item.name === props.selectedTheme
                      &&
                      <span
                        style={{ padding: '6px 10px', fontSize: 11, background: '#fff', color: item.color, borderRadius: 20, width: 40, textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', marginLeft: 20 }}
                        className="ripple"
                      >
                        <i class="fa fa-check-circle" style={{ fontSize: 12, marginRight: 5, color: item.color }}></i>
                        Aktif
                      </span>
                    }
                    <i class="fa fa-angle-right" style={{ fontSize: 12, marginLeft: 20 }}></i>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={true}
        className="modal-theme"
        centered
      >
        <Modal.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
            <div style={{ fontWeight: 'bold', color: '#333' }}>Pilih Tema</div>
            <i class="fa fa-times-circle ripple" style={{ color: '#333', fontSize: 20, cursor: 'pointer' }} onClick={() => setShow(false)}></i>
          </div>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'auto', maxHeight: 480 }}>
          {renderThemeItem()}
        </Modal.Body>
      </Modal>
    </>
  )
})

export default forwardRef(ModalTheme);