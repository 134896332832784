import React from "react"
import gif_loading_default from "../assets/animations/gif_loading_default.gif"
import { useTheme } from "../hooks/useTheme"

const Loading = (props) => {
	const { theme } = useTheme()

	return (
		<div className={`ComponentLoading`}>
			<div className={`ComponentLoading-container background-theme-${theme}`}>
				<img src={gif_loading_default} className="ComponentLoading-img" alt="bidan kita" />
				{props.title}
			</div>
		</div>
	)
}

export default Loading