import { useTheme } from '../hooks/useTheme'
import React, { useState, useRef, useCallback, useEffect } from "react"

import Navbar from "../components/navbar/Navbar"
import MyHelpers from "../helpers/MyHelpers"
import ApiHelpers from "../helpers/ApiHelpers"
import img_empty_data from "../assets/images/img_empty_data.png"
import img_pregnancy from '../assets/images/img_pregnancy_fetus.png'
import icon_emoticon_cool from '../assets/BK/icon_emoticon_excited.png'
import icon_emoticon_excited from '../assets/BK/icon_emoticon_excited.png'
import icon_emoticon_happy from '../assets/BK/icon_emoticon_excited.png'
import icon_emoticon_sad from '../assets/BK/icon_emoticon_excited.png'
import icon_emoticon_angry from '../assets/BK/icon_emoticon_excited.png'
import { userAuth } from "../store"
import { border, borders } from "@mui/system"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Loading from "../components/Loading"
import moment from "moment"

import DatePicker from "../components/datepicker/DatePicker"

import { useHistory } from "react-router-dom"
import ModalTheme from "./_modals/ModalTheme/ModalTheme"
import ModalFeedback from './_modals/ModalFeedback/ModalFeedback'


const ARR_MOOD = [
	{ id: "1", title: "Luar Biasa", value: "luar biasa", default_icon: "emoticon-cool-outline", selected_icon: icon_emoticon_cool, is_selected: false },
	{ id: "2", title: "Gembira", value: "gembira", default_icon: "emoticon-excited-outline", selected_icon: icon_emoticon_excited, is_selected: false },
	{ id: "3", title: "Okay", value: "okay", default_icon: "emoticon-happy-outline", selected_icon: icon_emoticon_happy, is_selected: false },
	{ id: "4", title: "Sedih", value: "sedih", default_icon: "emoticon-sad-outline", selected_icon: icon_emoticon_sad, is_selected: false },
	{ id: "5", title: "Marah", value: "marah", default_icon: "emoticon-angry-outline", selected_icon: icon_emoticon_angry, is_selected: false },
]

const Dashboard = () => {
	const history = useHistory()

	const { theme, backgroundImageTheme, changeTheme } = useTheme()

	const refModalTheme = useRef(null);
	const refModalFeedback = useRef(null);

	const [showError, setShowError] = useState(false)
	const [errMessage, setErrMessage] = useState('')
	const [errType, setErrType] = useState('warning')

	const [session, setSession] = useState(userAuth())
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState("Loading...")

	const [selectedTheme, setSelectedTheme] = useState('default')

	const [contractions, setContraction] = useState([]);

	const [showImportantContact, setShowImportantContact] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState(false)

	const [subscribe, setSubscribe] = useState({});

	const [pregnant, setPregnant] = useState({});

	const [hphtDate, setHphtDate] = useState('');


	useEffect(() => {
		let themeName = localStorage.getItem('ake_theme')

		if (themeName) {
			setSelectedTheme(themeName)
		}

		getDashboard()
	}, []);

	const getDashboard = async () => {
		setIsLoading(true)
		setLoadingMessage("Memuat data...")

		let response = await ApiHelpers.get("", "report/dashboard")

		if (response.status == 200) {
			getImportantContact()
			setContraction(response.results.data)
		} else {
			setIsLoading(false)
			setErrMessage(response.message)
			setShowError(true)
		}
	}

	const getImportantContact = async () => {

		let response = await ApiHelpers.get("", "account/important_contact")

		if (response.status == 200) {
			getSubscribeStatus()

			if (response.results.pagination.total_data <= 0) {
				setShowImportantContact(true)
			}
		} else {
			setIsLoading(false)
			setErrMessage(response.message)
			setShowError(true)
		}
	}

	const getSubscribeStatus = async () => {
		let response = await ApiHelpers.get("", 'account/subscribe/status')

		if (response.status == 200) {
			setSubscribe(response.results.data)
			getPregnantAge();
		} else {
			setIsLoading(false)
			setErrMessage(response.message)
			setShowError(true)
		}
	}

	const getPregnantAge = async () => {
		let response = await ApiHelpers.get("", 'account/pregnancy/detail')

		if (response.status == 200) {
			setIsLoading(false)
			setPregnant(response.results.data)
			setHphtDate(response.results.data.hpht_date)
		} else {
			setIsLoading(false)
			setErrMessage(response.message)
			setShowError(true)
		}
	}

	const isToday = (momentDate) => {
		let REFERENCE = moment()
		let TODAY = REFERENCE.clone().startOf('day')
		return momentDate.isSame(TODAY, 'd')
	}
	const isYesterday = (momentDate) => {
		let REFERENCE = moment()
		var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
		return momentDate.isSame(YESTERDAY, 'd');
	}
	const isTommorow = (momentDate) => {
		let REFERENCE = moment()
		var TOMMOROW = REFERENCE.clone().add(1, 'days').startOf('day');
		return momentDate.isSame(TOMMOROW, 'd');
	}

	const renderPregnancy = (item, key) => {
		let selectedKey = (Number(pregnant.pregnant_week) % 5) - 1;
		let number = (Math.floor(Number(pregnant.pregnant_week) / 5) * 5) + 1;

		if (selectedKey < 0) {
			selectedKey = 4;
		}

		if (Number(pregnant.pregnant_week) < number) {
			number = number - 5
		}

		if (Number(pregnant.pregnant_week) <= 5) {
			number = 0 + 1;
		}

		if (Number(pregnant.pregnant_week) <= 0) {
			number = 0;
			selectedKey = 0;
		}
		return (
			<div
				style={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					flexDirection: 'column',
				}}
				key={key}
			>
				<div style={{
					borderRadius: 60 / 2,
					width: 60,
					height: 60,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					// background: "linear-gradient(#950082, #FA37E1)",
					borderWidth: 2,
					borderStyle: key == selectedKey ? 'solid' : null,
					borderColor: '#fff'
				}}
					className={`gradient-theme-${theme}`}
				>
					{
						key == selectedKey ?
							<img src={img_pregnancy} style={{ width: '70%' }} />
							:
							<span style={{ color: 'rgba(255,255,255,0.3)', fontWeight: 'bold', fontSize: 18 }}>{number + key}</span>
					}

				</div>
				<div
					style={{
						marginTop: 5,
						textAlign: 'center',
						fontSize: 10,
						color: key == selectedKey ? '#fff' : 'rgba(255,255,255,0.3)',
						fontWeight: "bold"
					}}
				>
					{
						key === selectedKey ?
							pregnant.pregnant_age
							:
							'Minggu'
					}
				</div>
				<div style={{
					marginTop: 5,
					width: 5, height: 5, borderRadius: 5 / 2,
					backgroundColor: key == selectedKey ? '#fff' : 'rgba(0,0,0,0.2)'
				}} />
			</div>
		);
	}

	return (
		<div style={{
			flex: 1,
			// backgroundColor: '#D400B8',
			position: 'relative',
			"--background-themes": `url(${backgroundImageTheme})`,
			height: '100vh',
			height: 'calc(var(--vh, 1vh) * 100)',
			display: 'flex'
		}}
			className={`bg-themes background-theme-${theme}`}
		>

			<div style={{ position: 'relative', display: 'flex', flexDirection: 'column', flex: 1, maxWidth: '100%' }}>
				<div style={{
					paddingLeft: 20,
					paddingRight: 20,
					display: 'flex',
					justifyContent: 'space-between',
					paddingTop: 20,
					marginBottom: 20,
				}}>
					<div style={{ fontWeight: 'bold', color: '#fff' }}>Beranda</div>
					<div>
						<i className="fas fa-user-circle ripple" style={{ color: '#fff', fontSize: 22, cursor: 'pointer' }} onClick={() => { history.push('/account') }}></i>
						<i className="fas fa-paint-brush ripple" style={{ color: '#fff', fontSize: 22, cursor: 'pointer', marginLeft: 20 }} onClick={() => refModalTheme.current.showModal(true)}></i>
					</div>
				</div>

				<div style={{
					height: 'calc(100vh - 196px)',
					height: 'calc(calc(var(--vh, 1vh) * 100) - 66px)',
					overflowY: 'auto',
					paddingBottom: (Object.keys(subscribe).length > 0 && !subscribe.subscribe_bool) ? 0 : 125
				}}>

					<div style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						paddingLeft: 20,
						paddingRight: 20,
					}}>
						<div style={{
							minWidth: '120px', borderRadius: '50%', display: 'flex',
							height: '120px', resizeMode: 'cover', alignItems: 'center', justifyContent: 'end'
						}} className={`background-dark-theme-${theme}`}>
							<div style={{
								width: '100px', borderRadius: '50%', display: 'flex',
								height: '100px', resizeMode: 'cover', backgroundColor: "rgba(0,0,0,0.1)", alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
							}} className={`ripple`} onClick={() => history.push('/account')}>
								{
									Object.keys(session).length > 0 && session.image_url
									?
									<img
                    className="image"
                    src={session.image_url}
                    style={{
                      width: 100, borderRadius: '50%', display: 'flex',
                      height: 100, resizeMode: 'cover', objectFit: 'cover'
                    }}
                  />
									:
									<div style={{ fontWeight: 'bold', color: "rgba(255,255,255,0.5)", fontSize: 26 }}>{MyHelpers.initialName(session.name)}</div>
								}
							</div>
						</div>
						<div style={{ flex: 1, paddingLeft: 20, width: 'calc(100% - 120px)' }}>
							<div style={{ fontSize: 16, color: 'rgba(255,255,255,0.7)' }}>
								{MyHelpers.greating()}
							</div>
							<div style={{ fontSize: 20, color: 'rgba(255,255,255,1.0)', fontWeight: 'bold' }} className="text-truncate">
								{session.name}
							</div>
							<div style={{ fontSize: 12, color: 'rgba(255,255,255,1.0)' }} className="text-truncate">
								{session.email}
							</div>
						</div>
					</div>

					<div style={{ overflow: "hidden", display: 'flex', flexDirection: 'column' }} >
						<div style={{ flex: 1, paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
							{
								(Object.keys(pregnant).length > 0 && pregnant.hpht_date != '')
									?
									<div style={{ fontSize: 14, display: 'flex', justifyContent: 'space-between' }}>
										<div style={{ fontWeight: 'bold', color: 'rgba(255,255,255, 0.8)' }}>Usia Kehamilan Anda</div>
										<div style={{ color: 'rgba(255,255,255, 0.8)', textDecoration: 'underline', cursor: 'pointer' }}
											className="ripple"
											onClick={() => {
												if (Object.keys(subscribe).length > 0 && !subscribe.subscribe_bool) {
													history.push('/subscriptions-package')
												} else {
													setShowDatePicker(true)
												}
											}}>Ubah</div>
									</div>
									:
									<div style={{
										marginTop: 20,
										marginHorizontal: 20,
										// backgroundColor: 'rgba(0,0,0,0.2)',
										paddingHorizontal: 15,
										paddingVertical: 12,
										borderRadius: 7.5,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										cursor: 'pointer'
									}}
										className={`ripple background-dark-theme-${theme}`}
										onClick={() => {
											if (Object.keys(subscribe).length > 0 && !subscribe.subscribe_bool) {
												history.push('/subscriptions-package')
											} else {
												setShowDatePicker(true)
											}
										}}
									>
										<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
											<i className="fa fa-calendar-week" style={{ fontSize: 14, color: '#fff' }}></i>
											<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
												Kapan hari pertama haid terakhir Bunda?
											</div>
											<i className="fa fa-angle-right" style={{ fontSize: 14, color: '#fff' }}></i>
										</div>
									</div>
							}
						</div>
						{
							(Object.keys(pregnant).length > 0 && pregnant.hpht_date != '') &&
							<>
								<div style={{ flex: 1, paddingLeft: 20, paddingRight: 20, paddingTop: 20, display: 'flex', justifyContent: 'space-between' }}>

									{
										pregnant.pregnant_week > 42
											?
											<div style={{
												marginTop: 20,
												marginHorizontal: 20,
												// backgroundColor: 'rgba(0,0,0,0.2)',
												paddingHorizontal: 15,
												paddingVertical: 12,
												borderRadius: 7.5,
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												cursor: 'pointer',
												width: '100%'
											}}
												className={`ripple background-dark-theme-${theme}`}
												onClick={() => {
													if (Object.keys(subscribe).length > 0 && !subscribe.subscribe_bool) {
														history.push('/subscriptions-package')
													} else {
														setShowDatePicker(true)
													}
												}}
											>
												<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
													<i className="fa fa-child" style={{ fontSize: 14, color: '#fff', alignSelf: 'flex-start', marginTop: 3 }}></i>
													<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
														Usia kehamilan Bunda telah memasuki <br /> usia {pregnant.pregnant_age}.
													</div>
													<i className="fa fa-angle-right" style={{ fontSize: 14, color: '#fff' }}></i>
												</div>
											</div>
											:
											[...Array(5)].map((item, key) => {
												return (
													renderPregnancy(item, key)
												)
											})
									}
								</div>
								{
									pregnant.pregnant_week <= 42 &&
									<div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
										<div style={{
											marginHorizontal: 20,
											// backgroundColor: 'rgba(0,0,0,0.2)',
											paddingHorizontal: 15,
											paddingVertical: 12,
											borderRadius: 7.5,
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
											className={`background-dark-theme-${theme}`}
										>
											<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
												<i className="fa fa-calendar-week" style={{ fontSize: 14, color: '#fff' }}></i>
												<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
													Tgl HPL Parikh
												</div>
												<div style={{ color: '#fff', fontSize: 14 }}>{pregnant.hpl_parikh_date !== "" ? moment(pregnant.hpl_parikh_date).format("ll") : "-"}</div>
											</div>
										</div>
										<div style={{
											marginTop: 20,
											marginHorizontal: 20,
											// backgroundColor: 'rgba(0,0,0,0.2)',
											paddingHorizontal: 15,
											paddingVertical: 12,
											borderRadius: 7.5,
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
											className={`background-dark-theme-${theme}`}
										>
											<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
												<i className="fa fa-calendar-week" style={{ fontSize: 14, color: '#fff' }}></i>
												<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
													Tgl HPL Naegele
												</div>
												<div style={{ color: '#fff', fontSize: 14 }}>{pregnant.hpl_naegele_date !== "" ? moment(pregnant.hpl_naegele_date).format("ll") : "-"}</div>
											</div>
										</div>
									</div>
								}
							</>
						}
						<div style={{ flex: 1, paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
							<div style={{ fontSize: 14, display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ fontWeight: 'bold', color: 'rgba(255,255,255, 0.8)' }}>Riwayat Kontraksi</div>
								<div
									style={{ color: 'rgba(255,255,255, 0.8)', textDecoration: 'underline', cursor: 'pointer' }}
									className="ripple"
									onClick={() => history.push({
										pathname: '/contraction-history',
										params: {
											fieldLabel: 'Hari Ini',
											startDate: moment().format('YYYY-MM-DD'),
											endDate: ''
										}
									})}
								>Lihat riwayat</div>
							</div>

							<div style={{ display: 'flex', marginTop: 15, justifyContent: 'space-between' }}>
								{
									contractions.map((item, key) => {
										let today = isToday(moment(item.date))
										let yesterday = isYesterday(moment(item.date))
										let tommorow = isTommorow(moment(item.date))
										let fieldLabel = moment(item.date).format("ll")

										if (today) {
											fieldLabel = 'Hari Ini'
										}

										if (yesterday) {
											fieldLabel = 'Kemarin'
										}

										if (tommorow) {
											fieldLabel = 'Besok'
										}

										return (
											<div
												style={{
													flex: 1,
													borderRadius: 12,
													height: 90,
													// backgroundColor: '#A54F9D',
													alignItems: 'center',
													overflow: 'hidden',
													cursor: 'pointer',
													marginLeft: key !== 0 ? 15 : 0,
												}}
												className={`ripple background-light-theme-${theme}`}
												key={key}
											>
												<div style={{
													height: 65,
													paddingHorizontal: 10,
													backgroundColor: today && '#fff',
													borderRadius: 12,
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													justifyContent: 'center'
												}}
													className={`ripple background-dark-theme-${theme}`}
													onClick={() => history.push({
														pathname: '/contraction-history',
														params: {
															fieldLabel: fieldLabel,
															startDate: item.date,
															endDate: ''
														}
													})}
												>
													{
														today &&
														<>
															<span style={{ fontSize: 13, fontWeight: 'bold' }} className={`color-theme-${theme}`}>Hari Ini</span>
														</>
													}

													{
														yesterday &&
														<>
															<span style={{ fontSize: 13, color: '#fff', fontWeight: 'bold' }}>Kemarin</span>
														</>
													}

													{
														tommorow &&
														<>
															<span style={{ fontSize: 13, color: '#AD73A7', fontWeight: 'bold' }}>Besok</span>
														</>
													}

													{
														(!today && !yesterday && !tommorow) &&
														<>
															<div style={{ fontSize: 16, color: '#FFFFFF', textAlign: 'center', fontWeight: 'bold' }}>
																{moment(item.date).format("DD")}
															</div>
															<div style={{ fontSize: 10, color: '#FFFFFF', textAlign: 'center', fontWeight: 'normal', textTransform: 'uppercase' }}>
																{moment(item.date).format("MMM")}
															</div>
															<div style={{ fontSize: 8, color: '#FFFFFF', textAlign: 'center', fontWeight: 'normal', textTransform: 'uppercase' }}>
																{moment(item.date).format("YYYY")}
															</div>
														</>
													}
												</div>
												<div style={{ fontSize: 11, marginTop: 4.5, color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
													{item.duration_avg !== "0" ? item.duration_avg_label : '---'}
												</div>
											</div>
										)
									})
								}
							</div>

							{
								showImportantContact &&
								<div style={{
									marginTop: 20,
									marginHorizontal: 20,
									// backgroundColor: 'rgba(0,0,0,0.2)',
									paddingHorizontal: 15,
									paddingVertical: 12,
									borderRadius: 7.5,
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									cursor: 'pointer'
								}}
									className={`ripple background-dark-theme-${theme}`}
									onClick={() => history.push('/account-number-phone')}
								>
									<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
										<i className="fa fa-info-circle" style={{ fontSize: 14, color: '#fff', alignSelf: 'flex-start', marginTop: 3 }}></i>
										<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
											Bunda belum menambahkan no kontak penting.
										</div>
										<i className="fa fa-angle-right" style={{ fontSize: 14, color: '#fff' }}></i>
									</div>
								</div>
							}

							<div style={{
								marginTop: 20,
								marginHorizontal: 20,
								// backgroundColor: 'rgba(0,0,0,0.2)',
								paddingHorizontal: 15,
								paddingVertical: 12,
								borderRadius: 7.5,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								cursor: 'pointer'
							}}
								className={`ripple background-dark-theme-${theme}`}
								onClick={() => refModalFeedback.current.showModal(true)}
							>
								<div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
									<i className="fa fa-quote-left" style={{ fontSize: 14, color: '#fff', alignSelf: 'flex-start', marginTop: 3 }}></i>
									<div style={{ flex: 1, paddingLeft: 10, fontSize: 14, color: '#fff' }}>
										Apa yang Bunda pikirkan, untuk menjadikan aplikasi ini lebih baik lagi?
									</div>
									<i className="fa fa-angle-right" style={{ fontSize: 14, color: '#fff', marginLeft: 15 }}></i>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div>
					{
						(Object.keys(subscribe).length > 0 && !subscribe.subscribe_bool) &&
						<div style={{
							display: 'flex', flexDirection: 'column',
							backgroundColor: "rgba(0,0,0,0.3)",
							paddingHorizontal: 20, paddingTop: 20, paddingBottom: 15, justifyContent: 'center', alignItems: 'center', maxHeight: 150
						}}
						>
							<div style={{ color: "#fff", fontSize: 14, textAlign: 'center', width: 'calc(100% - 60px)' }}>Langganan Anda telah berakhir atau Anda belum membeli paket untuk berlangganan.</div>
							<div style={{
								marginTop: 15,
								borderRadius: 40,
								width: '80%',
								height: 40,
								// backgroundColor: "#9E3894",
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'row',
								display: 'flex',
								cursor: 'pointer'
							}}
								className={`ripple background-light-theme-${theme}`}
								onClick={() => history.push('/subscriptions-package')}
							>
								<i className="fas fa-check-circle" style={{ color: '#fff' }}></i>
								<span style={{ color: "#fff", fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
									Beli Paket
								</span>
							</div>
						</div>
					}

					{
						(Object.keys(subscribe).length > 0 && subscribe.subscribe_bool) &&
						<div style={{
							alignItems: 'center',
							position: 'absolute',
							bottom: 30,
							left: 0,
							right: 0,
							display: 'flex',
							justifyContent: 'center',
							pointerEvents: 'none'
						}}>
							<div style={{
								width: 100,
								height: 100,
								backgroundColor: '#fff',
								borderRadius: 100 / 2,
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								pointerEvents: 'all'
							}}>

								<div style={{
									borderRadius: 85 / 2,
									width: 85,
									height: 85,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									background: "linear-gradient(#fd79a8, #e84393)",
									cursor: 'pointer'

								}}
									className="ripple"
									onClick={() => { history.push('contraction') }}
								>
									<i class="fa fa-play-circle" style={{ color: '#fff', fontSize: 38 }}></i>
								</div>
							</div>
						</div>
					}

					{
						!backgroundImageTheme
						&&
						<div style={{
							position: 'absolute',
							left: -(180 / 2),
							bottom: 90,
							backgroundColor: 'rgba(0,0,0,0.15)',
							width: 180,
							height: 180,
							borderRadius: 180 / 2
						}}></div>
					}
				</div>

				{isLoading && <Loading title={loadingMessage} />}
			</div>

			<ModalTheme
				ref={refModalTheme}
				onSelectedTheme={(item) => {
					setSelectedTheme(item.name)
					localStorage.setItem('ake_theme', item.name)
					changeTheme(item.name)
				}}
				selectedTheme={selectedTheme}
			/>

			<ModalFeedback
				ref={refModalFeedback}
				onLoading={(value) => {
					if (value) {
						setLoadingMessage('Mengirimkan feedback')
					}
					setIsLoading(value)
				}}
				onSuccess={(msg) => alert(msg)}
				onError={(msg) => {
					setErrMessage(msg)
					setShowError(true)
				}}
			/>

			<DatePicker
				show={showDatePicker}
				setShow={setShowDatePicker}
				date={hphtDate}
				setDate={async (selectedDate) => {
					let todayDate = moment().format("YYYY-MM-DD")
					let inputDate = moment(selectedDate).format("YYYY-MM-DD")

					if (inputDate <= todayDate) {
						setHphtDate(selectedDate)

						setIsLoading(true)
						setLoadingMessage("Mengatur tanggal HPHT...")

						let response = await ApiHelpers.post("", "account/pregnancy/update", {
							"hpht_date": selectedDate
						})

						if (response.status == 200) {
							getDashboard()
						} else {
							setIsLoading(false)
							setErrMessage(response.message)
							setShowError(true)
						}
					} else {
						setErrMessage('Tanggal HPHT minimal hari ini.')
						setShowError(true)
					}
				}}
				header={'HPHT (Hari Pertama Haid Terakhir) Anda'}
			/>

			<Snackbar style={{
				position: 'absolute',
				width: '100%',
				left: 'auto',
				right: 'auto',
				bottom: 0
			}} open={showError} autoHideDuration={3000} onClose={() => {
				setErrMessage('')
				setErrType("warning")
				setShowError(false)
			}}>
				<Alert onClose={() => {
					setErrMessage('')
					setErrType("warning")
					setShowError(false)
				}} severity={errType}
					sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
					{errMessage}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default Dashboard