import React, { Component, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal"
import ApiHelpers from "../../helpers/ApiHelpers"
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { useTheme } from "../../hooks/useTheme"


const AccountPhoneNumberForm = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({

    closeModal() {
      closeModal()
    },

    onShowModal(data) {
      onShowModal(data)
    }

  }));

  const { theme } = useTheme()

  const [isShowModal, setIsShowModal] = useState(false)
  const [inputName, setInputName] = useState("")
  const [inputPhone, setInputPhone] = useState("")
  const [inputId, setInputId] = useState("")
  const [showError, setShowError] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [errType, setErrType] = useState('warning')
  const [loadingMessage, setIsLoadingMessage] = useState('warning')

  const history = useHistory()

  const onShowModal = (data) => {

    setTimeout(() => {
      setIsShowModal(true)
      setInputName(!data ? "" : data.name)
      setInputPhone(!data ? "" : data.number)
      setInputId(!data ? "" : data.id)
    }, 1)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  // useEffect(() => {
  //   props.refAccountPhoneNumberForm.current = onShowModal;

  //   return () => {
  //     props.refAccountPhoneNumberForm.current = null;
  //   };
  // });

  const onCloseModal = () => { setIsShowModal(false) }
  const onAccountPhoneNumberFormListeners = async () => {
    setShowError(false)
    let valid = false
    let errMessage = ""

    if (inputName === "" && inputPhone === "") {
      valid = false
      errMessage = "Anda belum menuliskan nama & no handphone"
    } else if (inputName === "") {
      valid = false
      errMessage = "Anda belum menuliskan nama Anda."
    } else {
      if (inputPhone === "") {
        valid = false
        errMessage = "Anda belum menuliskan no handphone Anda."
      } else {
        valid = true
        errMessage = "Ok."
      }
    }


    if (valid) {
      // setIsLoading(true)
      // setIsLoadingMessage("Proses login...")
      props.onUpdateData(
        {
          id: inputId,
          name: inputName,
          mobilephone: inputPhone
        }
      )

    } else {
      setErrMessage(errMessage)
      setErrType("warning")
      setShowError(true)

    }
  }

  const onCloseAlert = () => {
    setErrMessage('')
    setErrType("warning")
    setShowError(false)
  }



  const renderToolbar = () => {
    return (
      <Modal.Header style={{
        width: '100%',
        height: 50,
        flexDirection: 'row',
        // backgroundColor: "#950082",
        borderRadius: '15px 15px 0px 0px',
        alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 20,
      }} className={`background-light-theme-${theme}`}>
        <i class="fas fa-address-card" style={{ color: "#fff" }}></i>
        <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>{inputId !== '' ? 'Ubah Data' : 'Tambah No Penting'}</div>
        <i className="fas fa-times icon" style={{ color: "#fff", cursor: 'pointer' }} onClick={() => {
          onCloseModal()
        }}></i>
      </Modal.Header>
    )
  }
  const renderFooter = () => {
    return (
      <Modal.Footer style={{
        height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row', 
        // backgroundColor: "#D400B8",
        borderRadius: '0px 0px 15px 15px',
      }} className={`background-light-theme-${theme}`}>
        <div style={{
          borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
          alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
        }}
          onClick={() => {
            onCloseModal()
          }}>
          <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
        </div>
        <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
          <div style={{
            borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: 'center', justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            cursor: 'pointer'
          }}
            onClick={() => { onAccountPhoneNumberFormListeners() }}>
            <i className="fas fa-check icon ripple" style={{ color: '#fff', fontSize: 14 }}></i>
            <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>{inputId !== '' ? 'Simpan Perubahan' : 'Tambahkan'}</div>
          </div>
        </div>
      </Modal.Footer>
    )
  }
  const renderContents = () => {
    return (
      <Modal.Body style={{ 
        // backgroundColor: "#D400B8"
         }} className={`background-theme-${theme}`}>
        <div style={{ alignItems: "center" }}>
          <div style={{
            paddingHorizontal: 15,
            height: 40,
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 40,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 20
          }}>
            <i className="far fa-user-circle icon" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
            <input
              value={inputName}
              onChange={(event) => setInputName(event.target.value)}
              type="text"
              onSubmit={() => { onAccountPhoneNumberFormListeners() }}
              className="form-control no-border"
              placeholder="Nama"
              defaultValue=""
              style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
            />

          </div>
        </div>

        <div style={{ alignItems: "center" }}>
          <div style={{
            paddingHorizontal: 15,
            height: 40,
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 40,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 20
          }}>
            <i class="fab fa-whatsapp" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
            <input
              value={inputPhone}
              onChange={(event) => setInputPhone(event.target.value)}
              className="form-control no-border"
              placeholder="No. Telephone"
              type="number"
              defaultValue=""
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  onAccountPhoneNumberFormListeners()
                }
              }}

              style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
            />

          </div>
        </div>
      </Modal.Body>
    )
  }
  return (
    <div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        {renderToolbar()}
        {renderContents()}
        {renderFooter()}
      </Modal>

      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto'
      }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
        <Alert onClose={onCloseAlert} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>
    </div>
  )
})

export default AccountPhoneNumberForm