import React, { Component, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import { useTheme } from "../../hooks/useTheme"


export default function SubscriptionsInfo(props) {

  const { theme } = useTheme()

  const [isShowModal, setIsShowModal] = useState(false)
  const [objectDetail, setObjectDetail] = useState(null)

  const history = useHistory()

  useEffect(() => {
    props.refSubscriptionsInfo.current = onShowModal;

    return () => {
      props.refSubscriptionsInfo.current = null;
    };
  });

  const onShowModal = (data) => {
    setObjectDetail(data)
    setTimeout(() => {
      setIsShowModal(true)
    }, 1)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  const onCloseModal = () => { setIsShowModal(false) }



  const renderToolbar = () => {
    return (
      <Modal.Header style={{
        width: '100%',
        height: 50,
        flexDirection: 'row',
        // backgroundColor: "#950082",
        borderRadius: '15px 15px 0px 0px',
        alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 20,
      }}
      className={`background-light-theme-${theme}`}
      >
        {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
        <i class="fas fa-info-circle" style={{ color: "#fff" }}></i>
        <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Informasi Paket</div>
        <i className="fas fa-times icon ripple" style={{ color: "#fff", cursor: 'pointer' }} onClick={() => { onCloseModal() }}></i>
      </Modal.Header>
    )
  }
  const renderContents = () => {
    if (objectDetail !== null && objectDetail.id !== undefined) {
      return (
        <Modal.Body style={{
          // backgroundColor: "#D400B8"
        }}
        className={`background-theme-${theme}`}
        >
          <div style={{ textAlign: "left", padding: "15px 0px", borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              Nama Paket
            </div>
            <div style={{ fontSize: 16, color: '#fff' }}>{objectDetail.name}</div>
          </div>
          <div style={{ textAlign: "left", padding: "15px 0px", borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              <div style={{ fontSize: 14, color: "rgba(255,255,255,0.9)", display: "flex" }}>Harga Paket <div style={{ fontStyle: 'italic', marginLeft: 5 }}>{objectDetail.special_price_bool && "(Harga Spesial)"}</div></div>
            </div>
            <div style={{ marginTop: 5, flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
              <div numberOfLines={1} style={{
                fontSize: objectDetail.discount_value === "0" ? 15 : 14,
                marginRight: objectDetail.discount_value === "0" ? 0 : 10,
                color: objectDetail.discount_value === "0" ? "#55efc4" : "#FFF",
                textDecorationLine: objectDetail.discount_value === "0" ? "none" : "line-through",
                fontWeight: 'bold'
              }}>
                {MyHelpers.formatNumber(objectDetail.price, 'idr')}
              </div>
              {objectDetail.discount_value !== "0" &&
                <div numberOfLines={1} style={{ fontSize: 15, color: '#55efc4', fontWeight: 'bold', display: "flex" }}>
                  {MyHelpers.formatNumber(Number(objectDetail.price - objectDetail.discount_value), 'idr')} <div style={{ fontSize: 15, marginLeft: 4 }}>(-{MyHelpers.formatNumber(objectDetail.discount_percent, '')}%)</div>
                </div>
              }
            </div>
          </div>
          {objectDetail.discount_bool &&
            <div style={{ textAlign: "left", padding: "15px 0px", borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
              <div style={{ fontSize: 14, color: "rgba(255,255,255,0.9)" }}>Periode Diskon</div>
              <div style={{ fontSize: 16, color: "#FFF" }}>{moment(objectDetail.discount_start_datetime).format("ll")} - {moment(objectDetail.discount_end_datetime).format("ll")}</div>
            </div>
          }
          <div style={{ textAlign: "left", padding: "15px 0px", marginBottom: 40 }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              Deskripsi
            </div>
            <div style={{ fontSize: 16, color: '#fff' }}>{objectDetail.desc}</div>
          </div>
        </Modal.Body>
      )
    }
  }
  const renderFooter = () => {
    return (
      <Modal.Footer style={{
        height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row',
        // backgroundColor: "#D400B8",
        borderRadius: '0px 0px 15px 15px',
      }} className={`background-light-theme-${theme}`}>
        <div style={{
          borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
          alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
        }}
          onClick={() => {
            onCloseModal()
          }}>
          <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
        </div>
        <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
          <div style={{
            borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: 'center', justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            cursor: 'pointer'
          }}>
            <i className="fas fa-check icon ripple" style={{ color: '#fff', fontSize: 14 }}></i>
            <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Beli Paket</div>
          </div>
        </div>
      </Modal.Footer>
    )
  }
  return (
    <div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        {renderToolbar()}
        {renderContents()}
        {renderFooter()}
      </Modal>
    </div>
  )
}