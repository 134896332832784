import React, { useState, useRef, useCallback, useEffect } from "react"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Loading from "../../components/Loading"
import logo_bk from "../../assets/BK/img_splash.png"
import logo_google from "../../assets/BK/icon_google.png"
import { useHistory } from "react-router-dom"
import ApiHelpers from "../../helpers/ApiHelpers"
import Modal from "react-bootstrap/Modal"

import GoogleLogin from 'react-google-login';
import LoginPopup from "./LoginPopup"
import { useTheme } from './../../hooks/useTheme';

export default function Login() {
    const history = useHistory()

    const { theme } = useTheme()

    const [inputEmail, setInputEmail] = useState("")
    const [inputPassword, setInputPassword] = useState("")
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [inputPasswordType, setInputPasswordType] = useState("password")
    const [inputPasswordClass, setInputPasswordClass] = useState("input-password")
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMessage, setIsLoadingMessage] = useState("Loading...")
    const [showError, setShowError] = useState(false)
    const [errMessage, setErrMessage] = useState("")
    const [errType, setErrType] = useState("warning")

    const refLoginPopup = useRef(null);

    let refDialogLogin = null;

    const onSetIsLogin = async (isLogin, data) => {
        setIsLoadingMessage("Menyimpan sesi login Anda...")
        return new Promise((resolve) => {
            let result = false
            localStorage.setItem('user_session', JSON.stringify(data))
            let auth = JSON.parse(localStorage.getItem('user_session'))
            if (auth !== null && auth.id !== undefined && auth.id !== "") {
                result = isLogin
            }

            return resolve(result)
        })
    }

    // ====================================================================================
    // ========== ACTION LISTENERS SECTIONS ===============================================
    // ====================================================================================
    function onShowPassword() {
        let isShow = !isShowPassword
        setIsShowPassword(isShow)
        setInputPasswordType(isShow ? "input" : "password")
        setInputPasswordClass(isShow ? "" : "input-password")
    }
    function onTextChange(event, params) {
        if (params === "inputEmail") { setInputEmail(event.target.value) }
        if (params === "inputPassword") { setInputPassword(event.target.value) }
    }
    function useFocusNext() {
        const controls = useRef([]);

        const handler = (event) => {
            if (event.keyCode === 13) {
                // Required if the controls can be reordered
                controls.current = controls.current
                    .filter((control) => document.body.contains(control))
                    .sort((a, b) =>
                        a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
                            ? -1 : 1
                    );

                const index = controls.current.indexOf(event.target);
                const next = controls.current[index + 1];
                next && next.focus();

                // IE 9, 10
                event.preventDefault();
            }
        };

        return useCallback((element) => {
            if (element && !controls.current.includes(element)) {
                controls.current.push(element);
                element.addEventListener('keydown', handler);
            }
        }, []);
    }
    function onKeyDownToLogin(event) { if (event.keyCode === 13) { onLoginBasicListeners() } }

    async function onLoginBasicListeners(params) {
        setIsLoading(true)
        setIsLoadingMessage("Proses login...")

        let response = await ApiHelpers.post("", "auth/login", {
            "email": params.inputUsername,
            "password": params.inputPassword,
            "fcm_token": "",
            "device": "web",
        })

        setIsLoadingMessage("Mengecek akun login Anda...")
        if (response.status === 200) {
            let isLogged = await onSetIsLogin(true, response.results.data)
            if (isLogged) {
                setTimeout(() => {
                    history.push('/dashboard')
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMessage("Gagal login ke akun Anda.")
                    setErrType("error")
                    setShowError(true)
                }, 1500)
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
            setErrMessage(response.message)
            setErrType("error")
            setShowError(true)
        }
    }

    const onLoginGoogleListeners = async (responseGoogle) => {
        setIsLoading(true)
        let response = await ApiHelpers.post('', 'auth/sso', {
            "email": responseGoogle.profileObj.email,
            "name": responseGoogle.profileObj.name,
            "device": "web",
            "fcm_auth": responseGoogle.tokenId,
            "fcm_token": responseGoogle.accessToken,
        })
        setIsLoadingMessage("Mengecek akun login Anda...")
        if (response.status === 200) {
            let isLogged = await onSetIsLogin(true, response.results.data)
            if (isLogged) {
                setTimeout(() => {
                    history.push('/dashboard')
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMessage("Gagal login ke akun Anda.")
                    setErrType("error")
                    setShowError(true)
                }, 1500)
            }
        } else {
            setErrMessage(response.message)
            setErrType("error")
            setShowError(true)
        }

    }

    function onCloseAlert() { setShowError(false); setErrMessage(""); setErrType("warning") }

    // useEffect(() => {
    //     let linkLogin = ''
    //     if (window.location.hostname != 'kontraksi-aplikasi.bidankita.com'){
    //         linkLogin = `https://dev-portal.bidankita.com/login?ref=${window.location.origin}`
    //     }else{
    //         linkLogin = `https://keluarga.bidankita.com/login?ref=${window.location.origin}`
    //     }
    //     window.location.href = linkLogin
    // }, [])

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    const focusNextRef = useFocusNext()

    // const responseGoogleError = (response) => {
    //     setIsLoading(false)
    //     setErrMessage('Gagal login menggunakan akun Google Anda.')
    //     setShowError(true)
    // }

    // ====================================================================================
    // ========== RETURN SECTIONS =========================================================
    // ====================================================================================
    return (
        <div className={`Login background-theme-${theme}`}>
            <img src={logo_bk} className="Login-img" alt="Bidan Kita" />
            <div className={`Login-input-container`} style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'left', color: '#FFF' }}>
                    KONTRAKSI ENAK
                </div>
                <div style={{
                    color: '#FFF',
                    fontWeight: '300', fontSize: 14,
                    marginTop: 15, textAlign: 'left'
                }}>
                    Melacak kontraksi Anda dan menentukan kapan waktunya untuk pergi ke rumah sakit.
                </div>
                <div className="d-grid gap-2">
                    <button className={`btn btn-primary btn-rounded mg-top5 background-theme-${theme}`}
                        style={{ display: 'flex', justifyContent: 'center', height: 40, alignItems: 'center', borderRadius: 40, marginTop: 15 }}
                        type="button"
                        onClick={() => { refLoginPopup.current() }}>
                        <i className="fas fa-check-circle"></i>
                        <div style={{ fontSize: 14, fontWeight: 'bold', color: '#fff', marginLeft: 10 }}>
                            Masuk dg Akun Bidan Kita
                        </div>
                    </button>

                    <GoogleLogin
                        clientId={'593958113541-6kj3k9d4vdsmu1t1qqpjj8mi4en82m91.apps.googleusercontent.com'}
                        onSuccess={onLoginGoogleListeners}
                        // onFailure={responseGoogleError}

                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 40, marginTop: 5 }}
                        tag="div"
                        buttonText="Masuk atau Daftar dg Google"
                        render={renderProps => (
                            <button className="btn-rounded mg-top5" style={{ borderWidth: 0, display: 'flex', justifyContent: 'center', height: 40, alignItems: 'center', borderRadius: 40, marginTop: 5 }} type="button" onClick={renderProps.onClick}>

                                <img src={logo_google} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
                                <div style={{
                                    fontSize: 14, fontWeight: 'bold', color: 'rgba(80, 80, 80, 0.70)',
                                    marginLeft: 10
                                }}>
                                    Masuk atau Daftar dg Google
                                </div>
                            </button>
                        )}
                    />

                </div>
                {/* <div style={{ fontSize: 30, textAlign: 'center', paddingTop:15, paddingBottom: 15, color:'#fff' }}>
                    <b>Comming Soon</b>
                </div> */}
            </div>

            {isLoading && <Loading title={isLoadingMessage} />}

            <Snackbar style={{
                position: 'absolute',
                width: '100%',
                left: 'auto',
                right: 'auto'
            }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
                <Alert onClose={onCloseAlert} severity={errType}
                    sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
                    {errMessage}
                </Alert>
            </Snackbar>

            <LoginPopup refLoginPopup={refLoginPopup} onLoginBasicListeners={onLoginBasicListeners} />
        </div>
    )
}