import React, { Component, useEffect, useState } from "react"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal"
import ApiHelpers from "../../helpers/ApiHelpers"
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import Loading from "../../components/Loading"
import { useTheme } from "../../hooks/useTheme"


export default function LogoutPopup(props) {
    const { theme } = useTheme()
    const [isShowModal, setIsShowModal] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [errType, setErrType] = useState('warning')
    // const [loadingMessage, setIsLoadingMessage] = useState('warning')
    const [loadingMessage, setLoadingMessage] = useState("Loading...")
    const [isFetched, seIsFetched] = useState(false)

    const history = useHistory()

    const onShowModal = () => {
        setTimeout(() => {
            setIsShowModal(true)
        }, 1)
    }

    useEffect(() => {
        // seIsFetched(false)
        props.refLogoutPopup.current = onShowModal;

        return () => {
            props.refLogoutPopup.current = null;
        };
    });

    const onCloseModal = () => { setIsShowModal(false) }
    const onLogoutListeners = async () => {
        setLoadingMessage("Menghapus sesi login Anda...")
        seIsFetched(true)

        localStorage.setItem("user_session", null)
        setTimeout(() => {
            history.push('/login')
        }, 1500)
        // let linkLogout = ''
        // if (window.location.hostname != 'kontraksi-aplikasi.bidankita.com') {
        //     linkLogout = 'https://dev-portal.bidankita.com/logout?ref=' + window.location.href
        // } else {
        //     linkLogout = 'https://keluarga.bidankita.com/logout?ref=' + window.location.href
        // }
        // window.location.href = linkLogout
    }

    const onCloseAlert = () => {
        setErrMessage('')
        setErrType("warning")
        setShowError(false)
    }



    const renderToolbar = () => {
        return (
            <Modal.Header style={{
                width: '100%',
                height: 50,
                flexDirection: 'row',
                // backgroundColor: "#950082",
                borderRadius: '15px 15px 0px 0px',
                alignItems: 'center', justifyContent: 'center',
                paddingHorizontal: 20,
            }} className={`background-light-theme-${theme}`}>
                {/* <i className="fas fa-sign-in icon" style={{ color: "#fff" }}></i> */}
                <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Sign Out?</div>
                {/* <i className="fas fa-times icon" style={{ color: "#fff" }} onClick={() => {
                    onCloseModal()
                }}></i> */}
            </Modal.Header>
        )
    }
    const renderFooter = () => {
        return (
            <Modal.Footer style={{
                height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row',
                // backgroundColor: "#D400B8",
                borderRadius: '0px 0px 15px 15px',
            }} className={`background-light-theme-${theme}`}>
                <div style={{
                    borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
                    alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
                }}
                    onClick={() => {
                        onCloseModal()
                    }}>
                    <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
                </div>
                <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                    <div style={{
                        borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                        alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'row',
                        display: 'flex', cursor: 'pointer'
                    }}
                        onClick={() => { onLogoutListeners() }}>
                        <i className="fas fa-check icon" style={{ color: '#fff', fontSize: 14 }}></i>
                        <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Ya, Sing Out</div>
                    </div>
                </div>
            </Modal.Footer>
        )
    }
    const renderContents = () => {
        return (
            <Modal.Body style={{
                // backgroundColor: "#D400B8" 
            }} className={`background-theme-${theme}`}>
                <div style={{ height: 88, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 50, display: 'flex' }}>
                    <div>
                        <div style={{ color: "#fff", fontSize: 14, textAlign: 'center' }}>
                            Apakah Anda yakin akan Sign Out,
                        </div>
                        <div style={{ color: "#fff", fontSize: 14, textAlign: 'center' }}>
                            dan akan Login kembali?
                        </div>
                    </div>
                </div>
            </Modal.Body>
        )
    }

    if (isFetched == true) {
        return (
            <div>
                <Loading title={loadingMessage} />
            </div>
        )
    }
    return (
        <div>
            <Modal animationType="fade" show={isShowModal} transparent centered>
                {/* <div style={{flex:1, backgroundColor:'rgba(0,0,0,0.8)', alignItems:'center', justifyContent:'center'}}>
                    <div style={{width:'100%', borderRadius:10, backgroundColor:"#D400B8", overflow:'hidden'}}> */}
                {renderToolbar()}
                {renderContents()}
                {renderFooter()}
                {/* </div>
                </div> */}
            </Modal>

            <Snackbar style={{
                position: 'absolute',
                width: '100%',
                left: 'auto',
                right: 'auto'
            }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
                <Alert onClose={onCloseAlert} severity={errType}
                    sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
                    {errMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}