import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"

import Modal from "react-bootstrap/Modal"

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useTheme } from './../../hooks/useTheme'

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js'
import { Line } from 'react-chartjs-2'

import moment from "moment"

import DatePicker from './../../components/datepicker/DatePicker'
import Loading from '../../components/Loading'
import { Alert, Snackbar } from '@mui/material'
import ApiHelpers from '../../helpers/ApiHelpers'

import img_empty from "../../assets/img_empty_data.png";
import icon_contraction_lembut from '../../assets/icon_contraction_lembut.png'
import icon_contraction_sedang from '../../assets/icon_contraction_sedang.png'
import icon_contraction_kuat from '../../assets/icon_contraction_kuat.png'
import icon_contraction_intens from '../../assets/icon_contraction_intens.png'

import ContractionMyNoteInfo from "../contraction/ContractionMyNoteInfo";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

const options = {
  responsive: true,
  layout: {
    padding: {
      left: 20,
      right: 20,
    }
  },
  elements: {
    line: {
      tension: 0.4,
    }
  },
  scales: {
    x: {
      display: true,
      title: {
        display: false,
        text: 'Durasi'
      },
      ticks: {
        color: "rgba(255,255,255,0.4)"
      },
      grid: {
        color: function (context) {
          return 'rgba(255,255,255,0.1)';
        },
        borderColor: 'rgba(255,255,255,0.1)',
        borderDash: [8, 6],
      }
    },
    y: {
      display: true,
      beginAtZero: false,
      title: {
        display: false,
        text: 'Interval'
      },
      ticks: {
        callback: (label) => `${label}d`,
        color: "rgba(255,255,255,0.4)"
      },
      grid: {
        color: function (context) {
          return 'rgba(255,255,255,0.1)';
        },
        borderColor: 'rgba(255,255,255,0.1)',
        borderDash: [8, 6],
      }
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    filler: {
      propagate: true,
    },
    title: {
      display: false,
      text: 'Grafik',
    },
    tooltip: {
      enabled: false
    }
  },
};

export default function History() {

  const [labels, setLabels] = useState([])
  const [data, setData] = useState([])

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showBSheet, setShowBSheet] = useState(false)

  const [filterDate, setFilterDate] = useState('')

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...")

  const [contractionData, setContractionData] = useState({})
  const [chartType, setChartType] = useState('duration')

  const [showError, setShowError] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [errType, setErrType] = useState('warning')

  const [filterDateLabel, setFilterDateLabel] = useState('Hari Ini')

  const [isRange, setIsRange] = useState(false)
  const [isDetail, setIsDetail] = useState(false)

  const [detailTree, setDetailTree] = useState([])

  const refChart = useRef(null)

  const contractionNoteInfoRef = useRef()

  const history = useHistory()
  const location = useLocation()
  const { theme } = useTheme()

  useEffect(() => {
    // const query = new URLSearchParams(location.search)

    // let fieldLabel = query.get('field_label')
    // let startDate = query.get('start_date')
    // let endDate = query.get('end_date')

    let fieldLabel = location.params && location.params.fieldLabel ? location.params.fieldLabel : ''
    let startDate = location.params && location.params.startDate ? location.params.startDate : ''
    let endDate = location.params && location.params.endDate ? location.params.endDate : ''

    if (!startDate) {
      startDate = moment().format('YYYY-MM-DDD')
      fieldLabel = 'Hari Ini'
    }

    if (!fieldLabel) {
      fieldLabel = moment(startDate).format('ll')
    }

    if (!endDate) {
      endDate = ''
    }

    setDetailTree([{
      id: '',
      start_date: startDate,
      end_date: endDate,
      field_label: fieldLabel
    }])

    getData('', startDate, endDate, fieldLabel)
  }, [])

  const getData = async (id = '', start_date = '', end_date = '', fieldLabel = '') => {
    setFilterDateLabel(fieldLabel)

    setIsLoading(true)
    setLoadingMessage("Memuat data...")

    if (id) {
      setIsDetail(true)
    } else {
      setIsDetail(false)
    }

    if (end_date) {
      setIsRange(true)
    } else {
      setIsRange(false)
    }

    let response = await ApiHelpers.get("", "report/summary", {
      id,
      start_date,
      end_date
    })

    if (response.status == 200) {
      setIsLoading(false)
      setContractionData(response.results.data)

      if (chartType == 'duration') {
        setLabels([...response.results.data.chart_duration.label, ''])
        setData([...response.results.data.chart_duration.data])
      } else {
        setLabels([...response.results.data.chart_interval.label, ''])
        setData([...response.results.data.chart_interval.data])
      }

      // const query = new URLSearchParams(location.search)

      // query.set('field_label', fieldLabel)
      // query.set('start_date', start_date)
      // query.set('end_date', end_date)

      // history.replace({ search: `?${query.toString()}` })
    } else {
      setIsLoading(false)
      setErrMessage(response.message)
      setShowError(true)
    }
  }

  const isToday = (momentDate) => {
    let REFERENCE = moment()
    let TODAY = REFERENCE.clone().startOf('day')
    return momentDate.isSame(TODAY, 'd')
  }
  const isYesterday = (momentDate) => {
    let REFERENCE = moment()
    var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    return momentDate.isSame(YESTERDAY, 'd');
  }

  const renderImageFeel = (item) => {
    if (item.data_jsonobject && item.data_jsonobject.feel) {
      let imageFeel = icon_contraction_lembut

      if (item.data_jsonobject.feel === 'Sedang') {
        imageFeel = icon_contraction_sedang
      }
      if (item.data_jsonobject.feel === 'Kuat') {
        imageFeel = icon_contraction_kuat
      }
      if (item.data_jsonobject.feel === 'Intens') {
        imageFeel = icon_contraction_intens
      }
      return (
        <div style={{ marginLeft: 6 }}><img src={icon_contraction_lembut} style={{ width: 22 }} /></div>
      )
    } else {
      return (
        <div></div>
      )
    }

  }

  return (
    <div
      style={{ flex: 1, height: '100vh', height: 'calc(var(--vh, 1vh) * 100)', display: 'flex', flexDirection: 'column' }}
      className={`background-theme-${theme}`}
    >

      <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'center', paddingTop: 20, marginBottom: 10 }}>
        <i className="far fa-arrow-left ripple" style={{ color: '#fff', cursor: 'pointer', position: 'absolute', left: 20 }} onClick={() => history.goBack()}></i>
        <div style={{ fontWeight: 'bold', color: '#fff', fontSize: 14 }}>Riwayat Kontraksi</div>
      </div>

      <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', justifyContent: 'space-between', paddingBottom: 15, borderBottom: '1px solid rgba(0,0,0,0.08)' }}>
        {
          detailTree.length > 1 &&
          <div
            style={{ width: 40, height: 40, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 15, cursor: 'pointer' }}
            className={`background-dark-theme-${theme} ripple`}
            onClick={() => {
              let tree = [...detailTree]
              let item = tree[tree.length - 2]

              tree.pop()

              setDetailTree([...tree])

              getData(item.id, item.start_date, item.end_date, item.field_label)
            }}
          >
            <i className="far fa-angle-left" style={{ color: 'rgba(255,255,255, 0.4)' }}></i>
          </div>
        }

        <div
          style={{ height: 40, paddingLeft: 15, paddingRight: 15, color: '#fff', borderRadius: 8, flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, cursor: 'pointer' }}
          className={`background-light-theme-${theme} ripple`}
          onClick={() => setShowBSheet(true)}
        >
          <span>{filterDateLabel}</span>
          <span>
            {
              chartType === 'duration'
                ?
                <>
                  <b>{Object.keys(contractionData).length > 0 ? contractionData.summary.item_count : '-'}</b> kontraksi
                </>
                :
                <>
                  <b>{Object.keys(contractionData).length > 0 ? contractionData.summary.interval_count : '-'}</b> interval
                </>
            }

            <i className="far fa-angle-down" style={{ color: 'rgba(255,255,255, 0.8)', marginLeft: 15 }}></i>
          </span>
        </div>

        {
          detailTree.length === 1 &&
          <div
            style={{ width: 40, height: 40, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 15, cursor: 'pointer' }}
            className={`background-dark-theme-${theme} ripple`}
            onClick={() => setShowDatePicker(true)}
          >
            <i className="far fa-calendar-alt" style={{ color: 'rgba(255,255,255, 0.4)' }}></i>
          </div>
        }
      </div>

      <div style={{ height: 'calc(100vh - 196px)', height: 'calc(calc(var(--vh, 1vh) * 100) - 84px)', overflowY: 'auto' }}>

        {
          Object.keys(contractionData).length > 0 && contractionData.item_array.length > 0
            ?
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
                <div style={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>Grafik</div>
                <div>

                  <RadioGroup row name="radio-buttons-group" style={{ color: '#fff' }} >
                    <FormControlLabel
                      value="duration"
                      control={
                        <Radio
                          size="small"
                          color="default"
                          style={{ color: 'rgba(255,255,255, 0.6)' }}
                          checked={chartType == 'duration'}
                          name='chart_type'
                          value='duration'
                          onChange={(e) => {
                            setChartType('duration')
                            setLabels([...contractionData.chart_duration.label, ''])
                            setData([...contractionData.chart_duration.data])
                          }}
                        />
                      }
                      label="Durasi" />
                    <FormControlLabel
                      value="interval"
                      control={
                        <Radio
                          size="small"
                          color="default"
                          style={{ color: 'rgba(255,255,255, 0.6)' }}
                          checked={chartType == 'interval'}
                          name='chart_type'
                          value='interval'
                          onChange={(e) => {
                            setChartType('interval')
                            setLabels([...contractionData.chart_interval.label, ''])
                            setData([...contractionData.chart_interval.data])
                          }}
                        />
                      }
                      label="Interval" />
                  </RadioGroup>
                </div>
              </div>

              <Line
                ref={refChart}
                options={options}
                data={{
                  labels,
                  datasets: [
                    {
                      label: 'Kontraksi',
                      data,
                      borderColor: 'rgba(255,255,255, 0.2)',
                      backgroundColor: 'rgba(255,255,255, 0.8)',
                      radius: 2,
                      borderWidth: 3,
                      fill: {
                        target: 'origin',
                        above: 'rgba(255,255,255, 0.08)',
                      }
                    },
                  ],
                }}
                style={{ marginTop: 15 }} />

              <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginRight: 7.5, borderRadius: 8, height: 70, alignItems: 'center', justifyContent: 'center' }} className={`background-light-theme-${theme}`}>
                  {
                    chartType === 'duration'
                      ?
                      <>
                        <div style={{ color: '#ffeaa7', fontWeight: 'bold', fontSize: 22 }}>{Object.keys(contractionData).length > 0 ? contractionData.summary.duration_label : '-'}</div>
                        <div style={{ fontSize: 10, fontWeight: 'bold', color: '#fff' }}>TOT DUR. KONTRAKSI</div>
                      </>
                      :
                      <>
                        <div style={{ color: '#ffeaa7', fontWeight: 'bold', fontSize: 22 }}>{Object.keys(contractionData).length > 0 ? contractionData.summary.interval_label : '-'}</div>
                        <div style={{ fontSize: 10, fontWeight: 'bold', color: '#fff' }}>TOT DUR. INTERVAL</div>
                      </>
                  }
                </div>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 7.5, borderRadius: 8, height: 70, alignItems: 'center', justifyContent: 'center' }} className={`background-light-theme-${theme}`}>
                  {
                    chartType === 'duration'
                      ?
                      <>
                        <div style={{ color: '#ff9ff3', fontWeight: 'bold', fontSize: 22 }}>{Object.keys(contractionData).length > 0 ? contractionData.summary.duration_avg_label : '-'}</div>
                        <div style={{ fontSize: 10, fontWeight: 'bold', color: '#fff' }}>AVG DUR. KONTRAKSI</div>
                      </>
                      :
                      <>
                        <div style={{ color: '#ff9ff3', fontWeight: 'bold', fontSize: 22 }}>{Object.keys(contractionData).length > 0 ? contractionData.summary.interval_avg_label : '-'}</div>
                        <div style={{ fontSize: 10, fontWeight: 'bold', color: '#fff' }}>AVG DUR. INTERVAL</div>
                      </>
                  }
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: 25, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '20%', height: 7, borderRadius: 7, backgroundColor: 'rgba(0,0,0,0.1)' }} />
              </div>

              <div style={{ marginTop: 15, marginBottom: 15, color: '#fff', fontWeight: 'bold', textAlign: 'center', fontSize: 14 }}>List Data {chartType === 'duration' ? 'Kontraksi' : 'Interval'}</div>

              <div style={{ display: 'flex', marginTop: 15, flexDirection: 'column', paddingLeft: 15, paddingRight: 15, paddingBottom: isDetail ? 50 : 20 }}>

                {
                  Object.keys(contractionData).length > 0 && contractionData.item_array.map((item, key) => (
                    <div key={key}>
                      {
                        chartType === 'duration'
                          ?
                          <>
                            {
                              !isDetail
                                ?
                                <div
                                  style={{
                                    display: 'flex',
                                    borderRadius: 10,
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    marginBottom: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                  }}
                                  className='ripple'
                                  onClick={() => {
                                    let id = item.id ? item.id : ''
                                    let start_date = item.date ? item.date : ''
                                    let end_date = ''
                                    let field_label = moment(item.date).format('ll')

                                    if (id) {
                                      field_label = `${moment(item.start_datetime).format("HH:mm:ss")} s.d. ${moment(item.end_datetime).format("HH:mm:ss")}`
                                    } else {
                                      let today = isToday(moment(item.date))
                                      let yesterday = isYesterday(moment(item.date))

                                      if (today) {
                                        field_label = "Hari Ini"
                                      }

                                      if (yesterday) {
                                        field_label = "Kemarin"
                                      }
                                    }

                                    let tree = [...detailTree]
                                    tree.push({
                                      id,
                                      start_date,
                                      end_date,
                                      field_label
                                    })

                                    setDetailTree([...tree])

                                    getData(id, start_date, end_date, field_label)
                                  }}
                                >
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>{isRange ? 'TGL' : 'WAKTU'} KONTRAKSI</span>
                                    {
                                      isRange
                                        ?
                                        <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.date).format('ll')}</span>
                                        :
                                        <div>
                                          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.start_datetime).format('HH:mm:ss')}</span>
                                          <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.end_datetime).format('HH:mm:ss')}</span>
                                        </div>
                                    }
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>TOT DURASI</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ffeaa7' }}>{item.duration_label}</span>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>AVG DURASI</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{Number(item.item_count) > 1 ? item.duration_avg_label : '-'}</span>
                                  </div>
                                  <i className="fa fa-angle-right" style={{ fontSize: 12, color: '#fff', marginLeft: 20 }}></i>
                                </div>
                                :
                                <div style={{
                                  display: 'flex',
                                  borderRadius: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginBottom: 10,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer'
                                }}
                                  className={`background-light-theme-${theme} ripple`}
                                  onClick={() => {
                                    contractionNoteInfoRef.current.onShowModal(
                                      true,
                                      item.data_jsonobject.my_note_object,
                                      key + 1
                                    )
                                  }}
                                >
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>KONTRAKSI #{(key + 1)}</span>
                                    <div>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.start_datetime).format('HH:mm:ss')}</span>
                                      <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.end_datetime).format('HH:mm:ss')}</span>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>DURASI</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ffeaa7' }}>{item.duration_label}</span>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{item.interval_label}</span>
                                  </div>
                                  <div style={{ width: 28 }}>
                                    {renderImageFeel(item)}
                                  </div>
                                </div>
                            }

                            {
                              item.item_array && item.item_array.length > 1 && !isRange && item.item_array.map((child, indexChild) => (
                                <div style={{
                                  display: 'flex',
                                  borderRadius: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginBottom: 10,
                                  marginLeft: 20,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }} className={`background-light-theme-${theme}`} key={indexChild}>
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>KONTRAKSI #{(indexChild + 1)}</span>
                                    <div>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(child.start_datetime).format('HH:mm:ss')}</span>
                                      <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(child.end_datetime).format('HH:mm:ss')}</span>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>DURASI</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ffeaa7' }}>{child.duration_label}</span>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{child.interval_label}</span>
                                  </div>
                                  <div style={{ width: 28 }}>
                                    {renderImageFeel(child)}
                                  </div>
                                </div>
                              ))
                            }
                          </>
                          :
                          <>
                            {
                              !isDetail
                                ?
                                <div style={{
                                  display: 'flex',
                                  borderRadius: 10,
                                  backgroundColor: 'rgba(0,0,0,0.2)',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginBottom: 10,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer'
                                }} className='ripple'>
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>{isRange ? 'TGL' : 'WAKTU'} INTERVAL</span>
                                    {
                                      isRange
                                        ?
                                        <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.date).format('ll')}</span>
                                        :
                                        <div>
                                          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.start_datetime).format('HH:mm:ss')}</span>
                                          <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.end_datetime).format('HH:mm:ss')}</span>
                                        </div>
                                    }
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>TOT INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ffeaa7' }}>{item.interval_label}</span>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>AVG INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{Number(item.interval_count) > 1 ? item.interval_avg_label : '-'}</span>
                                  </div>
                                  <i className="fa fa-angle-right" style={{ fontSize: 12, color: '#fff', marginLeft: 20 }}></i>
                                </div>
                                :
                                <div style={{
                                  display: 'flex',
                                  borderRadius: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginBottom: 10,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer'
                                }}
                                  className={`background-light-theme-${theme} ripple`}
                                  onClick={() => {
                                    contractionNoteInfoRef.current.onShowModal(
                                      true,
                                      item.data_jsonobject.my_note_object,
                                      key + 1
                                    )
                                  }}
                                >
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL #{(key + 1)}</span>
                                    <div>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.start_datetime).format('HH:mm:ss')}</span>
                                      <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(item.end_datetime).format('HH:mm:ss')}</span>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{item.interval_label}</span>
                                  </div>
                                  <div style={{ width: 28 }}>
                                    {renderImageFeel(item)}
                                  </div>
                                </div>
                            }
                            {
                              item.interval_array && item.interval_array.length > 0 && !isRange && item.interval_array.map((child, indexChild) => (
                                <div style={{
                                  display: 'flex',
                                  borderRadius: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginBottom: 10,
                                  marginLeft: 20,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }} className={`background-light-theme-${theme}`} key={indexChild}>
                                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL #{(indexChild + 1)}</span>
                                    <div>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(child.start_datetime).format('HH:mm:ss')}</span>
                                      <span style={{ fontSize: 12, color: '#fff', marginLeft: 5, marginRight: 5 }}>s.d.</span>
                                      <span style={{ fontSize: 12, fontWeight: 'bold', color: '#fff' }}>{moment(child.end_datetime).format('HH:mm:ss')}</span>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', width: 100, flexDirection: 'column', textAlign: 'right' }}>
                                    <span style={{ fontSize: 10, color: '#fff' }}>INTERVAL</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', color: '#ff9ff3' }}>{child.interval_label}</span>
                                  </div>
                                  <div style={{ width: 28 }}>
                                    {renderImageFeel(child)}
                                  </div>
                                </div>
                              ))
                            }
                          </>
                      }
                    </div>
                  ))
                }
              </div>
            </>
            :
            <>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src={img_empty} height={'25%'} />
                <div style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', marginTop: 15, marginBottom: 15 }}>Belum Ada Data</div>
                <div style={{ color: '#fff', fontSize: 12 }}>Data ringkasan <span style={{ color: '#ffeaa7' }}>kontraksi</span> tidak tersedia.</div>
                <div style={{ color: '#fff', fontSize: 12 }}>atau data yang Anda cari tidak ditemukan.</div>
              </div>
            </>
        }

        {
          isDetail &&
          <div
            style={{ position: 'absolute', bottom: 0, width: '100%', padding: 10, textAlign: 'center', cursor: 'pointer', color: '#fff', fontWeight: 'bold' }}
            className={`background-light-theme-${theme} ripple`}
            onClick={() => {
              setErrMessage('Mohon maaf. Untuk saat ini, fitur download laporan belum tersedia.')
              setShowError(true)
            }}
          >
            <i className="fa fa-download" style={{ fontSize: 14, color: '#fff', marginRight: 10 }}></i>
            <span>Laporan (pdf)</span>
          </div>
        }
      </div>

      <ContractionMyNoteInfo ref={contractionNoteInfoRef} colors={{}} />

      <DatePicker
        show={showDatePicker}
        setShow={setShowDatePicker}
        date={filterDate}
        setDate={async (selectedDate) => {
          setChartType('duration')
          setFilterDate(selectedDate)
          setDetailTree([{
            id: '',
            start_date: selectedDate,
            end_date: '',
            field_label: moment(selectedDate).format("ll")
          }])
          await getData('', selectedDate, '', moment(selectedDate).format("ll"))
        }}
        header={'Riwayat Kontraksi Bunda'}
      />

      <Modal
        show={showBSheet}
        onHide={() => setShowBSheet(false)}
        animation={false}
        className="modal-bt-sheet"
      >
        <div
          className={`background-light-theme-${theme}`}
          style={{ borderRadius: 0, borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        >
          <Modal.Header
            className={`background-theme-${theme}`}
            style={{ fontWeight: 'bold', color: '#fff', justifyContent: 'center' }}
          >
            Lihat Riwayat Kontraksi
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 480, padding: 15 }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 20, paddingBottom: 20 }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                className='ripple'
                onClick={() => {
                  setChartType('duration')
                  setDetailTree([{
                    id: '',
                    start_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
                    end_date: '',
                    field_label: 'Kemarin'
                  }])
                  getData('', moment().subtract(1, 'days').format("YYYY-MM-DD"), '', 'Kemarin')
                  setShowBSheet(false)
                }}
              >
                <i className="far fa-calendar" style={{ fontSize: 28, color: '#fff' }}></i>
                <span style={{ fontSize: 12, color: '#fff', marginTop: 5 }}>Kemarin</span>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                className='ripple'
                onClick={() => {
                  setChartType('duration')
                  setDetailTree([{
                    id: '',
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: '',
                    field_label: 'Hari Ini'
                  }])
                  getData('', moment().format('YYYY-MM-DD'), '', 'Hari Ini')
                  setShowBSheet(false)
                }}
              >
                <i className="far fa-calendar-day" style={{ fontSize: 28, color: '#fff' }}></i>
                <span style={{ fontSize: 12, color: '#fff', marginTop: 5 }}>Hari Ini</span>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                className='ripple'
                onClick={() => {
                  setChartType('duration')
                  let currentDate = moment()
                  setDetailTree([{
                    id: '',
                    start_date: currentDate.clone().startOf('isoWeek').format("YYYY-MM-DD"),
                    end_date: currentDate.clone().endOf('isoWeek').format("YYYY-MM-DD"),
                    field_label: 'Minggu Ini'
                  }])
                  getData('', currentDate.clone().startOf('isoWeek').format("YYYY-MM-DD"), currentDate.clone().endOf('isoWeek').format("YYYY-MM-DD"), 'Minggu Ini')
                  setShowBSheet(false)
                }}
              >
                <i className="far fa-calendar-week" style={{ fontSize: 28, color: '#fff' }}></i>
                <span style={{ fontSize: 12, color: '#fff', marginTop: 5 }}>Minggu Ini</span>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                className='ripple'
                onClick={() => {
                  setChartType('duration')
                  let month = moment().format('MM')
                  let year = moment().format('YYYY')

                  let dateCount = new Date(year, month, 0).getDate()
                  let startDate = ""
                  let endDate = ""

                  for (let index = 1; index <= dateCount; index++) {
                    let date = index > 9 ? index : `0${index}`
                    startDate = `${year}-${month}-01`
                    endDate = `${year}-${month}-${date.toString()}`
                  }

                  setDetailTree([{
                    id: '',
                    start_date: startDate,
                    end_date: endDate,
                    field_label: 'Bulan Ini'
                  }])

                  getData('', startDate, endDate, 'Bulan Ini')
                  setShowBSheet(false)
                }}
              >
                <i className="far fa-calendar-alt" style={{ fontSize: 28, color: '#fff' }}></i>
                <span style={{ fontSize: 12, color: '#fff', marginTop: 5 }}>Bulan Ini</span>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {isLoading && <Loading title={loadingMessage} />}

      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto',
        bottom: 0
      }} open={showError} autoHideDuration={3000} onClose={() => {
        setErrMessage('')
        setErrType("warning")
        setShowError(false)
      }}>
        <Alert onClose={() => {
          setErrMessage('')
          setErrType("warning")
          setShowError(false)
        }} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>

    </div >
  )
}
