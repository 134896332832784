import React, { Component, useEffect, useState } from "react"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal"
import ApiHelpers from "../../helpers/ApiHelpers"
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { useTheme } from "../../hooks/useTheme"


export default function UpdateAccount (props) {
    const {theme} = useTheme()

    const [isShowModal, setIsShowModal] = useState(false)
    const [rawData, setRawData] = useState(null)
    const [inputName, setInputName] = useState("")
    const [inputPhone, setInputPhone] = useState("")
    const [showError, setShowError] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [errType, setErrType] = useState('warning')
    const [loadingMessage, setIsLoadingMessage] = useState('warning')

    const history = useHistory()

    const onShowModal = (data) => {

        setTimeout(() => {
            setIsShowModal(true)
            setRawData(data)
            setInputName(data.name)
            setInputPhone(data.mobilephone)
        }, 1)
    }

    useEffect(() => {
        props.refUpdateAccount.current = onShowModal;
    
        return () => {
          props.refUpdateAccount.current = null;
        };
      });

    const onCloseModal = () => { setIsShowModal(false) }
    const onSetIsLogin = async (isLogin) => {
        setIsLoadingMessage("Menyimpan sesi login Anda...")
        // setIsLoadingMessage("Menyimpan sesi login Anda...")
        const newData = {
          ...rawData, 
          name: inputName,
          mobilephone: inputPhone
        }
        return new Promise((resolve) => {
            let result = false
            localStorage.setItem('user_session', JSON.stringify(newData))
            let auth = JSON.parse(localStorage.getItem('user_session'))
            if (auth !== null && auth.id !== undefined && auth.id !== "") {
                result = isLogin
            }
            onCloseModal()
            props.onUpdateData(newData)
            return resolve(result)
        })
    }
    const onUpdateAccountListeners = async () => {
        setShowError(false)
        let valid = false
        let errMessage = ""

        if (inputName === "" && inputPhone === "") {
            valid = false
            errMessage = "Anda belum menuliskan nama & no handphone"
        } else if (inputName === "") {
            valid = false
            errMessage = "Anda belum menuliskan nama Anda."
        } else {
            if (inputPhone === "") {
                valid = false
                errMessage = "Anda belum menuliskan no handphone Anda."
            } else {
                valid = true
                errMessage = "Ok."
            }
        }

        if (!valid) {
            setErrMessage(errMessage)
            setShowError(true)
        }


        if (valid) {
            // setIsLoading(true)
            // setIsLoadingMessage("Proses login...")

            let response = await ApiHelpers.post("", "account/profile/update", {
              "name": inputName !== "" ? inputName : rawData.name,
              "username": rawData.username,
              "image_path": rawData.image_path,
              "image": rawData.image,
              "mobilephone": inputPhone !== "" ? inputPhone : rawData.mobilephone,
              "profile_object": rawData.profile_object,
            })

            // setIsLoadingMessage("Mengecek akun login Anda...")
            if (response.status === 200) {
                let isLogged = await onSetIsLogin(true)
                if (isLogged) {
                  
                } else {
                    setTimeout(() => {
                        setErrMessage("Gagal mengubah akun Anda.")
                        setErrType("error")
                        setShowError(true)
                    }, 1500)
                }
            } else {
                setErrMessage(response.message)
                setErrType("error")
                setShowError(true)
            }
        } else {
            setErrMessage(errMessage)
            setErrType("warning")
            setShowError(true)

        }
    }

    const onCloseAlert = () => {
        setErrMessage('')
        setErrType("warning")
        setShowError(false)
    }



    const renderToolbar = () => {
        return (
            <Modal.Header style={{
                width: '100%',
                height: 50,
                flexDirection: 'row',
                // backgroundColor: "#950082",
                borderRadius: '15px 15px 0px 0px',
                alignItems: 'center', justifyContent: 'center',
                paddingHorizontal: 20,
            }} className={`background-light-theme-${theme}`}>
                <i class="fas fa-address-card" style={{ color: "#fff" }}></i>
                <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Ubah Profil</div>
                <i className="fas fa-times icon" style={{ color: "#fff" }} onClick={() => {
                    onCloseModal()
                }}></i>
            </Modal.Header>
        )
    }
    const renderFooter = () => {
        return (
            <Modal.Footer style={{
                height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row', 
                // backgroundColor: "#D400B8",
                borderRadius: '0px 0px 15px 15px',
            }} className={`background-light-theme-${theme}`}>
                <div style={{
                    borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
                    alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
                }}
                    onClick={() => {
                        onCloseModal()
                    }}>
                    <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
                </div>
                <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                    <div style={{
                        borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                        alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'row',
                        display: 'flex', cursor: 'pointer'
                    }}
                        onClick={() => { onUpdateAccountListeners() }}>
                        {/* <Icons name={'check-bold'} color={"#fff"} size={14}/> */}
                        <i className="fas fa-check icon" style={{ color: '#fff', fontSize: 14 }}></i>
                        <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Simpan Perubahan</div>
                    </div>
                </div>
            </Modal.Footer>
        )
    }
    const renderContents = () => {
        return (
            <Modal.Body style={{ 
                // backgroundColor: "#D400B8" 
                }} className={`background-theme-${theme}`}>
                <div style={{ alignItems: "center" }}>
                    <div style={{
                        paddingHorizontal: 15,
                        height: 40,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 40,
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 20
                    }}>
                        <i className="far fa-user-circle icon" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
                        <input
                            value={inputName}
                            onChange={(event) => setInputName(event.target.value)}
                            type="text"
                            onSubmit={() => { onUpdateAccountListeners() }}
                            className="form-control no-border"
                            placeholder="Nama"
                            defaultValue=""
                            style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
                        />

                    </div>
                </div>

                <div style={{ alignItems: "center" }}>
                    <div style={{
                        paddingHorizontal: 15,
                        height: 40,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 40,
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 20
                    }}>
                        <i className="fas fa-mobile-android-alt" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
                        <input
                            value={inputPhone}
                            onChange={(event) => setInputPhone(event.target.value)}
                            className="form-control no-border"
                            placeholder="No. Telephone"
                            type="number"
                            defaultValue=""
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    onUpdateAccountListeners()
                                }
                            }}

                            style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
                        />

                    </div>
                </div>
            </Modal.Body>
        )
    }
    return (
        <div>
            <Modal animationType="fade" show={isShowModal} transparent centered>
                {/* <div style={{flex:1, backgroundColor:'rgba(0,0,0,0.8)', alignItems:'center', justifyContent:'center'}}>
                    <div style={{width:'100%', borderRadius:10, backgroundColor:"#D400B8", overflow:'hidden'}}> */}
                {renderToolbar()}
                {renderContents()}
                {renderFooter()}
                {/* </div>
                </div> */}
            </Modal>

            <Snackbar style={{
                position: 'absolute',
                width: '100%',
                left: 'auto',
                right: 'auto'
            }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
                <Alert onClose={onCloseAlert} severity={errType}
                    sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
                    {errMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}