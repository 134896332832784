export const NavbarItems = [
    {
        title:"Beranda",
        path:"/dashboard",
        icon:"fas fa-home",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Kasir POS",
        path:"/pos",
        icon:"fas fa-cash-register",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Close Register",
        path:"/closeregister",
        icon:"fas fa-money-bill",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Pengeluaran",
        path:"/expense",
        icon:"fas fa-wallet",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Transaksi",
        path:"/transaction",
        icon:"fas fa-file-alt",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Laporan",
        path:"/report",
        icon:"fas fa-chart-bar",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Pengaturan",
        path:"",
        icon:"",
        class:"nav-section",
        type:"menu-sections",
        role:""
    },
    {
        title:"Panduan",
        path:"/guide",
        icon:"fas fa-book-open",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Pelanggan",
        path:"/customer",
        icon:"fas fa-users",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Produk & Layanan",
        path:"/services",
        icon:"fas fa-box",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Toko & Pengguna",
        path:"/store",
        icon:"fas fa-store",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Promo",
        path:"/promo",
        icon:"fas fa-percent",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Profil Saya",
        path:"/account",
        icon:"fas fa-user-alt",
        class:"nav-text",
        type:"menu-item",
        role:""
    },
    {
        title:"Sign Out",
        path:"#",
        icon:"fas fa-power-off",
        class:"nav-logout",
        type:"menu-logout",
        role:""
    }
]