import React, { Component, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"
import MyHelpers from "../../helpers/MyHelpers"
import { useTheme } from './../../hooks/useTheme';


export default function AccountSubscriptionsDetail(props) {

  const { theme } = useTheme()

  const [isShowModal, setIsShowModal] = useState(false)
  const [objectDetail, setObjectDetail] = useState(null)

  const history = useHistory()

  useEffect(() => {
    props.refAccountSubscriptionsDetail.current = onShowModal;

    return () => {
      props.refAccountSubscriptionsDetail.current = null;
    };
  });

  const onShowModal = (data) => {
    setObjectDetail(data)
    setTimeout(() => {
      setIsShowModal(true)
    }, 1)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  const onCloseModal = () => { setIsShowModal(false) }



  const renderToolbar = () => {
    return (
      <Modal.Header style={{
        width: '100%',
        height: 50,
        flexDirection: 'row',
        // backgroundColor: "#950082",
        borderRadius: '15px 15px 0px 0px',
        alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 20,
      }} className={`background-light-theme-${theme}`}>
        {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
        <i class="fas fa-info-circle" style={{ color: "#fff" }}></i>
        <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Detail Langganan</div>
        <i className="fas fa-times icon ripple" style={{ color: "#fff", cursor: 'pointer' }} onClick={() => { onCloseModal() }}></i>
      </Modal.Header>
    )
  }
  const renderContents = () => {
    if (objectDetail !== null && objectDetail.id !== undefined) {
      return (
        <Modal.Body style={{
          // backgroundColor: "#D400B8",
          borderRadius: '0px 0px 15px 15px',
        }} className={`background-theme-${theme}`}>
          <div style={{ textAlign: "left", padding: 15, borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              Nama Paket
            </div>
            <div style={{ fontSize: 16, color: '#fff' }}>{objectDetail.package_name}</div>
          </div>
          <div style={{ textAlign: "left", padding: 15, borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              <div style={{ fontSize: 14, color: "rgba(255,255,255,0.9)" }}>Harga Paket <div style={{ fontStyle: 'italic' }}>{objectDetail.special_price_bool && "(Harga Spesial)"}</div></div>
            </div>
            <div style={{ marginTop: 5, flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
              <div numberOfLines={1} style={{
                fontSize: objectDetail.discount_value === "0" ? 15 : 14,
                marginRight: objectDetail.discount_value === "0" ? 0 : 10,
                color: objectDetail.discount_value === "0" ? "#55efc4" : "#FFF",
                textDecorationLine: objectDetail.discount_value === "0" ? "none" : "line-through",
                fontWeight: 'bold'
              }}>
                {MyHelpers.formatNumber(objectDetail.price, 'idr')}
              </div>
              {objectDetail.discount_value !== "0" &&
                <div numberOfLines={1} style={{ fontSize: 15, color: '#55efc4', fontWeight: 'bold', display: "flex" }}>
                  {MyHelpers.formatNumber(Number(objectDetail.price - objectDetail.discount_value), 'idr')} <div style={{ fontSize: 15, marginLeft: 4 }}>(-{MyHelpers.formatNumber(objectDetail.discount_percent, '')}%)</div>
                </div>
              }
            </div>
          </div>
          <div style={{ textAlign: "left", padding: 15, borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,0.9)' }}>
              Deskripsi
            </div>
            <div style={{ fontSize: 16, color: '#fff' }}>{objectDetail.package_desc}</div>
          </div>
        </Modal.Body>
      )
    }
  }
  return (
    <div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        {renderToolbar()}
        {renderContents()}
      </Modal>
    </div>
  )
}