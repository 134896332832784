import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import ApiHelpers from "../../helpers/ApiHelpers";
import gif_loading_default from "../../assets/animations/gif_loading_default.gif";
import { useTheme } from "./../../hooks/useTheme";
const width = 400;
const height = 400;
const WIDTH_ICON_CONTAINER = (width - 100) / 5;
const WIDTH_ICON = WIDTH_ICON_CONTAINER + 20;
const WIDTH_ICON_IMAGE = WIDTH_ICON_CONTAINER * 0.8;
const ICON_DEF =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png";
const URL_GET_OPTION = "actions/options";

const ContractionMyNote = forwardRef(({ colors, onSubmit }, ref) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState("");
  const [arrMoods, setArrMoods] = useState([]);
  const [arrContractionTreatment, setArrContractionTreatment] = useState([]);
  const [arrFetalMovement, setArrFetalMovement] = useState([]);
  const [arrSignsAndSymptoms, setArrSignsAndSymptoms] = useState([]);
  const [arrLifeStyle, setArrLifeStyle] = useState([]);
  const [arrRupturedMembranes, setArrRupturedMembranes] = useState([]);
  const [arrPhysicalCondition, setArrPhysicalCondition] = useState([]);
  const [objData, setObjData] = useState({
    medicine_consumed: "",
    moods: [],
    contraction_treatment: [],
    contraction_treatment_other: "",
    fetal_movement: { value: "", icon: "" },
    signs_and_symptoms: [],
    life_style: [],
    ruptured_membranes: [],
    physical_condition: [],
  });
  const { theme, backgroundImageTheme, changeTheme, changeBackground } =
    useTheme();
  useImperativeHandle(ref, () => ({
    onShowModal(index, data) {
      onShowModal(index, data);
    },
  }));

  const onShowModal = (state, index) => {
    if (!state) {
      setIsLoading(false);
      setArrMoods([]);
      setArrContractionTreatment([]);
      setArrFetalMovement([]);
      setArrSignsAndSymptoms([]);
      setArrLifeStyle([]);
      setArrRupturedMembranes([]);
      setArrPhysicalCondition([]);
      setObjData({
        medicine_consumed: "",
        moods: [],
        contraction_treatment: [],
        contraction_treatment_other: "",
        fetal_movement: { value: "", icon: "" },
        signs_and_symptoms: [],
        life_style: [],
        ruptured_membranes: [],
        physical_condition: [],
      });
    } else {
      setIsLoading(true);
      setIndex(index);
      getMoods();
    }

    setTimeout(() => {
      setIsShowModal(state);
    }, 1);
  };

  const getMoods = async () => {
    // MOODS
    let arrMoods = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "moods",
    });
    // console.log("moods", JSON.stringify(response));
    if (response.status === 200 && response.results.data.length > 0) {
      response.results.data.map((item) => {
        arrMoods.push({
          value: item.value,
          icon: item.icon,
          is_selected: false,
        });
      });
    }
    setArrMoods(arrMoods);
    getContractionTreatment();
  };

  const getContractionTreatment = async () => {
    // TREATMENT SELAMA KONTRAKSI
    let arrContractionTreatment = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "contraction_treatment",
    });
    // console.log("contraction_treatment", JSON.stringify(response));
    if (response.status === 200 && response.results.data.length > 0) {
      // arrContractionTreatment = response.results.data
      response.results.data.map((item) => {
        arrContractionTreatment.push({
          id: item.id,
          value: item.value,
          input_bool: item.input_bool,
          icon: item.icon,
          array_bool: item.array_bool,
          item_array: item.item_array,
          is_selected: false,
        });
      });
    }
    setArrContractionTreatment(arrContractionTreatment);
    getFetalMovement();
  };

  const getFetalMovement = async () => {
    // GERAKAN JANIN
    let arrFetalMovement = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "fetal_movement",
    });
    // console.log('fetal_movement', JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      arrFetalMovement = response.results.data;
    }
    setArrFetalMovement(arrFetalMovement);
    getSignsAndSymptoms();
  };
  // getSignsAndSymptoms => DONE
  const getSignsAndSymptoms = async () => {
    // TANDA ATAU GEJALA
    let arrSignsAndSymptoms = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "signs_and_symptoms",
    });
    // console.log('signs_and_symptoms', JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      arrSignsAndSymptoms = response.results.data;
      let newObjData = objData;
      arrSignsAndSymptoms.map((item) => {
        if (item.input_bool) {
          newObjData.signs_and_symptoms.push({
            value: item.value,
            icon: item.icon,
            type: "input",
            input: "",
          });
        } else {
          if (item.array_bool) {
            newObjData.signs_and_symptoms.push({
              value: item.value,
              icon: item.icon,
              type: "array",
              item_array: item.item_array,
            });
          }
        }
      });
      // console.log(JSON.stringify(objData.signs_and_symptoms))
      setObjData(newObjData);
    }
    setArrSignsAndSymptoms(arrSignsAndSymptoms);
    getLifeStyle();
  };
  // getLifeStyle => DONE
  const getLifeStyle = async () => {
    // LIFE STYLE
    let arrLifeStyle = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "life_style",
    });
    // console.log('life_style', JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      arrLifeStyle = response.results.data;
      let newObjData = objData;
      arrLifeStyle.map((item) => {
        if (item.input_bool) {
          newObjData.life_style.push({
            value: item.value,
            icon: item.icon,
            type: "input",
            input: "",
          });
        } else {
          if (item.array_bool) {
            newObjData.life_style.push({
              value: item.value,
              icon: item.icon,
              type: "array",
              item_array: item.item_array,
            });
          }
        }
      });
      // console.log(JSON.stringify(objData.life_style))
      setObjData(newObjData);
    }
    setArrLifeStyle(arrLifeStyle);
    getRupturedMembranes();
  };
  // getRupturedMembranes => DONE
  const getRupturedMembranes = async () => {
    // MEMBRANE
    let arrRupturedMembranes = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "ruptured_membranes",
    });
    // console.log('ruptured_membranes', JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      arrRupturedMembranes = response.results.data;
      let newObjData = objData;
      arrRupturedMembranes.map((item) => {
        if (item.input_bool) {
          newObjData.ruptured_membranes.push({
            value: item.value,
            icon: item.icon,
            type: "input",
            input: "",
          });
        } else {
          newObjData.ruptured_membranes.push({
            value: item.value,
            icon: item.icon,
            type: "array",
            item_array: item.item_array,
          });
        }
      });
      setObjData(newObjData);
    }
    setArrRupturedMembranes(arrRupturedMembranes);
    getPhysicalCondition();
  };
  // getPhysicalCondition => DONE
  const getPhysicalCondition = async () => {
    // PHYSICAL CONDITION
    let arrPhysicalCondition = [];
    let response = await ApiHelpers.get("", URL_GET_OPTION, {
      type: "physical_condition",
    });
    // console.log('physical_condition', JSON.stringify(response))
    if (response.status === 200 && response.results.data.length > 0) {
      arrPhysicalCondition = response.results.data;
      let newObjData = objData;
      arrPhysicalCondition.map((item) => {
        if (item.input_bool) {
          newObjData.physical_condition.push({
            value: item.value,
            icon: item.icon,
            type: "input",
            input: "",
          });
        } else {
          newObjData.physical_condition.push({
            value: item.value,
            icon: item.icon,
            type: "array",
            item_array: item.item_array,
          });
        }
      });
      setObjData(newObjData);
    }
    setArrPhysicalCondition(arrPhysicalCondition);
    setIsLoading(false);
  };

  const onTextChanged = (params, value) => {
    if (params !== "") {
      setText(value);
      let newObjData = objData;
      newObjData[params] = value;
      // if(evt.length > 0) {
      //     if(params === "contraction_treatment_other") {
      //         dataObj.contraction_treatment = ""
      //     }
      // }
      setObjData(newObjData);
    }
  };
  const onTextinput = (objParams, index, inputParams, value) => {
    setText(value);
    if (objParams !== "" && inputParams !== "") {
      let newObjData = objData;
      newObjData[objParams][index][inputParams] = value;
      setObjData(newObjData);
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const onSelectedIcons = (params, data, idx = "", selIdx = "") => {
    if (data == selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(data);
    }
    
    if (params !== "" && data.value !== "") {
      let newArrMoods = arrMoods;
      let newArrContractionTreatment = arrContractionTreatment;
      let newObjData = objData;
      if (params === "moods") {
        if (idx !== "") {
          // let newArrMoods = arrMoods;
          let is_selected = newArrMoods[idx].is_selected;
          if (is_selected) {
            newObjData.moods = newObjData.moods.filter((item) => item.value !== data.value)
          } else {
            newObjData.moods.push({
              value: data.value,
              icon: data.icon,
            });
          }
          newArrMoods[idx].is_selected = !is_selected;
          setArrMoods(newArrMoods);
        }
      } else {
        if (params === "contraction_treatment") {
          if (idx !== "") {
            let newArrContractionTreatment = arrContractionTreatment;
            let is_selected = newArrContractionTreatment[idx].is_selected;
            if (is_selected) {
              newObjData.contraction_treatment =
                newObjData.contraction_treatment.filter(
                  (item) => item.value !== data.value
                );
            } else {
              newObjData.contraction_treatment.push({
                value: data.value,
                icon: data.icon,
              });
            }
            newArrContractionTreatment[idx].is_selected = !is_selected;
            setArrContractionTreatment(newArrContractionTreatment);
          }
        } else {
          if (
            params === "ruptured_membranes" ||
            params === "physical_condition" ||
            params === "signs_and_symptoms" ||
            params === "life_style"
          ) {
            let item_array = newObjData[params][idx].item_array;
            let new_item_array = [];
            item_array.map((item, key) => {
              new_item_array.push({
                icon: item.icon,
                value: item.value,
                is_selected: key === selIdx ? true : false,
              });
            });
            newObjData[params][idx].item_array = new_item_array;
          } else {
            newObjData[params].value = data.value;
            newObjData[params].icon = data.icon;
          }
        }
      }
      setObjData(newObjData);
    }
  };

  const [text, setText] = useState("");

  const renderContent = () => {
    const selected = selectedItem;
    const settext = text;
    // ^ untuk re-render halaman
    let newObjData = objData;
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 10,
          height: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}
      >
        {/* === OBAT YANG DIKONSUMSI */}
        <div style={{ ...styles.inputContainer, marginTop: 20 }}>
          <div
            style={{
              ...styles.inputLabel,
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            OBAT YANG DIKONSUMSI
          </div>
          <div>
            <input
              value={objData.medicine_consumed}
              onChange={(event) =>
                onTextChanged("medicine_consumed", event.target.value)
              }
              type="text"
              // onSubmit={() => { onLoginBasicListeners() }}
              className="form-control no-border"
              placeholder="Tuliskan disini"
              maxLength={100}
              defaultValue=""
              style={{
                paddingLeft: 15,
                flex: 1,
                fontSize: 16,
                color: "#fff",
                fontWeight: "bold",
                backgroundColor: "transparent",
                "::placeholder": "rgba(255,255,255,0.15)",
              }}
            />
          </div>

          <div style={styles.divider} />
          <div style={styles.inputNote}>
            <div style={styles.inputOptional}>Opsional</div>
            <div style={styles.inputLengthInfo}>
              <div
                style={{
                  fontWeight: "bold",
                  color: '#FFF',
                }}
              >
                {objData.medicine_consumed.length}
              </div>
              &nbsp;dari 100 karakter
            </div>
          </div>
        </div>

        {/* === MOODS */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: 10 }}>
              MOODS
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderMoods()}
        </div>

        {/* === TREATMENT */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: 10 }}>
              Treatment selama kontraksi
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderContractionTreatment()}
          {!isLoading && arrContractionTreatment.length > 0 && (
            <>
              <div
                style={{
                  ...styles.inputLabel,

                  textTransform: "capitalize",
                  marginTop: 15,
                  marginBottom: 10,
                }}
              >
                Atau Treatment Lainnya{" "}
                <div style={{ fontStyle: "italic", fontSize: 12, marginTop: 3 }}>
                  (jika tidak ada pilihan diatas)
                </div>
              </div>
              <div>
                <input
                  value={objData.contraction_treatment_other}
                  onChange={(event) =>
                    onTextChanged(
                      "contraction_treatment_other",
                      event.target.value
                    )
                  }
                  type="text"
                  // onSubmit={() => { onLoginBasicListeners() }}
                  className="form-control no-border"
                  placeholder="Tuliskan disini"
                  maxLength={100}
                  defaultValue=""
                  style={{
                    paddingLeft: 15,
                    flex: 1,
                    fontSize: 16,
                    color: "#fff",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    "::placeholder": "rgba(255,255,255,0.15)",
                  }}
                />
              </div>

              <div style={{ ...styles.divider }} />
              <div
                style={{
                  ...styles.inputNote,
                }}
              >
                <div style={styles.inputOptional}>Jika Ada</div>
                <div style={styles.inputLengthInfo}>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: '#FFF',
                    }}
                  >
                    {objData.contraction_treatment_other.length}
                  </div>{" "}
                  dari 100 karakter
                </div>
              </div>
            </>
          )}
        </div>

        {/* === GERAKAN JANIN */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: 10 }}>
              GERAKAN JANIN
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderFetalMovement()}
        </div>

        {/* === TANDA ATAU GEJALA */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: -5 }}>
              TANDA ATAU GEJALA
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderSignsAndSymptoms()}
        </div>

        {/* === LIFESTYLE */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: -5 }}>
              Lifestyle
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderLifestyle()}
        </div>

        {/* === STATUS KETUBAN */}
        <div
          style={{
            ...styles.inputContainer,
            marginTop: 20,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: -5 }}>
              STATUS KETUBAN
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderRupturedMembranes()}
        </div>

        {/* === PEMERIKSAAN FISIK */}
        <div
          style={{
            ...styles.inputContainer,

            marginTop: 20,
            borderBottomWidth: 0,
            paddingBottom: 20,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ ...styles.inputLabel, fontWeight: "bold", marginBottom: -5 }}>
              PEMERIKSAAN FISIK
            </div>
            {isLoading && renderLoading()}
          </div>
          {renderPhysicalCondition()}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    if (!isLoading) {
      return (
        <div
          style={{
            paddingBottom: 5,
            paddingTop: 5,
            display: "flex",
            flexDirection: "row",
            backgroundColor: "rgb(255 255 255 / 27%)",
            borderRadius: '0px 0px 12px 12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={onSubmitListeners}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 25,
              paddingRight: 25,
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 20,
            }}
          >
            <i className="fa fa-check" style={{ color: "white" }} />
            <div
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: "white",
                marginLeft: 5,
              }}
            >
              Tambahkan Catatan
            </div>
          </div>
        </div>
      );
    }
  };

  const onSubmitListeners = () => {
    let newObjData = objData;
    onSubmit(index - 1, newObjData);
    onShowModal(false);
  };

  const renderMoods = () => {
    let arrData = arrMoods;
    
    if (isLoading) {
      return renderIconsLoading();
    } else {
      
      
      if (arrData.length > 0) {
        return (
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              overflowY: "hidden",
              alignItems: 'start'
            }}
          >
            {arrData.map((item, key) => {
              return renderIcons(item, key, "moods", item.is_selected);
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("moods");
      }
    }
  };
  const renderContractionTreatment = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let arrData = arrContractionTreatment;
      if (arrData.length > 0) {
        // let objData = objData
        // let selected = ""
        // if(objData.contraction_treatment !== "") {
        //     selected = objData.contraction_treatment
        // }
        return (
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            {arrData.map((item, key) => {
              return renderIcons(
                item,
                key,
                "contraction_treatment",
                item.is_selected
              );
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("treatment");
      }
    }
  };
  const renderFetalMovement = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let arrData = arrFetalMovement;
      if (arrData.length > 0) {
        let newObjData = objData;
        let selected = "";
        if (newObjData.fetal_movement !== "") {
          selected = newObjData.fetal_movement;
        }
        return (
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              flex: 1,
              overflowY: "hidden",
            }}
          >
            {arrData.map((item, key) => {
              return renderIcons(item, key, "fetal_movement", selected);
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("gerakan janin");
      }
    }
  };
  const renderSignsAndSymptoms = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let newObjData = objData;
      let arrData = newObjData.signs_and_symptoms;
      if (arrData.length > 0) {
        return (
          <div style={{ marginTop: 5, paddingBottom: 20 }}>
            {arrData.map((item, key) => {
              return (
                <div key={key} style={{ marginTop: 20 }}>
                  <div
                    style={{
                      ...styles.inputLabel,
                      textTransform: "capitalize",
                      marginBottom: 6
                    }}
                  >
                    {item.value}
                  </div>
                  {item.type === "input" &&
                    renderInput(item, "signs_and_symptoms", key, "input")}
                  {item.type === "array" && (
                    <div style={{ display: "flex", flex: 1, overflowX: "scroll",
                    overflowY: "hidden",  }}>
                      {renderIcons(item, key, "signs_and_symptoms", "")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("tanda atau gejala");
      }
    }
  };
  const renderLifestyle = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let newObjData = objData;
      let arrData = newObjData.life_style;
      if (arrData.length > 0) {
        return (
          <div style={{ marginTop: 5, paddingBottom: 20, }}>
            {arrData.map((item, key) => {
              let sufiks = "";
              let isMultiline = false;
              if (item.value === "Lama Tidur") {
                sufiks = "menit/jam";
              }
              if (item.value === "Tensi") {
                sufiks = "mmHg";
              }
              if (item.value === "Nadi") {
                sufiks = "x/mnt";
              }
              if (item.value === "Suhu Tubuh") {
                sufiks = "C";
              }
              if (item.value === "Makan / Cemilan") {
                isMultiline = true;
              }
              return (
                <div key={key} style={{ marginTop: 20 }}>
                  <div
                    style={{
                      ...styles.inputLabel,
                      textTransform: "capitalize",
                      marginBottom: 6
                    }}
                  >
                    {item.value}
                  </div>
                  {item.type === "input" &&
                    renderInput(
                      item,
                      "life_style",
                      key,
                      "input",
                      sufiks,
                      isMultiline
                    )}
                  {item.type === "array" && (
                    <div style={{ display: "flex", overflowX: "scroll",
                    overflowY: "hidden", flex: 1,  }}>
                      {renderIcons(item, key, "life_style", "")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("lifestyle");
      }
    }
  };
  const renderRupturedMembranes = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let newObjData = objData;
      let arrData = newObjData.ruptured_membranes;
      if (arrData.length > 0) {
        return (
          <div style={{ marginTop: 5, paddingBottom: 20, }}>
            {arrData.map((item, key) => {
              return (
                <div key={key} style={{ marginTop: 20 }}>
                  <div
                    style={{
                      ...styles.inputLabel,
                      textTransform: "capitalize",
                      marginBottom: 6
                    }}
                  >
                    {item.value}
                  </div>
                  {item.type === "input" &&
                    renderInput(item, "ruptured_membranes", key, "input")}
                  {item.type === "array" && (
                    <div style={{ display: "flex", overflowX: "scroll",
                    overflowY: "hidden", flex: 1, }}>
                      {renderIcons(item, key, "ruptured_membranes", "")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("ketuban pecah");
      }
    }
  };
  const renderPhysicalCondition = () => {
    if (isLoading) {
      return renderIconsLoading();
    } else {
      let newObjData = objData;
      let arrData = newObjData.physical_condition;
      if (arrData.length > 0) {
        return (
          <div style={{ marginTop: 5, paddingBottom: 20 }}>
            {arrData.map((item, key) => {
              let sufiks = "";
              if (item.value === "Pembukaan") {
                sufiks = "cm";
              }
              return (
                <div key={key} style={{ marginTop: 20 }}>
                  <div
                    style={{
                      ...styles.inputLabel,
                      textTransform: "capitalize",
                      marginBottom: 6
                    }}
                  >
                    {item.value}
                  </div>
                  {item.type === "input" &&
                    renderInput(
                      item,
                      "physical_condition",
                      key,
                      "input",
                      sufiks
                    )}
                  {item.type === "array" && (
                    <div style={{ display: "flex",
                    overflowX: "scroll",
                    overflowY: "hidden", flex: 1, }}>
                      {renderIcons(item, key, "physical_condition", "")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        return renderIconsEmpty("kondisi fisik");
      }
    }
  };

  // ====================================================================================
  // ========== DEFAULT RENDER SECTIONS =================================================
  // ====================================================================================
  const renderIconsEmpty = (title) => {
    return (
      <div
        style={{
          marginLeft: 15,
          marginTop: 10,
          marginBottom: 5,
          marginRight: 15,
        }}
      >
        <div style={{ fontSize: 12, color: '#FFF' }}>
          Data {title} tidak tersedia.
        </div>
      </div>
    );
  };

  const renderIconsLoading = () => {
    renderLoading();
  };

  const renderInput = (
    item,
    objParams,
    index,
    inputParams,
    sufiks = "",
    isMultiline = false
  ) => {
    return (
      <>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: isMultiline ? "flex-start" : "center",
            justifyContent: "center",
            marginTop: 0,
            marginLeft: -5,
          }}
        >
          {item.icon !== "" ? (
            <img
              src={item.icon}
              width={18}
              height={18}
              placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
            />
          ) : (
            <div
              style={{
                width: 18,
                height: 18,
                borderRadius: 18 / 2,
                backgroundColor: colors.backgroundLight,
                marginTop: isMultiline ? 5 : 0,
              }}
            />
          )}

          <input
            value={item.input}
            onChange={(event) => {
              onTextinput(objParams, index, inputParams, event.target.value);
            }}
            type="text"
            // onSubmit={() => { onLoginBasicListeners() }}
            className="form-control no-border"
            placeholder="Tuliskan disini"
            maxLength={100}
            defaultValue=""
            style={{
              paddingLeft: 15,
              flex: 1,
              fontSize: 16,
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "transparent",
              "::placeholder": "rgba(255,255,255,0.15)",
            }}
          />

          {sufiks !== "" && (
            <>
              {sufiks === "C" ? (
                <div
                  style={{
                    fontWeight: "bold",
                    color: '#FFF',
                    fontSize: 12,
                  }}
                >
                  &#176; {sufiks}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    color: '#FFF',
                    fontSize: 12,
                  }}
                >
                  {sufiks}
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ ...styles.divider, marginLeft: 15 }} />
        <div style={{ ...styles.inputNote }}>
          <div style={styles.inputOptional}>Opsional</div>
          <div style={styles.inputLengthInfo}>
            <div style={{ fontWeight: "bold", color: '#FFF' }}>
              {item.input.length}
            </div>{" "}
            dari {isMultiline ? 100 : 20} karakter
          </div>
        </div>
      </>
    );
  };
  const renderIcons = (item, key, params, selected) => {
  
    if (params === "moods" || params === "contraction_treatment") {
      return (
        <div
          key={key}
          style={{
            marginLeft: key > 0 ? 20 : 0,
            width: WIDTH_ICON,
            minWidth: WIDTH_ICON,
            height: 111,
            flexDirection: "column"
          }}
          onClick={() => {
            onSelectedIcons(params, item, key);
          }}
        >
          {selected ? (
            <div
              style={{
                backgroundColor: '#FFF',
                borderRadius: WIDTH_ICON_CONTAINER / 2,
                width: WIDTH_ICON_CONTAINER,
                height: WIDTH_ICON_CONTAINER,
                alignItems: "center",
                display: 'flex',
                justifyContent: "center",
                margin: 'auto'
              }}
            >
              <img
                src={item.icon}
                width={WIDTH_ICON_IMAGE}
                height={WIDTH_ICON_IMAGE}
                placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
              />
            </div>
          ) : (
            <div
              key={key}
              style={{
                width: WIDTH_ICON,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                colors={[colors.backgroundLight, colors.backgroundDark]}
                style={{
                  borderRadius: WIDTH_ICON_CONTAINER / 2,
                  width: WIDTH_ICON_CONTAINER,
                  height: WIDTH_ICON_CONTAINER,
                  alignItems: "center",
                  backgroundImage: 'linear-gradient(#ffffffbd, #ffffff14)',
                  opacity: 0.5,
                  display: 'flex',
                  justifyContent: "center",
                  margin: 'auto'
                }}
              >
                <img
                  src={item.icon}
                  width={WIDTH_ICON_IMAGE}
                  height={WIDTH_ICON_IMAGE}
                  placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                />
              </div>
            </div>
          )}
          <div
            numberOfLines={3}
            style={{
              marginTop: 5,
              fontSize: 12,
              color: selected ? '#FFF' : "rgba(255,255,255,0.7)",
              fontWeight: selected ? "bold" : "normal",
              textAlign: "center",
            }}
          >
            {item.value}
          </div>
          <div
            style={{
              marginTop: 7,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 5,
              height: 5,
              borderRadius: 5 / 2,
              backgroundColor: selected
                ? '#FFF'
                : colors.background,
            }}
          />
        </div>
      );
    } else {
      // if(params === "ruptured_membranes" && item.type === "array") {
      if (item.type === "array") {
        let item_array = [];
        if (item.item_array !== undefined && item.item_array.length > 0) {
          item_array = item.item_array;
        }
        if (item_array.length > 0) {
          // console.log(params)
          return item_array.map((item, index) => {
            let is_selected = false;
            if (item.is_selected !== undefined) {
              is_selected = item.is_selected;
            }
            return (
              <div
                key={index}
                style={{
                  marginLeft: index > 0 ? 15 : 0,
                  width: WIDTH_ICON,
                  alignItems: "center",
                }}
                onClick={() => {
                  onSelectedIcons(params, item, key, index);
                }}
              >
                {is_selected ? (
                  <div
                    style={{
                      backgroundColor: '#FFF',
                      borderRadius: WIDTH_ICON_CONTAINER / 2,
                      height: WIDTH_ICON_CONTAINER,
                      width: WIDTH_ICON_CONTAINER,
                      alignItems: "center",
                      display: 'flex',
                      justifyContent: "center",
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={item.icon !== "" ? item.icon : ICON_DEF}
                      width={WIDTH_ICON_IMAGE}
                      height={WIDTH_ICON_IMAGE}
                      placeholderStyle={{
                        backgroundColor: "rgba(0,0,0,0.0)",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    colors={[colors.backgroundLight, colors.backgroundDark]}
                    style={{
                      borderRadius: WIDTH_ICON_CONTAINER / 2,
                      backgroundImage: 'linear-gradient(#ffffffbd, #ffffff14)',
                      opacity: 0.5,
                      height: WIDTH_ICON_CONTAINER,
                      width: WIDTH_ICON_CONTAINER,
                      alignItems: "center",
                      display: 'flex',
                      justifyContent: "center",
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={item.icon !== "" ? item.icon : ICON_DEF}
                      width={WIDTH_ICON_IMAGE}
                      height={WIDTH_ICON_IMAGE}
                      placeholderStyle={{
                        backgroundColor: "rgba(0,0,0,0.0)",
                      }}
                    />
                  </div>
                )}
                <div
                  numberOfLines={3}
                  style={{
                    marginTop: 5,
                    fontSize: 12,
                    color: is_selected
                      ? '#FFF'
                      : "rgba(255,255,255,0.7)",
                    fontWeight: is_selected ? "bold" : "normal",
                    textAlign: "center",
                  }}
                >
                  {item.value}
                </div>
                <div
                  style={{
                    marginTop: 7,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: 5,
                    height: 5,
                    borderRadius: 5 / 2,
                    backgroundColor: is_selected
                      ? '#FFF'
                      : colors.background,
                  }}
                />
              </div>
            );
          });
        }
      } else {
        return (
          <div
            key={key}
            style={{
              marginLeft: key > 0 ? 15 : 0,
              width: WIDTH_ICON,
              alignItems: "center",
            }}
            onClick={() => {
              onSelectedIcons(params, item);
            }}
          >
            {selected.value === item.value ? (
              <div
                style={{
                  backgroundColor: '#FFF',
                  borderRadius: WIDTH_ICON_CONTAINER / 2,
                  height: WIDTH_ICON_CONTAINER,
                  width: WIDTH_ICON_CONTAINER,
                  alignItems: "center",
                  display: 'flex',
                  justifyContent: "center",
                  margin: 'auto'
                }}
              >
                <img
                  src={item.icon}
                  width={WIDTH_ICON_IMAGE}
                  height={WIDTH_ICON_IMAGE}
                  placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                />
              </div>
            ) : (
              <div
                colors={[colors.backgroundLight, colors.backgroundDark]}
                style={{
                  borderRadius: WIDTH_ICON_CONTAINER / 2,
                  // backgroundColor: "rgb(255 255 255 / 27%)",
                  backgroundImage: 'linear-gradient(#ffffffbd, #ffffff14)',
                  opacity: 0.5,
                  height: WIDTH_ICON_CONTAINER,
                  width: WIDTH_ICON_CONTAINER,
                  alignItems: "center",
                  display: 'flex',
                  justifyContent: "center",
                  margin: 'auto'
                }}
              >
                <img
                  src={item.icon}
                  width={WIDTH_ICON_IMAGE}
                  height={WIDTH_ICON_IMAGE}
                  style={{
                    resizeMode: "cover",
                    opacity: selected.value === item.value ? 1 : 0.2,
                  }}
                  placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                />
              </div>
            )}
            <div
              numberOfLines={3}
              style={{
                marginTop: 5,
                fontSize: 12,
                lineHeight: 1.3,
                color:
                  selected.value === item.value
                    ? '#FFF'
                    : "rgba(255,255,255,0.7)",
                fontWeight: selected.value === item.value ? "bold" : "normal",
                textAlign: "center",
              }}
            >
              {item.value}
            </div>
            <div
              style={{
                marginTop: 7,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 5,
                height: 5,
                borderRadius: 5 / 2,
                backgroundColor:
                  selected.value === item.value
                    ? '#FFF'
                    : colors.background,
              }}
            />
          </div>
        );
      }
    }
  };

  const renderLoading = () => {
    return (
      <img
        src={gif_loading_default}
        className="ComponentLoading-img"
        style={{ width: "24px", height: "24px" }}
      />
    );
  };

  return (
    <div
      onClick={() => {
        // closeModal()
      }}
    >
      <Modal animationType="fade" show={isShowModal} transparent centered>
        <div>
          <div
            className={`background-theme-${theme}`}
            style={{
              borderRadius: 10,
              // flex: 1,
              // backgroundColor: '#D400B8',
              // position: "relative",
              // "--background-themes": `url(${backgroundImageTheme})`,
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.2)",
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 20,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {`Tambah/Ubah Catatan Kontraksi #${index}`}
              </div>
              <i
                className="fas fa-times-circle mg-right10"
                style={{ color: "white" }}
                onClick={() => setIsShowModal(false)}
              />
            </div>
            <div>{renderContent()}</div>
            <div>{renderFooter()}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const styles = {
  container: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.7)",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: width * 0.95,
    height: height * 0.9,
    borderRadius: 20,
    overflow: "hidden",
  },
  inputContainer: {
    borderBottomColor: "rgba(0,0,0,0.03)",
    borderBottomWidth: 10,
    paddingBottom: 15,
  },
  inputLabel: {
    color: "rgba(255,255,255,1.0)",
    fontSize: 14,
    textTransform: "uppercase",
  },
  inputText: {
    flex: 1,
    fontSize: 16,
    color: "#fff",
    marginTop: 0,
    marginLeft: -5,
  },
  inputNote: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 5,
  },
  inputInfo: {
    flex: 1,
    fontSize: 16,
    color: "#fff",
    marginTop: 10,
  },
  inputRequired: { fontSize: 11, color: "#fab1a0" },
  inputOptional: { fontSize: 11, color: "#55efc4" },
  inputLengthInfo: { fontSize: 11, color: "#B5B5B5", display: "flex" },
  divider: {
    height: 1,
    backgroundColor: "rgba(216, 216, 216, 0.30)",
    marginTop: 0,
  },
};

export default ContractionMyNote;
