import React from "react"
import logo_bk from "../assets/BK/img_splash.png"
import gif_loading_default from "../assets/animations/gif_loading_default.gif"
import { useTheme } from './../hooks/useTheme';

 const Splash = () => {
	const { theme } = useTheme()

		return (
			<div className={`Splash background-theme-${theme}`}>
				<img src={logo_bk} className="Splash-img" alt="Bidan Kita" />
				<h2 className="Splash-title">Kontraksi Enak</h2>
				<div className="Splash-subtitle">
					Melacak kontraksi Anda dan menentukan kapan waktunya untuk pergi ke rumah sakit.
				</div>
				<img src={gif_loading_default} className="Splash-loading" alt="Bidan Kita" />
			</div>
		)
}

export default Splash