import { createContext, useReducer } from 'react'

export const ThemeContext = createContext()

const ThemeReducer = (state, payload) => {
  return {...state, ...payload}
}

export function ThemeProvider({ children }) {

  const [state, dispatch] = useReducer(ThemeReducer, { 
    theme: localStorage.getItem('ake_theme') ? localStorage.getItem('ake_theme') :  'default',
    backgroundImageTheme: localStorage.getItem('ake_background_theme') || localStorage.getItem('ake_background_theme') !== 'null' ? localStorage.getItem('ake_background_theme') : null,
  })

  const changeTheme = (theme) => {
    dispatch({theme})
  }

  const changeBackground = (backgroundImageTheme) => {
    dispatch({backgroundImageTheme})
  }

  return (
    <ThemeContext.Provider value={{ ...state, changeTheme, changeBackground }}>
      {children}
    </ThemeContext.Provider>
  )
}