import React, { useState, useRef, useCallback, useEffect } from "react"
import ApiHelpers from "../../helpers/ApiHelpers"
import Modal from "react-bootstrap/Modal"
import Header from "../../components/Header"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import Loading from "../../components/Loading"
import { useHistory, useParams } from "react-router-dom"
import img_payment_method from "../../assets/BK/img_payment_method_white.png"
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import Accordion from 'react-bootstrap/Accordion';
import { useTheme } from './../../hooks/useTheme';

export default function SubscriptionsCheckoutResult() {

  const { theme } = useTheme()

  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const history = useHistory()
  const { id } = useParams()


  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'transaction/detail', {
      "id": id
    })
    if (response.status === 200) {
      objData = response.results.data
      if (response.results.data.status === 'dibatalkan') {
        history.push('/subscriptions-history')
      }
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  useEffect(() => {
    onRefresh()
  }, [])

  const onPressPayWithGojek = (redirectUrl) => {
    // history.push(redirectUrl)
    window.location=redirectUrl
      // Linking.openURL(redirectUrl).catch(er => {
      //     return Alert.alert(
      //         `Bayar dengan Gopay`,
      //         `Tidak dapat membuka Aplikasi Gopay.`,
      //         [
      //             {text: 'Ok', onPress: () => {}},
      //         ],
      //         {cancelable: false},
      //     )
      // });
      // setTimeout(() => {
      //     this.props.navigation.goBack()
      // }, 100);
  }

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      // return (
      //   <div>
      //     <div style={{ marginBottom:10}}>
      //       <div style={{ marginBottom: 15 }}>
      //         <div style={{ paddingLeft: 18, paddingRight: 18, paddingTop:20, paddingBottom:10,
      //           borderBottom:"1px solid rgba(0,0,0,0.03)",
      //           borderBottomWidth:10,
      //           }}>
      //             dlkfjskljfklsdjfklsdjflskjfklsdfjkldfj
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // )
      if (fetchData !== null) {
        return (
          // <div style={{
          //     paddingLeft:15, paddingRight:15, borderBottomColor:'rgba(0,0,0,0.03)', borderBottomWidth:10, 
          //     flexDirection:'row', alignItems:'center', justifyContent:'space-between',
          //     paddingVertical:20, display:'flex',
          //     borderBottom:"1px solid rgba(0,0,0,0.03)",
          //     borderBottomWidth:10,
          //   }}>
          //   <div style={{fontSize:16, color:'#FFF'}}>Total Pembayaran </div>
          //   <div style={{fontSize:16, color:'#ffeaa7', fontWeight:'bold', marginRight:5}}>
          //       {MyHelpers.formatNumber(fetchData.grand_total, 'idr')}
          //   </div>
          // </div>
          // <div>
          <div>
            <div style={{ marginBottom: 10 }}>
              <div style={{ marginBottom: 15 }}>
                <div style={{
                  paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10,
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 10,
                }}>
                  <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ fontSize: 16, color: '#FFF' }}>Total Pembayaran </div>
                    <div style={{ fontSize: 16, color: '#ffeaa7', fontWeight: 'bold', marginRight: 5 }}>
                      {MyHelpers.formatNumber(fetchData.grand_total, 'idr')}
                    </div>
                  </div>
                </div>
                <div style={{
                  paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10,
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 10,
                }}>
                  {renderPaymentInformation()}
                </div>
                {/* <div style={{ paddingLeft: 18, paddingRight: 18, paddingTop:20, paddingBottom:10,
                  borderBottom:"1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth:10,
                  }}>
                  {renderPaymentInstruction()}
                </div> */}
                <div>
                  <div>
                    {
                      fetchData.payment_detail_object.instruction.map((accordion, index) => (
                        <Accordion defaultActiveKey={0}>
                          <Accordion.Item eventKey={index} style={{ background: 'transparent', padding: '0px 4px', boxShadow: 'none', marginBottom: 0, borderTop: 'none', borderBottom: '1px solid rgba(0,0,0,0.03)' }}>
                            <Accordion.Header style={{ background: 'transparent', padding: '0px 4px', boxShadow: 'none', marginBottom: 0 }}>{accordion.label}</Accordion.Header>
                            <Accordion.Body style={{
                              color: '#FFF'
                            }}>
                              {
                                accordion.value.map((item, key) => {
                                  return (
                                    <div key={key} style={{
                                      flexDirection: 'row', display: 'flex', alignItems: 'flex-start',
                                      marginBottom: 15,
                                    }}>
                                      <div style={{
                                        width: 18, height: 18, alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: 'rgba(0,0,0,0.25)', borderRadius: 18, display: 'flex', marginTop: 3
                                      }}>
                                        <div style={{ fontSize: 8, color: '#FFF' }}>{key + 1}</div>
                                      </div>
                                      <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                        <div style={{ fontSize: 14, color: '#FFF' }}>{item}</div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: "center", minHeight: "calc(100vh - 60px)", display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <img src={img_payment_method}
                style={{ width: 150, height: 150, resizeMode: 'contain', marginLeft: 20 }}
                placeholderStyle={{ backgroundColor: 'rgba(0,0,0,0,0.0)' }}
              />
              <div style={{ textAlign: 'center', fontSize: 16, color: '#FFF', marginTop: 25, fontWeight: 'bold' }}>
                Data Tidak Tersedia
              </div>
              <div style={{ textAlign: 'center', fontSize: 13, color: '#FFF', marginTop: 15 }}>
                Informasi pembayaran yang Anda
              </div>
              <div style={{ textAlign: 'center', fontSize: 13, color: '#FFF', marginTop: 2 }}>
                cari tidak tersedia.
              </div>
              <div style={{
                flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center',
                borderColor: 'rgba(255,255,255,0.5)', borderWidth: 1,
                width: '100%', paddingVertical: 11, borderRadius: 7, marginTop: 20, cursor: 'pointer'
              }}
                onClick={() => { history.goBack() }}
              >
                <i className="fa fa-arrow-left" style={{ color: '#FFF' }} aria-hidden="true"></i>
                <div style={{ fontSize: 15, color: '#FFF', marginLeft: 5 }}>Kembali</div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }

  const renderBottomNavigation = () => {
    if (isFetched == false && fetchData !== null) {
      if (fetchData.payment_detail_object.type === 'gopay') {
        return (
          <div style={{ position: 'fixed', bottom: 0 }} className="width_full">
            <div style={{ height: 70, alignItems: 'center', justifyContent: 'center', paddingRight: 20, paddingLeft: 20, flexDirection: 'row', 
            // backgroundColor: '#D400B8', 
            display: 'flex' }} className={`background-theme-${theme}`}>
              <div style={{ flex: 1, height: 40 }}>
                <div style={{
                  borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                  alignItems: 'center', justifyContent: 'center',
                  flexDirection: 'row',
                  display: 'flex'
                }}
                  className="ripple"
                  onClick={() => { onPressPayWithGojek(fetchData.payment_detail_object.redirect_url) }}>
                  <div style={{ fontSize: 16, fontWeight: 'bold', color: '#FFF' }}>
                  Bayar ({MyHelpers.formatNumber(fetchData.grand_total, 'idr')})
                </div>
                </div>
              </div>
            </div>
          </div>
          // <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', height: 60 }}>
          //   <div style={{
          //     backgroundColor: 'rgba(0,0,0,0.4)',
          //     height: 45, paddingHorizontal: 20, alignItems: 'center', justifyContent: 'center',
          //     borderRadius: 45 / 2, width: '100%'
          //   }}
          //   >
          //     <div style={{ fontSize: 16, fontWeight: 'bold', color: '#FFF' }}>
          //       Bayar ({MyHelpers.formatNumber(fetchData.grand_total, 'idr')})
          //     </div>
          //   </div>
          // </div>
        )
      }
    }
  }

  const renderPaymentInformation = () => {
    if (fetchData.payment_detail_object.type === 'bank_transfer') {
      return (
        <div style={{ borderBottomColor: 'rgba(0,0,0,0.03)', borderBottomWidth: 10, paddingBottom: 20, }}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img
              style={{ width: 70, resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.image}
            />
            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
              <div numberOfLines={1} style={{ fontSize: 16, color: '#FFF', fontWeight: 'bold' }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: 5, marginTop: 15 }}>
            <div numberOfLines={1} style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>Nomor Rekening</div>
            <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <div numberOfLines={1} style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', marginTop: 5 }}>
                {fetchData.payment_detail_object.va_number}
              </div>
              {/* <TouchableOpacity
                        onPress={() => { Clipboard.setString(objData.va_number) }}
                        >
                        <div style={{fontSize:14, color:'#74b9ff', fontWeight:'bold'}}>SALIN</div>
                    </TouchableOpacity> */}
            </div>
          </div>
        </div>
      )
    }

    if (fetchData.payment_detail_object.type === 'echannel') {
      return (
        <div style={{ borderBottomColor: 'rgba(0,0,0,0.03)', borderBottomWidth: 10, paddingBottom: 20 }}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img
              style={{ width: 70, resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.image}
            />
            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
              <div numberOfLines={1} style={{ fontSize: 16, color: '#FFF', fontWeight: 'bold' }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div numberOfLines={1} style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>Kode Merchant</div>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div numberOfLines={1} style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', marginTop: 5 }}>
                {fetchData.payment_detail_object.va_code}
              </div>
              {/* <TouchableOpacity
                onPress={() => { Clipboard.setString(fetchData.payment_detail_object.va_code) }}
                >
                <div style={{fontSize:14, color:'#74b9ff', fontWeight:'bold'}}>SALIN</div>
              </TouchableOpacity> */}
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div numberOfLines={1} style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>Nomor Rekening</div>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div numberOfLines={1} style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', marginTop: 5 }}>
                {fetchData.payment_detail_object.va_number}
              </div>
              {/* <TouchableOpacity
                onPress={() => { Clipboard.setString(fetchData.payment_detail_object.va_number) }}
                >
                <div style={{fontSize:14, color:'#74b9ff', fontWeight:'bold'}}>SALIN</div>
              </TouchableOpacity> */}
            </div>
          </div>
        </div>
      )
    }

    if (fetchData.payment_detail_object.type === 'qris') {
      return (
        <div style={{ borderBottomColor: 'rgba(0,0,0,0.03)', borderBottomWidth: 10, paddingBottom: 20, }}>
          <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
            <img
              style={{ width: 70, resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.image}
            />
            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
              <div numberOfLines={1} style={{ fontSize: 16, color: '#FFF', fontWeight: 'bold' }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>
          </div>
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 10 }}>
            <img
              style={{ width: 'calc(100% * 0.8)', resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.qrcode_url}
            />
          </div>
        </div>
      )
    }

    if (fetchData.payment_detail_object.type === 'gopay') {
      return (
        <div style={{ borderBottomColor: 'rgba(0,0,0,0.03)', borderBottomWidth: 10, paddingBottom: 20 }}>
          <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
            <img
              style={{ width: 70, resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.image}
            />
            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
              <div numberOfLines={1} style={{ fontSize: 16, color: '#FFF', fontWeight: 'bold' }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>
          </div>
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 10 }}>
            <img
              style={{ width: 'calc(100% * 0.8)', resizeMode: 'contain' }}
              src={fetchData.payment_detail_object.qrcode_url}
            />
          </div>

        </div>
      )
    }
  }

  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Pembayaran"}
        // navigation={props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <div>
      {renderBottomNavigation()}
      </div>
    </div>
  )
  // }
}