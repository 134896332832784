import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { userAuth } from "../store"

function Authenticated(props) {
    const history = useHistory()
    const auth = userAuth()

    useEffect(() => {
        if (auth.id !== undefined && auth.id !== "") {
            if(window.location.pathname != '/' && window.location.pathname != '/login'){
                setTimeout(() => {
                    history.push(window.location.pathname)
                }, 1000)
            }else{
                setTimeout(() => {
                    history.push('/dashboard')
                }, 1000)
            }
        } else {
            setTimeout(() => {
                history.push('/login')
            }, 10)
        }
    }, [])

    return props.render
}

export default Authenticated