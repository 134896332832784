import React, { Component } from "react"

export default class Logout extends Component {
    // ====================================================================================
    // ========== ACTION LISTENER SECTIONS ================================================
    // ====================================================================================
    onCancelListeners = () => {
        const { onCancel } = this.props
        onCancel()
    }
    onSubmitListeners = () => {
        const { onSubmit } = this.props
        onSubmit()
    }

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return(
            <div className="ComponentLogout">
                <div className="ComponentLogout-container">
                    <div className="ComponentLogout-toolbar">
                        {this.props.title}
                    </div>
                    <div className="ComponentLogout-content">
                        {this.props.message}
                    </div>
                    <div className="ComponentLogout-footer">
                        <button className="btn btn-cancel-outline btn-rounded mg-right15" type="button" onClick={this.onCancelListeners}>
                            {this.props.cancelTitle}
                        </button>
                        <button className="btn btn-cancel btn-rounded" type="button" onClick={this.onSubmitListeners}>
                            {this.props.submitTitle}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}