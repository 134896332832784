import { useEffect, useMemo } from "react"
import {
  useLocation, useHistory
} from "react-router-dom";
import ApiHelpers from "../../helpers/ApiHelpers";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function LogoutAuto() {

  let query = useQuery();

  const history = useHistory()

  useEffect( async() => {

    await ApiHelpers.get('', 'logout')
    localStorage.removeItem("user_session")
    localStorage.setItem("user_session", null)

    let redirect = query.get('redirect')
    redirect = redirect ? redirect.split(',') : []
    let last = query.get('last')

    if (redirect.length > 0) {
      let link = redirect[0]
      redirect.shift()
      let nextLink = redirect.toString()
      window.location.href = `${link}?redirect=${nextLink}&last=${last}`
    } else {
      window.location.href = last
    }

  }, [])

  return (
    <>
    </>
  )
}