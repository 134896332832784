import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react"
import Modal from "react-bootstrap/Modal"

import ApiHelpers from "../../../helpers/ApiHelpers"

import './Style.css'
import { useTheme } from './../../../hooks/useTheme';

const ModalFeedback = ((props, ref) => {
  const { theme } = useTheme()

  const [show, setShow] = useState(false)

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      showModal
    }),
  )

  useEffect(() => {
    if (show) {
      setTitle('')
      setContent('')
    }
  }, [show])

  const showModal = (value) => {
    setShow(value)
  }

  const handleSubmit = async () => {
    props.onLoading && props.onLoading(true)

    let response = await ApiHelpers.post('', 'feedback/add', { title, content })

    props.onLoading && props.onLoading(false)

    if(response.status === 200) {
      setShow(false)
      props.onSuccess && props.onSuccess('Terima kasih Bunda, telah memberikan masukan (feedback) untuk aplikasi ini.')
    }else{
      props.onError && props.onError(response.message)
    }

  }

  return (
    <>
      <Modal
        show={show}
        animation={true}
        className="modal-feedback"
        centered
      >
        <div className={`background-theme-${theme}`}>
          <Modal.Header className={`background-light-theme-${theme}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(255,255,255, 1)' }}>
              <i class="fa fa-quote-left" style={{ color: '#fff', fontSize: 14 }}></i>
              <div style={{ fontWeight: 'bold' }}>Feedback Bunda</div>
              <i class="fa fa-times-circle ripple" style={{ color: '#fff', fontSize: 20, cursor: 'pointer' }} onClick={() => setShow(false)}></i>
            </div>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'auto', maxHeight: 480, padding: 15 }}>
            <form>
              <div className="label" style={{ color: '#fff' }}>Judul</div>
              <input
                className={`input background-theme-${theme}`}
                style={{ borderBottom: 'solid 1px rgba(255,255,255,0.3)' }}
                type='text'
                maxLength={50}
                placeholder='Tuliskan disini'
                autoCapitalize="sentences"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="required">
                <span className='true' style={{ color: '#ffdaa0' }}>Wajib</span>
                <span className="length" style={{ color: '#fff' }}><b>{title.length}</b> dari 50 karakter.</span>
              </div>

              <div className="label" style={{ color: '#fff' }}>Masukan (Feedback) Bunda</div>
              <textarea
                className={`textarea background-theme-${theme}`}
                style={{ borderBottom: 'solid 1px rgba(255,255,255,0.3)' }}
                maxLength={250}
                rows='3'
                placeholder='Tuliskan disini'
                autoCapitalize="sentences"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              >
              </textarea>
              <div className="required">
                <span className='true' style={{ color: '#ffdaa0' }}>Wajib</span>
                <span className="length" style={{ color: '#fff' }}><b>{content.length}</b> dari 250 karakter.</span>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div style={{
              borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)", alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
            }}
              onClick={() => { setShow(false) }}
              className="ripple"
            >
              <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Batal</div>
            </div>
            <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
              <div style={{
                borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)", alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', cursor: 'pointer'
              }}
                onClick={() => handleSubmit()}
                className="ripple"
              >
                <div style={{ color: "#fff", fontSize: 16, paddingRight: 10 }}>Kirim</div>
                <i className="fas fa-paper-plane icon" style={{ color: '#fff', fontSize: 14 }}></i>
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
})

export default forwardRef(ModalFeedback);