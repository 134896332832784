import React, { Component, useEffect, useState } from "react"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/
import Modal from "react-bootstrap/Modal"
import ApiHelpers from "../../helpers/ApiHelpers"
import { useHistory } from "react-router-dom"
import { useTheme } from './../../hooks/useTheme';


export default function LoginPopup(props) {
    const { theme } = useTheme()

    const [isShowModal, setIsShowModal] = useState(false)
    const [inputUsername, setInputUsername] = useState("")
    const [inputPassword, setInputPassword] = useState("")
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [inputPasswordClass, setInputPasswordClass] = useState('input-password')
    const [inputPasswordType, setInputPasswordType] = useState('password')
    const [showError, setShowError] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [errType, setErrType] = useState('warning')
    const [loadingMessage, setIsLoadingMessage] = useState('warning')

    const history = useHistory()

    const onShowModal = () => {
        setTimeout(() => {
            setIsShowModal(true)
            setInputUsername('')
            setInputPassword('')
        }, 1)
    }

    useEffect(() => {
        props.refLoginPopup.current = onShowModal;

        return () => {
            props.refLoginPopup.current = null;
        };
    });

    const onCloseModal = () => { setIsShowModal(false) }
    const onShowPassword = () => {
        let isShow = !isShowPassword
        setIsShowModal(isShow)
        setInputPasswordClass(isShow ? "" : "input-password")
        setInputPasswordType(isShow ? "input" : "password")
    }
    const focusTextInput = (node) => { node.focus() }
    const onSetIsLogin = async (isLogin, data) => {
        setIsLoadingMessage("Menyimpan sesi login Anda...")
        // setIsLoadingMessage("Menyimpan sesi login Anda...")
        return new Promise((resolve) => {
            let result = false
            localStorage.setItem('user_session', JSON.stringify(data))
            let auth = JSON.parse(localStorage.getItem('user_session'))
            if (auth !== null && auth.id !== undefined && auth.id !== "") {
                result = isLogin
            }

            return resolve(result)
        })
    }
    const onLoginBasicListeners = async () => {
        setShowError(false)
        let valid = false
        let errMessage = ""

        if (inputUsername === "" && inputPassword === "") {
            valid = false
            errMessage = "Anda belum menuliskan email & password"
        } else if (inputUsername === "" || inputUsername.length < 4) {
            valid = false
            errMessage = "Email tidak boleh kosong dan minimal 5 karakter."
        } else {
            if (inputPassword === "" || inputPassword.length < 4) {
                valid = false
                errMessage = "Password tidak boleh kosong dan minimal 5 karakter."
            } else {
                valid = true
                errMessage = "Ok."
            }
        }

        if (!valid) {
            setErrMessage(errMessage)
            setShowError(true)
        }


        if (valid) {
            const { onLoginBasicListeners } = props
            onLoginBasicListeners({
                inputUsername,
                inputPassword
            })
        } else {
            setErrMessage(errMessage)
            setErrType("warning")
            setShowError(true)

        }
    }

    const onCloseAlert = () => {
        setErrMessage('')
        setErrType("warning")
        setShowError(false)
    }



    const renderToolbar = () => {
        return (
            <Modal.Header style={{
                width: '100%',
                height: 50,
                flexDirection: 'row',
                // backgroundColor: "#D400B8",
                borderRadius: '15px 15px 0px 0px',
                alignItems: 'center', justifyContent: 'center',
                paddingHorizontal: 20,
            }} className={`background-theme-${theme}`}>
                <i className="fas fa-sign-in icon" style={{ color: "#fff" }}></i>
                <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Login</div>
                <i className="fas fa-times icon" style={{ color: "#fff" }} onClick={() => {
                    onCloseModal()
                }}></i>
            </Modal.Header>
        )
    }


    const renderFooter = () => {
        return (
            <Modal.Footer style={{
                height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row', 
                // backgroundColor: "#D400B8",
                borderRadius: '0px 0px 15px 15px',
            }} className={`background-theme-${theme}`}>
                <div style={{
                    borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
                    alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: "pointer"
                }}
                    onClick={() => {
                        onCloseModal()
                    }}>
                    <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
                </div>
                <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                    <div style={{
                        borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
                        alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        cursor: "pointer"
                    }}
                        onClick={() => { onLoginBasicListeners() }}>
                        <i className="fas fa-check icon" style={{ color: '#fff', fontSize: 14 }}></i>
                        <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Login</div>
                    </div>
                </div>
            </Modal.Footer>
        )
    }


    const renderContents = () => {
        return (
            <Modal.Body style={{ 
                // backgroundColor: "#D400B8" 
                }} className={`background-theme-${theme}`}>
                <div style={{ alignItems: "center" }}>
                    <div style={{
                        paddingHorizontal: 15,
                        height: 40,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 40,
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 20
                    }}>
                        <i className="far fa-user-circle icon" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
                        <input
                            value={inputUsername}
                            onChange={(event) => setInputUsername(event.target.value)}
                            type="text"
                            onSubmit={() => { onLoginBasicListeners() }}
                            className="form-control no-border"
                            placeholder="Email"
                            defaultValue=""
                            style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
                        />

                    </div>
                </div>

                <div style={{ alignItems: "center" }}>
                    <div style={{
                        paddingHorizontal: 15,
                        height: 40,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 40,
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 20
                    }}>
                        <i className="fal fa-lock-alt icon" style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
                        <input
                            value={inputPassword}
                            onChange={(event) => setInputPassword(event.target.value)}
                            className={`form-control no-border ${inputPasswordClass}`}
                            placeholder="Password"
                            defaultValue=""
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    onLoginBasicListeners()
                                }
                            }}

                            style={{ paddingLeft: 15, flex: 1, fontSize: 16, color: "#fff", fontWeight: "bold", backgroundColor: 'transparent', '::placeholder': 'rgba(255,255,255,0.15)' }}
                        />
                        <span className="input-group-text cursor no-border" onClick={onShowPassword} style={{ backgroundColor: 'transparent' }}>
                            <i className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'}`} style={{ color: '#fff', fontSize: 20, marginLeft: 15 }}></i>
                        </span>

                    </div>
                </div>
            </Modal.Body>
        )
    }

    return (
        <div>
            <Modal animationType="fade" show={isShowModal} transparent centered>
                {renderToolbar()}
                {renderContents()}
                {renderFooter()}
            </Modal>

            <Snackbar style={{
                position: 'absolute',
                width: '100%',
                left: 'auto',
                right: 'auto'
            }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
                <Alert onClose={onCloseAlert} severity={errType}
                    sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
                    {errMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}