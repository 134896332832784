import React, { Component, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"
import img_payment_method from "../../assets/BK/img_payment_method_white.png"
import ApiHelpers from "../../helpers/ApiHelpers"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import { useTheme } from "../../hooks/useTheme"


export default function SubscriptionsPayment(props) {

  const { theme } = useTheme()

  const [isShowModal, setIsShowModal] = useState(false)
  const [isFetched, seIsFetched] = useState(true)
  const [objectDetail, setObjectDetail] = useState(null)
  const [dataSelected, setDataSelected] = useState(null)
  const MODAL_HEIGHT = '100vh'

  const history = useHistory()

  useEffect(() => {
    props.refSubscriptionsPayment.current = onShowModal;

    return () => {
      props.refSubscriptionsPayment.current = null;
    };
  });


  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'general/payment_methods')
    if (response.status === 200) {
      objData = response.results.data
    }
    setObjectDetail(objData)
    seIsFetched(false)
  }

  const onShowModal = (data) => {
    setDataSelected(data)
    seIsFetched(true)
    setObjectDetail(null)
    getData()
    setTimeout(() => {
      setIsShowModal(true)
    }, 1)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  const onCloseModal = () => {
    setIsShowModal(false)
  }

  const onCheckoutListeners = (data) => {
    props.onUpdateData(data)
    setIsShowModal(false)
  }



  const renderToolbar = () => {
    return (
      <Modal.Header style={{
        width: '100%',
        height: 50,
        flexDirection: 'row',
        // backgroundColor: "#950082",
        borderRadius: '15px 15px 0px 0px',
        alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 20,
      }} className={`background-light-theme-${theme}`}>
        {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
        <i class="fa fa-th-list" style={{ color: "#fff" }}></i>
        <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Pilih Metode</div>
        <i className="fas fa-times icon ripple" style={{ color: "#fff", cursor: 'pointer' }} onClick={() => { onCloseModal() }}></i>
      </Modal.Header>
    )
  }
  const renderContents = () => {
    if (isFetched == false) {
      if (objectDetail.length > 0) {
        return (
          <Modal.Body style={{ 
            // backgroundColor: "#D400B8", 
            borderRadius: '0px 0px 15px 15px'
            }} className={`background-theme-${theme}`}>
            {objectDetail.map((item) => {
              return (
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  paddingTop: 12,
                  paddingBottom: 12
                }}
                  onClick={() => {
                    onCheckoutListeners(item)
                  }}>
                  {/* { item } */}
                  <div style={{ backgroundSize: 'contain' }}>
                    <img src={item.image} style={{ width: 70 }} />
                  </div>
                  <div style={{ textAlign: 'left', marginLeft: 15, color: 'white', fontWeight: 700 }}>
                    {item.label}
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <i class="fa fa-check-circle" style={{ color: dataSelected !== null ? "#fff" : 'rgba(0,0,0,0.3)' }}></i>
                  </div>
                </div>
              )
            })}
          </Modal.Body>
        )
      } else {
        return (
          <Modal.Body style={{ 
            // backgroundColor: "#D400B8", 
            borderRadius: '0px 0px 15px 15px'
            }} className={`background-theme-${theme}`}>
            <div style={{ height: 'calc(100vh * 0.4)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div>
                <img
                  src={img_payment_method} style={{ width: 120, height: 120, resizeMode: 'contain' }}
                  placeholderStyle={{ backgroundColor: "rgba(0,0,0,0.0)" }}
                />
                <div style={{ fontSize: 16, color: 'white', fontWeight: "bold", marginTop: 30 }}>Metode Bayar Tidak Tersedia</div>
                <div style={{ fontSize: 12, marginTop: 13, color: 'white' }}>Metode pembayaran tidak ditemukan atau,</div>
                <div style={{ fontSize: 12, marginTop: 3, color: 'white' }}>data yang Anda cari tidak tersedia.</div>
              </div>
            </div>
          </Modal.Body>
        )
      }
    } else {
      return (
        <Modal.Body style={{ 
          // backgroundColor: "#D400B8", 
          borderRadius: '0px 0px 15px 15px'
          }} className={`background-theme-${theme}`}>
          <div style={{ textAlign: "left", padding: "15px 0px", borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            Loading ...
          </div>
        </Modal.Body>
      )
    }
  }
  return (
    <div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        {renderToolbar()}
        {renderContents()}
      </Modal>
    </div>
  )
}