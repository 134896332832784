import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.css"
import "./App.css"
import * as Middleware from "./middleware/Middleware"
import 'moment'
import 'moment/locale/id'

import Splash from "./modules/Splash"
import Login from "./modules/auth/Login"
import AutoLogin from "./modules/auth/AutoLogin"
import LogoutAuto from "./modules/account/LogoutAuto"
import Dashboard from "./modules/Dashboard"
import Contraction from "./modules/contraction/Contraction"
import Account from "./modules/account/Account"
import AccountPhoneNumber from "./modules/account/AccountPhoneNumber"
import AccountSubscriptions from "./modules/account/AccountSubscriptions"
import Privacy from "./modules/Privacy"
import SubscriptionsPackage from "./modules/subscriptions/SubscriptionsPackage"
import SubscriptionsCheckout from "./modules/subscriptions/SubscriptionsCheckout"
import SubscriptionsCheckoutResult from "./modules/subscriptions/SubscriptionsCheckoutResult"
import SubscriptionsHistory from "./modules/subscriptions/SubscriptionsHistory"
import SubscriptionsHistoryDetail from "./modules/subscriptions/SubscriptionsHistoryDetail"
import History from './modules/contractionHistory/History';

export default function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Middleware.Authenticated render={<Splash />} />
        </Route>
        <Route path="/login">
          <Middleware.Guest render={<Login />} />
        </Route>
        <Route path="/auth">
          <Middleware.Guest render={<AutoLogin />} />
        </Route>
        <Route path="/logout">
          <Middleware.Authenticated render={<LogoutAuto />} />
        </Route>
        <Route path="/dashboard">
          <Middleware.Authenticated render={<Dashboard />} />
        </Route>
        <Route path="/contraction">
          <Middleware.Authenticated render={<Contraction />} />
        </Route>
        <Route path="/account">
          <Middleware.Authenticated render={<Account />} />
        </Route>
        <Route path="/contraction-history">
          <Middleware.Authenticated render={<History />} />
        </Route>
        <Route path="/account-number-phone">
          <Middleware.Authenticated render={<AccountPhoneNumber />} />
        </Route>
        <Route path="/account-subscriptions">
          <Middleware.Authenticated render={<AccountSubscriptions />} />
        </Route>
        <Route path="/subscriptions-package">
          <Middleware.Authenticated render={<SubscriptionsPackage />} />
        </Route>
        <Route path="/checkout">
          <Middleware.Authenticated render={<SubscriptionsCheckout />} />
        </Route>
        <Route path="/subscriptions-payment/:id">
          <Middleware.Authenticated render={<SubscriptionsCheckoutResult />} />
        </Route>
        <Route path="/subscriptions-history">
          <Middleware.Authenticated render={<SubscriptionsHistory />} />
        </Route>
        <Route path="/subscriptions-history-detail/:id">
          <Middleware.Authenticated render={<SubscriptionsHistoryDetail />} />
        </Route>
        <Route path="/privacy-policy">
          <Middleware.Guest render={<Privacy />} />
          <Privacy />
        </Route>
      </Switch>
    </Router>
  )
}