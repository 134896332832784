import React, { useState, useRef, useCallback, useEffect } from "react"
import ApiHelpers from "../../helpers/ApiHelpers"
import Modal from "react-bootstrap/Modal"
import Header from "../../components/Header"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import Loading from "../../components/Loading"
import { useHistory, useParams } from "react-router-dom"
import { useTheme } from './../../hooks/useTheme';

export default function SubscriptionsHistoryDetail() {

  const { theme } = useTheme()


  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [metodeSelected, setMetodeSelected] = useState(null)
  const history = useHistory()
  const { id } = useParams()


  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    let objData = null
    let response = await ApiHelpers.get("", 'transaction/detail', {
      "id": id
    })
    if (response.status === 200) {
      objData = response.results.data
    }
    setFetchData(objData)
    seIsFetched(false)
  }

  const setDurationInfo = (params) => {
    let result = ""
    if (params === "Day") { result = "hari" }
    if (params === "Month") { result = "bulan" }
    if (params === "Year") { result = "tahun" }
    return result
  }
  const handleCaraBayar = (data) => {
    // localStorage.setItem('subscriptions_id', JSON.stringify(data))
    history.push(`/subscriptions-payment/${data}`)
    // history.push(`/subscriptions-payment`)
  }

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      return (
        <div>
          <div style={{ marginBottom: fetchData.status === "belum bayar" ? 40 : 10 }}>
            <div style={{ marginBottom: 15 }}>
              {renderPaymentStatus()}
              <div style={{
                paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10,
                borderBottom: "1px solid rgba(0,0,0,0.03)",
                borderBottomWidth: 10,
              }}>
                <div
                  style={{ fontSize: 16, fontWeight: 'bold', color: 'rgba(255,255,255,0.7)' }}>
                  Rincian Layanan
                </div>
                <div
                  style={{
                    flexDirection: 'row', display: "flex", alignItems: 'center',
                    marginTop: 12,
                    borderBottom: "1px solid rgba(0,0,0,0.03)",
                    paddingTop: 12,
                    paddingBottom: 12
                  }}>
                  <div style={{ fontSize: 14, color: 'white' }}>Nama</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: 'white', textAlign: 'right' }}>
                    {fetchData.package_name}
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: 'row', display: "flex", alignItems: 'center',
                    borderBottom: "1px solid rgba(0,0,0,0.03)",
                    paddingTop: 12,
                    paddingBottom: 12
                  }}>
                  <div style={{ fontSize: 14, color: 'white' }}>Durasi</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#ffeaa7', textAlign: 'right' }}>
                    {fetchData.package_subscribe_value} {setDurationInfo(fetchData.package_subscribe_type)}
                  </div>
                </div>
              </div>
              {/* <hr style={{ border:'7px solid rgba(0,0,0,0.03)', margin:0 }}></hr> */}
              <div style={{
                paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10,
                borderBottom: "1px solid rgba(0,0,0,0.03)",
                borderBottomWidth: 10,
              }}>
                <div style={{ fontSize: 16, fontWeight: 'bold', color: "#FFF" }}>Rincian Pemesanan</div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  marginTop: 12,
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 1,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: "#fff" }}>No Transaksi</div>
                  <div style={{ flex: 1, paddingLeft: 20, paddingRight: 10, fontSize: 14, fontWeight: 'bold', color: "#fff", textAlign: 'right' }}>
                    {fetchData.trans_number}
                  </div>
                  {/* <TouchableOpacity
                          onPress={() => { Clipboard.setString(fetchData.trans_number) }}
                          >
                          <div style={{fontSize:14, color:'#74b9ff', fontWeight:'bold'}}>SALIN</div>
                      </TouchableOpacity> */}
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', paddingTop: 12, paddingBottom: 12 }}>
                  <div style={{ fontSize: 14, color: "#fff" }}>Waktu Transaksi</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: "#fff", textAlign: 'right' }}>
                    {moment(fetchData.input_datetime).format("lll")}
                  </div>
                </div>
                {fetchData.status === "selesai" &&
                  <div style={{
                    flexDirection: 'row', display: 'flex', alignItems: 'center',
                    borderTopColor: "rgba(0,0,0,0.03)",
                    borderTopWidth: 1,
                    paddingTop: 12, paddingBottom: 12
                  }}>
                    <div style={{ fontSize: 14, color: "#fff" }}>Waktu Pembayaran</div>
                    <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: "#fff", textAlign: 'right' }}>
                      {moment(fetchData.payment_datetime).format("lll")}
                    </div>
                  </div>
                }
              </div>
              {/* <hr style={{ border:'7px solid rgba(0,0,0,0.03)', margin:0 }}></hr> */}

              <div style={{
                paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10,
                borderBottom: "1px solid rgba(0,0,0,0.03)",
                borderBottomWidth: 10,
              }}>
                <div style={{ fontSize: 16, fontWeight: 'bold', color: "#FFF" }}>Rincian Pembayaran</div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  marginTop: 12,
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 1,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: '#FFF' }}>Subtotal</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right' }}>
                    {MyHelpers.formatNumber(fetchData.package_price, 'idr')}
                  </div>
                </div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 1,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: '#FFF' }}>Diskon</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#55efc4', textAlign: 'right' }}>
                    -{MyHelpers.formatNumber(fetchData.package_discount_value, 'idr')} {fetchData.package_discount_percent !== "" && Number(fetchData.package_discount_percent) !== 0 && `(${fetchData.package_discount_percent}%)`}
                  </div>
                </div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 5,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: '#FFF' }}>Biaya Admin</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#fab1a0', textAlign: 'right' }}>
                    {MyHelpers.formatNumber(fetchData.admin_fee, 'idr')}
                  </div>
                </div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 1,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 16, color: '#ffeaa7', fontWeight: "bold" }}>Total Bayar</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 16, fontWeight: 'bold', color: '#ffeaa7', textAlign: 'right' }}>
                    {MyHelpers.formatNumber(fetchData.grand_total, 'idr')}
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: 18, paddingRight: 18, paddingTop: 20, paddingBottom: 10 }}>
                <div style={{ fontSize: 16, fontWeight: 'bold', color: "#FFF" }}>Metode Pembayaran</div>
                <div style={{
                  flexDirection: 'row', display: 'flex', alignItems: 'center',
                  marginTop: 12,
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  borderBottomWidth: 1,
                  paddingTop: 12, paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: '#FFF' }}>TIPE</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right', textTransform: "uppercase" }}>
                    {fetchData.payment_type !== "" ? fetchData.payment_type.replace(/_/g, ' ') : "-"}
                  </div>
                </div>
                {renderPaymentInfo()}
              </div>
            </div>
          </div>

          {fetchData !== null && fetchData.status === "belum bayar" &&
            <div style={{ position: 'fixed', bottom: 0 }} className="width_full">
              <div style={{ height: 70, alignItems: 'center', justifyContent: 'center', paddingRight: 18, paddingLeft: 18, flexDirection: 'row', 
              // backgroundColor: '#D400B8', 
              display: 'flex' }} className={`background-theme-${theme}`}>
                <div style={{ display: 'flex', flex: 1, height: 40, flexDirection: 'row' }}>
                  <div style={{ flex: 1, height: 40 }}>
                    <div style={{
                      borderRadius: 40, height: 40, width: '100%', backgroundColor: "rgba(0,0,0,0.4)",
                      alignItems: 'center', justifyContent: 'center',
                      flexDirection: 'row',
                      display: 'flex'
                    }}
                      onClick={() => { handleCaraBayar(fetchData.id) }}
                      className="ripple">
                      <i className="fa fa-info-circle" style={{ color: "#FFF", width: 18 }}></i>
                      <div style={{ color: "#fff", fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
                        Cara Pembayaran
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }

  const renderPaymentInfo = () => {
    if (fetchData.payment_detail_object.type !== undefined) {
      if (fetchData.payment_detail_object.type === "bank_transfer") {
        return (
          <div style={{ marginTop: 12, paddingBottom: 20 }}>
            <div
              style={{
                flexDirection: 'row', display: "flex", alignItems: 'center',
                marginTop: 12,
                borderBottom: "1px solid rgba(0,0,0,0.03)",
                paddingTop: 12,
                paddingBottom: 12
              }}>
              <div style={{ fontSize: 14, color: '#FFF' }}>Nama Bank</div>
              <div style={{
                flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right',
                textTransform: "capitalize"
              }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>

            <div
              style={{
                flexDirection: 'row', display: "flex", alignItems: 'center',
                marginTop: 12,
                borderBottom: "1px solid rgba(0,0,0,0.03)",
                paddingTop: 12,
                paddingBottom: 12
              }}>
              <div style={{ fontSize: 14, color: '#FFF' }}>No Rekening</div>
              <div style={{
                flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right',
                textTransform: "capitalize"
              }}>
                {fetchData.payment_detail_object.va_number}
              </div>
            </div>
          </div>
        )
      }

      if (fetchData.payment_detail_object.type === "echannel") {
        return (
          <div style={{ marginTop: 12, paddingBottom: 20 }}>
            <div style={{
              flexDirection: 'row', alignItems: 'center',
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12,
              display: 'flex'
            }}>
              <div style={{ fontSize: 14, color: "#FFF" }}>Bank</div>
              <div style={{
                flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: "#FFF", textAlign: 'right',
                textTransform: "capitalize"
              }}>
                {fetchData.payment_detail_object.bank}
              </div>
            </div>
            <div style={{
              flexDirection: 'row', alignItems: 'center',
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12,
              display: 'flex'
            }}>
              <div style={{ fontSize: 14, color: "#FFF" }}>Kode Merchant</div>
              <div style={{
                flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: "#FFF", textAlign: 'right',
                textTransform: "capitalize"
              }}>
                {fetchData.payment_detail_object.va_code}
              </div>
            </div>
            <div style={{
              flexDirection: 'row', alignItems: 'center', paddingTop: 12,
              paddingBottom: 12,
              display: 'flex'
            }}>
              <div style={{ fontSize: 14, color: "#FFF" }}>No Rekening</div>
              <div style={{
                flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: "#FFF", textAlign: 'right',
                textTransform: "capitalize"
              }}>
                {fetchData.payment_detail_object.va_number}
              </div>
            </div>
          </div>
        )
      }
    }
  }

  const renderPaymentStatus = () => {
    let note = 'Terimakasih telah melakukan transaksi.'
    let icon = 'fa fa-thumbs-o-up'
    if (fetchData.status === 'dibatalkan') {
      icon = 'fa fa-times'
      if (fetchData.cancel_reason !== "") {
        note = fetchData.cancel_reason
      }
    }
    if (fetchData.status === 'belum bayar') {
      icon = 'fa fa-credit-card'
      note = `Silahkan melakukan pembayaran sebelum tanggal ${moment(fetchData.expired_payment_datetime).format("lll")}.`
    }

    let bgColor = "#ffeaa7"
    let ftColor = "#000"
    if (fetchData.status === "selesai") {
      bgColor = "#55efc4"
      ftColor = "#000"
    }
    if (fetchData.status === "dibatalkan") {
      bgColor = "#ff7675"
      ftColor = "#FFF"
    }

    return (
      <div style={{
        backgroundColor: bgColor, paddingLeft: 15, paddingRight: 15, paddingTop: 20, paddingBottom: 20, display: 'flex',
        flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', position: 'relative'
      }}>
        <div style={{ flex: 1, paddingRight: 20 }}>
          <div style={{ color: ftColor, textTransform: 'capitalize', fontWeight: 'bold', fontSize: 18 }}>
            {fetchData.status}
          </div>
          <div style={{ color: ftColor, fontSize: 14, marginTop: 7 }}>
            {note}
          </div>
        </div>
        <div style={{ width: 50, height: 50, alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
          <i className="fa fa-align-justify fa-3x" style={{ color: ftColor }}></i>
          <div style={{
            position: 'absolute', display: 'flex', backgroundColor: ftColor, width: 35, height: 35, alignItems: 'center', justifyContent: 'center', bottom: -5, left: -5, borderRadius: 35 / 2,
          }}>
            <i className={icon} style={{ color: fetchData.status === "dibatalkan" ? '#ff7675' : '#FFF' }}></i>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{
      flex: 1,
      // backgroundColor: '#D400B8',
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Detail Transaksi"}
        // navigation={props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
    </div>
  )
  // }
}