import React, { useState, useRef, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
import ModalTheme from "../modules/_modals/ModalTheme/ModalTheme"
import { useTheme } from "../hooks/useTheme"

export default function Header(props) {

  const { theme, backgroundImageTheme, changeTheme } = useTheme()
  const refModalTheme = useRef(null);
  const [selectedTheme, setSelectedTheme] = useState('default')

  const history = useHistory()
  const loacation = window.location.href

  let strSplit = loacation.split("/")

  useEffect(() => {
    let themeName = localStorage.getItem('ake_theme')

    if (themeName) {
      setSelectedTheme(themeName)
    }
  }, []);

  return (
    <div style={{
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      position: 'fixed',
      zIndex: 999
    }}
      className={`background-theme-${theme} width_full`}>
      <div onClick={() => strSplit[3] === 'account' ? history.push('/dashboard') : strSplit[3] === 'subscriptions-history' ? history.push('/account') : strSplit[3] === 'subscriptions-payment' ? history.push('/subscriptions-history') : history.goBack()} style={{ position: 'absolute', left: props.paddingHorizontal, zIndex: 2 }}>
        <i className="far fa-arrow-left" style={{ color: props.color, width: props.fontSize * 1.5, cursor: 'pointer' }}></i>
      </div>
      <div style={{ flex: 1, paddingTop: 4 }}>
        {props.title === "loading" ?
          <div style={{
            color: props.color,
            fontSize: props.fontSize,
            fontWeight: props.fontWeight,
            textAlign: props.textAlign,
          }}>
            <Spinner animation="border" variant="light" />
          </div>
          :
          <div style={{
            color: props.color,
            fontSize: props.fontSize,
            fontWeight: props.fontWeight,
            textAlign: props.textAlign,
          }}>
            {props.title}
          </div>
        }
      </div>
      {
        props.showThemeConfig &&
        <div
          style={{ position: 'absolute', right: props.paddingHorizontal, zIndex: 2, cursor: 'pointer' }}
          className="ripple"
          onClick={() => refModalTheme.current.showModal(true)}
        >
          <i className="far fa-paint-brush" style={{ color: props.color, width: props.fontSize * 1.5 }}></i>
        </div>
      }


      <ModalTheme
        ref={refModalTheme}
        onSelectedTheme={(item) => {
          setSelectedTheme(item.name)
          localStorage.setItem('ake_theme', item.name)
          changeTheme(item.name)
        }}
        selectedTheme={selectedTheme}
      />
    </div>
  )
}