import React, { useState, useRef, useCallback, useEffect } from "react"
import SubscriptionsPayment from "./SubscriptionsPayment"
import ApiHelpers from "../../helpers/ApiHelpers"
import Modal from "react-bootstrap/Modal"
import Header from "../../components/Header"
import MyHelpers from "../../helpers/MyHelpers"
import moment from 'moment'
import Loading from "../../components/Loading"
import { useHistory } from "react-router-dom"
import { useTheme } from "../../hooks/useTheme"
import Snackbar from '@mui/material/Snackbar' //https://mui.com/getting-started/usage/
import Alert from '@mui/material/Alert' //https://mui.com/getting-started/usage/

export default function SubscriptionsCheckout() {

  const { theme } = useTheme()


  const refSubscriptionsPayment = useRef(null)
  const [isFetched, seIsFetched] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [fetchData, setFetchData] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [isShowModal, setIsShowModal] = useState(false)
  const [dataSelected, setDataSelected] = useState(JSON.parse(localStorage.getItem('subscriptions_package')))
  const [metodeSelected, setMetodeSelected] = useState(null)
  const history = useHistory()
  const [showError, setShowError] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [errType, setErrType] = useState('warning')


  // ====================================================================================
  // ========== ACTION LISTENER SECTIONS ================================================
  // ====================================================================================

  const onRefresh = () => {
    seIsFetched(true)
    setIsRefreshing(false)
    setFetchData(null)
    getData()
  }
  const getData = async () => {
    // let objData = null
    // let response = await ApiHelpers.get("", 'packages/paid', {
    //   "id": dataSelected.id
    //   // "payment_method_object": dataSelected
    // })
    // if (response.status === 200) {
    //   objData = response.results.data
    // }
    // setFetchData(objData)
    seIsFetched(false)
  }

  const setDurationInfo = (params) => {
    let result = ""
    if (params === "Day") { result = "hari" }
    if (params === "Month") { result = "bulan" }
    if (params === "Year") { result = "tahun" }
    return result
  }
  const setDurationActiveDate = (duration, type) => {
    let result = ""
    if (type === "Day") { result = moment().add(Number(duration), 'days').format('ll') }
    if (type === "Month") { result = moment().add(Number(duration), 'months').format('ll') }
    if (type === "Year") { result = moment().add(Number(duration), 'years').format('ll') }
    return result
  }
  const setTotalBayar = (fetchData, dataSelected) => {
    let result = "0"
    let price = Number(dataSelected.price)
    let discount = Number(dataSelected.discount_value)
    let fee = Number(fetchData.admin_fee)

    result = (price - discount) + fee
    return MyHelpers.formatNumber(result, 'idr')
  }

  const onPaymentDataListeners = async (data) => {
    setMetodeSelected(data)
  }

  // async function onCheckoutListeners(params) {
  //   setIsLoading(true)
  //   setIsLoadingMessage("Proses login...")

  //   let response = await ApiHelpers.post("", "auth/login", {
  //       "email": params.inputUsername,
  //       "password": params.inputPassword,
  //       "fcm_token": "",
  //       "device": "web",
  //   })

  //   setIsLoadingMessage("Mengecek akun login Anda...")
  //   if (response.status === 200) {
  //       let isLogged = await onSetIsLogin(true, response.results.data)
  //       if (isLogged) {
  //           setTimeout(() => {
  //               history.push('/dashboard')
  //           }, 1500)
  //       } else {
  //           setTimeout(() => {
  //               setErrMessage("Gagal login ke akun Anda.")
  //               setErrType("error")
  //               setShowError(true)
  //           }, 1500)
  //       }
  //   } else {
  //       setErrMessage(response.message)
  //       setErrType("error")
  //       setShowError(true)
  //   }
  // }

  const onCheckoutListeners = async () => {
    seIsFetched(true)
    setLoadingMessage('Loading...')
    let response = await ApiHelpers.post("", "packages/paid", {
      "id": dataSelected.id,
      "payment_method_object": metodeSelected
    })
    if (response.status === 200) {
      // localStorage.setItem('subscriptions_id', JSON.stringify(response.results.data.id))
      history.push(`/subscriptions-payment/${response.results.data.id}`)
      // history.push(`/subscriptions-payment`)
    } else {
      setErrMessage(response.message)
      setErrType("error")
      setShowError(true)
    }
  }

  const onCloseAlert = () => {
    setErrMessage('')
    setErrType("warning")
    setShowError(false)
  }

  useEffect(() => {
    onRefresh()
  }, [])

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  // render() {

  const renderContents = () => {
    if (isFetched == false) {
      return (
        <div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ marginBottom: 15 }}>
              <div
                style={{ paddingLeft: 18, paddingRight: 18 }}>
                <div
                  style={{ fontSize: 16, fontWeight: 'bold', color: 'rgba(255,255,255,0.7)' }}>
                  Rincian Layanan
                </div>
                <div
                  style={{
                    flexDirection: 'row', display: "flex", alignItems: 'center',
                    marginTop: 12,
                    borderBottom: "1px solid rgba(0,0,0,0.03)",
                    paddingTop: 12,
                    paddingBottom: 12
                  }}>
                  <div style={{ fontSize: 14, color: 'white' }}>Nama</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: 'white', textAlign: 'right' }}>
                    {dataSelected.name}
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: 'row', display: "flex", alignItems: 'center',
                    borderBottom: "1px solid rgba(0,0,0,0.03)",
                    paddingTop: 12,
                    paddingBottom: 12
                  }}>
                  <div style={{ fontSize: 14, color: 'white' }}>Durasi</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#ffeaa7', textAlign: 'right' }}>
                    {dataSelected.subscribe_value} {setDurationInfo(dataSelected.subscribe_type)}
                  </div>
                </div>
                <div style={{
                  flexDirection: 'row', display: "flex", alignItems: 'center',
                  borderBottom: "1px solid rgba(0,0,0,0.03)",
                  paddingTop: 12,
                  paddingBottom: 12
                }}>
                  <div style={{ fontSize: 14, color: 'white' }}>Aktif s.d.</div>
                  <div style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#55efc4', textAlign: 'right' }}>
                    {setDurationActiveDate(dataSelected.subscribe_value, dataSelected.subscribe_type)}
                  </div>
                </div>
              </div>
              <div style={{ border: '6px solid rgba(0,0,0,0.03)', margin: 0 }} />
              <div
                style={{ paddingLeft: 18, paddingRight: 18 }}>
                <div style={{
                  paddingTop: 15,
                  paddingBottom: metodeSelected !== null ? 5 : 0
                }}>
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 16, fontWeight: 'bold', color: 'rgba(255,255,255,0.7)' }}>Metode Bayar</div>
                    <div style={{ backgroundColor: "rgba(0,0,0,0.5)", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 15 }} onClick={() => { refSubscriptionsPayment.current(metodeSelected) }}>
                      <div style={{ fontSize: 14, fontWeight: 'bold', color: 'rgba(255,255,255,0.7)' }}>
                        {metodeSelected === null ? "Pilih Metode" : "Ubah Metode"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                {
                  metodeSelected !== null &&
                  renderPaymentInfo()
                }
              </div>
              <div style={{ border: metodeSelected !== null ? '6px solid rgba(0,0,0,0.03)' : '0px solid rgba(0,0,0,0.03)', backgroundColor: metodeSelected !== null ? '' : 'unset', margin: 0 }}></div>
              <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                {
                  metodeSelected !== null &&
                  <div style={{
                    paddingTop: 18,
                    paddingBottom: 20
                  }}>
                    <div style={{ fontSize: 16, fontWeight: 'bold', color: 'rgba(255,255,255,0.7)' }}>Rincian Pembayaran</div>
                    <div style={{
                      flexDirection: 'row', display: 'flex', alignItems: 'center',
                      marginTop: 12,
                      borderBottomColor: "rgba(0,0,0,0.03)",
                      borderBottomWidth: 1,
                      paddingTop: 12,
                      paddingBottom: 12
                    }}>
                      <div style={{ fontSize: 14, color: "#fff" }}>Subtotal</div>
                      <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right' }}>
                        {MyHelpers.formatNumber(dataSelected.price, 'idr')}
                      </div>
                    </div>
                    <div style={{
                      flexDirection: 'row', display: 'flex', alignItems: 'center',
                      borderBottomColor: "rgba(0,0,0,0.03)",
                      borderBottomWidth: 1,
                      paddingTop: 12,
                      paddingBottom: 12
                    }}>
                      <div style={{ fontSize: 14, color: "#fff" }}>Diskon</div>
                      <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#55efc4', textAlign: 'right' }}>
                        -{MyHelpers.formatNumber(dataSelected.discount_value, 'idr')} {dataSelected.discount_percent !== "" && dataSelected.discount_percent !== "0" && `(${dataSelected.discount_percent}%)`}
                      </div>
                    </div>
                    <div style={{
                      flexDirection: 'row', display: 'flex', alignItems: 'center',
                      borderBottomColor: "rgba(0,0,0,0.03)",
                      borderBottomWidth: 5,
                      paddingTop: 12,
                      paddingBottom: 12
                    }}>
                      <div style={{ fontSize: 14, color: "#fff" }}>Biaya Admin</div>
                      <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#fab1a0', textAlign: 'right' }}>
                        {MyHelpers.formatNumber(metodeSelected.admin_fee, 'idr')}
                      </div>
                    </div>
                    <div style={{
                      flexDirection: 'row', display: 'flex', alignItems: 'center',
                      borderBottomColor: "rgba(0,0,0,0.03)",
                      borderBottomWidth: 1,
                      paddingTop: 12,
                      paddingBottom: 12
                    }}>
                      <div style={{ fontSize: 16, color: '#ffeaa7', fontWeight: "bold" }}>Total Bayar</div>
                      <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 16, fontWeight: 'bold', color: '#ffeaa7', textAlign: 'right' }}>
                        {setTotalBayar(metodeSelected, dataSelected)}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          {
            metodeSelected !== null &&
            <div style={{ position: 'fixed', bottom: 0 }} className="width_full">
              <div style={{ height: 70, alignItems: 'center', justifyContent: 'center', paddingRight: 18, paddingLeft: 18, flexDirection: 'row', 
              // backgroundColor: '#D400B8', 
              display: 'flex' }}
              className={`background-light-theme-${theme}`}
              >
                <div style={{ display: 'flex', flex: 1, height: 40, flexDirection: 'row' }}>
                  <div style={{
                    borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
                    alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
                  }}
                    onClick={() => { history.goBack() }}>
                    <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Batal</div>
                  </div>
                  <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
                    <div style={{
                      borderRadius: 40, height: 40, width: '100%', backgroundColor: "rgba(0,0,0,0.4)",
                      alignItems: 'center', justifyContent: 'center',
                      flexDirection: 'row',
                      display: 'flex'
                    }}
                      onClick={() => { setIsShowModal(true) }}
                      className="ripple">
                      <i className="fas fa-check-circle" style={{ color: "#FFF", width: 18 }}></i>
                      <div style={{ color: "#fff", fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
                        Checkout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      )
    } else {
      return (
        <div>
          <Loading title={loadingMessage} />
        </div>
      )
    }
  }

  const renderPaymentInfo = () => {
    if (metodeSelected.code === "bank_transfer") {
      return (
        <div style={{ marginTop: 12, paddingBottom: 20 }}>
          <div
            style={{
              flexDirection: 'row', display: "flex", alignItems: 'center',
              marginTop: 12,
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12
            }}>
            <div style={{ fontSize: 14, color: '#FFF' }}>Metode</div>
            <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right', textTransform: 'capitalize' }}>
              Transfer Bank
            </div>
          </div>
          <div
            style={{
              flexDirection: 'row', display: "flex", alignItems: 'center',
              marginTop: 12,
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12
            }}>
            <div style={{ fontSize: 14, color: '#FFF' }}>Nama Bank</div>
            <div numberOfLines={1} style={{
              flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right',
              textTransform: "capitalize"
            }}>
              {metodeSelected.bank}
            </div>
          </div>
          <div style={{ flex: 1, fontSize: 14, color: '#FFF', paddingTop: 12 }}>
            {metodeSelected.desc_web}
          </div>
        </div>
      )
    }

    if (metodeSelected.code === "echannel") {
      return (
        <div style={{ marginTop: 12, paddingBottom: 20 }}>
          <div
            style={{
              flexDirection: 'row', display: "flex", alignItems: 'center',
              marginTop: 12,
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12
            }}>
            <div style={{ fontSize: 14, color: '#FFF' }}>Metode</div>
            <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right', textTransform: 'capitalize' }}>
              {metodeSelected.label}
            </div>
          </div>
          <div style={{ flex: 1, fontSize: 14, color: '#FFF', paddingTop: 12 }}>
            {metodeSelected.desc_web}
          </div>
        </div>
      )
    }

    if (metodeSelected.code === "gopay") {
      return (
        <div style={{ marginTop: 12, paddingBottom: 20 }}>
          <div
            style={{
              flexDirection: 'row', display: "flex", alignItems: 'center',
              marginTop: 12,
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12
            }}>
            <div style={{ fontSize: 14, color: '#FFF' }}>Metode</div>
            <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right', textTransform: 'capitalize' }}>
              {metodeSelected.label}
            </div>
          </div>
          <div style={{ flex: 1, fontSize: 14, color: '#FFF', paddingTop: 12 }}>
            {metodeSelected.desc_web}
          </div>
        </div>
      )
    }

    if (metodeSelected.code === "qris") {
      return (
        <div style={{ marginTop: 12, paddingBottom: 20 }}>
          <div
            style={{
              flexDirection: 'row', display: "flex", alignItems: 'center',
              marginTop: 12,
              borderBottom: "1px solid rgba(0,0,0,0.03)",
              paddingTop: 12,
              paddingBottom: 12
            }}>
            <div style={{ fontSize: 14, color: '#FFF' }}>Metode</div>
            <div numberOfLines={1} style={{ flex: 1, paddingLeft: 20, fontSize: 14, fontWeight: 'bold', color: '#FFF', textAlign: 'right', textTransform: 'capitalize' }}>
              {metodeSelected.label}
            </div>
          </div>
          <div style={{ flex: 1, fontSize: 14, color: '#FFF', paddingTop: 12 }}>
            {metodeSelected.desc_web}
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{
      // backgroundColor: '#D400B8',
      flex: 1,
      minHeight: '100vh'
    }} className={`background-theme-${theme}`}>
      <Header
        title={isFetched ? 'loading' : "Checkout"}
        // navigation={props.navigation}
        // backgroundColor={"#D400B8"}
        paddingHorizontal={20}
        fontSize={16}
        fontWeight={"bold"}
        textAlign={"center"}
        color={"#fff"}
        className={`background-theme-${theme}`}
      />
      <div style={{ paddingTop: 60 }}>
        {renderContents()}
      </div>
      <Modal animationType="fade" show={isShowModal} transparent centered>
        <Modal.Header style={{
          width: '100%',
          height: 50,
          flexDirection: 'row',
          // backgroundColor: "#950082",
          borderRadius: '15px 15px 0px 0px',
          alignItems: 'center', justifyContent: 'center',
          paddingHorizontal: 20,
        }} className={`background-light-theme-${theme}`}>
          {/* <i class="fas fa-address-card" style={{ color: "#fff" }}></i> */}
          <div style={{ flex: 1, color: "#fff", fontSize: 18, fontWeight: "bold", textAlign: 'center' }}>Beli Paket Langganan?</div>
        </Modal.Header>
        <Modal.Body style={{ 
          // backgroundColor: "#D400B8" 
          }} className={`background-theme-${theme}`}>
          <div style={{ textAlign: "left", padding: "15px 0px" }}>
            <div style={{ color: '#fff', fontSize: 14, textAlign: 'center' }}>
              Apakah Anda yakin akan membeli paket
            </div>
            <div style={{ color: '#fff', fontSize: 14, textAlign: 'center' }}>
              langganan <span style={{ fontWeight: 'bold' }}>{dataSelected !== null && dataSelected.name}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{
          height: 70, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20, flexDirection: 'row', 
          // backgroundColor: "#D400B8",
          borderRadius: '0px 0px 15px 15px',
        }} className={`background-light-theme-${theme}`}>
          <div style={{
            borderRadius: 40, width: 100, height: 40, backgroundColor: "rgba(0,0,0,0.1)",
            alignItems: 'center', justifyContent: 'center', display: 'flex', cursor: 'pointer'
          }}
            onClick={() => { setIsShowModal(false) }}>
            <div style={{ color: "rgba(255,255,255,0.5)", fontSize: 16 }}>Tidak</div>
          </div>
          <div style={{ flex: 1, paddingLeft: 15, height: 40 }}>
            <div style={{
              borderRadius: 40, width: '100%', height: 40, backgroundColor: "rgba(0,0,0,0.4)",
              alignItems: 'center', justifyContent: 'center',
              flexDirection: 'row',
              display: 'flex',
              cursor: 'pointer'
            }}
              onClick={onCheckoutListeners}>
              <i className="fas fa-check icon ripple" style={{ color: '#fff', fontSize: 14 }}></i>
              <div style={{ color: "#fff", fontSize: 16, paddingLeft: 10 }}>Ya, Beli Paket</div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <SubscriptionsPayment refSubscriptionsPayment={refSubscriptionsPayment} onUpdateData={onPaymentDataListeners} />
      <Snackbar style={{
        position: 'absolute',
        width: '100%',
        left: 'auto',
        right: 'auto'
      }} open={showError} autoHideDuration={6000} onClose={onCloseAlert}>
        <Alert onClose={onCloseAlert} severity={errType}
          sx={{ width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: "6vh" }}>
          {errMessage}
        </Alert>
      </Snackbar>
    </div>
  )
  // }
}