import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react"

const isDebug = false

const AudioMedia = ((props, ref) => {
  const refAudio = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      audio: refAudio,
      setVolume,
      play,
      stop
    }),
  )

  useEffect(() => {
    if (refAudio !== null) {
      const audio = refAudio.current

      audio.addEventListener('error', (e) => {
        if (isDebug) console.log('error')
        props.onError && props.onError(e)
      })

      audio.addEventListener('ended', () => {
        if (isDebug) console.log('ended')
        props.onEnded && props.onEnded()
      })
    }
  }, [refAudio]);

  const setVolume = (value) => {
    let newVolume = value
    if (newVolume < 0) newVolume = 0
    else if (newVolume > 1) newVolume = 1
    refAudio.current.volume = newVolume
  }

  const play = (src = null) => {
    if (src) {
      refAudio.current.src = src
    }

    if (props.volume) {
      refAudio.current.volume = props.volume
    }

    setTimeout(() => {
      const playAudio = refAudio.current.play()

      if (playAudio !== undefined) {
        playAudio.then(() => {
          if (isDebug) console.log('playSuccess')
          props.onPlaySuccess && props.onPlaySuccess()
        }).catch((err) => {
          if (isDebug) console.log('playError')
          props.onPlayError && props.onPlayError(new Error(err))
        })
      }
    }, 200);
  }

  const stop = () => {
    refAudio.current.pause()
    refAudio.current.currentTime = 0

    if (isDebug) console.log('onStop')
    props.onStop && props.onStop()
  }

  return (
    <audio controls={isDebug} ref={refAudio}></audio>
  )
})

export default forwardRef(AudioMedia);